<template>
    <div>
        <v-card>
            <div>
                <v-row class="px-5 ">
                    <SnackBar @dismiss="(visible) => { response.visible = visible;} "
                        :type="response.type"
                        :snackbar="response.visible"
                        :text="response.text"
                    />

                    <v-col cols="12" sm="12" :md="DataSearch && SelectedItems ? '12' : '6'" lg="3" xl="3" :class="!isSmOrXsScreen ? 'text-left mt-1' : 'text-center order-first'" v-if="sub">
                        <BtnAddBack :sub="sub" :insert="insert" @setInsert="(val) => {insert = val;}" />
                    </v-col>
                    <v-col cols="12" sm="12" :md="6" :lg="selectColumn" :xl="selectColumn" v-if="SelectedItems" class="mt-n2">
                        <v-select v-model="select" :value="disabledSelect ? selectItem : select" :items="SelectedItems" :label="labelSelect" :class="!isSmOrXsScreen ? '' : 'mx-4 mt-n3 mb-4'" :disabled="disabledSelect">
                            <template slot="selection" slot-scope="data">
                                <v-list-item-title style="white-space:normal;" class="pt-2 pb-1" >{{ data.item.text }}</v-list-item-title>
                            </template>
                            <template slot="item" slot-scope="data">
                                <span style="text-align: justify;" class="my-2">{{ data.item.text }}</span>
                            </template>              
                        </v-select>
                    </v-col>
                    <v-col v-if="sub && !SelectedItems && !isSmOrXsScreen && !$vuetify.breakpoint.md"></v-col>
                    <v-col cols="12" sm="12" :md="6" :lg="searchColumn" :xl="searchColumn" :class="!isSmOrXsScreen ? 'text-right mt-n6' : 'text-right mt-n8 order-first'" v-if="dataSearch">
                        <InputSearch
                            :label="labelSearch"
                            :dataSearch="dataSearch"
                            :page="page"
                            :rows="rows"
                            :sortBy="sortBy"
                            :sortDesc="sortDesc"
                            :resetData="RefreshData"
                            @total="(val) => {totalRows = val;}"
                            @terminado="(val) => {loading = val}"
                            @dados="(val) => {data = val}"
                            @search="(val) => {search = val}"
                        />
                    </v-col>
                </v-row>
            </div>

            <div class="mt-4">
                <DataTable
                    :loading="loading"
                    :headers="headers"
                    :items="data"
                    :actions="actions"
                    :hasSelect="hasSelect"
                    :editItem="edit"
                    :itemsSelected="selected"
                    :totalRows="totalRows"
                    :RefreshData="RefreshData"
                    :tipoDocumento="tipoForm ? tipoForm : tipoDocumento"
                    :search="search"
                    @selected="(val) => {selected = val;}"
                />
            </div>
        </v-card>
        <div class="mt-9 mb-n10" v-show="selected.length > 0">
            <BtnActions  :tipoDocumento="tipoDocumento" :Selected="selected" @selected="(val) => {selected = val;}" :Actions="ationsBtn"  @response="(val) => {response = val;}" :RefreshData="RefreshData" />
        </div>
    </div>
</template>

<script>
import SnackBar from "../WarningComponents/snackbar.vue";
import DataTable from "./DataTable.vue";
import InputSearch from "./InputSearch.vue";
import BtnActions from "../InputForm/BtnAction.vue";
import BtnAddBack from "../BtnAddBack.vue";

import _ from "lodash";

export default {
    mounted() {
        this.dataSearch = this.DataSearch
        this.$store.dispatch("resetData")
        this.RefreshData();

        if(this.disabledSelect){
            this.select = this.selectItem
        }

        if(this.$route.name == "quadro"){
            switch (this.$route.params.num) {
                case "I":
                    this.dataSearch[0].tipo = "QUADRO I"
                    break;
                case "II B":
                    this.dataSearch[0].tipo = "QUADRO II - B"
                    break;
                case "II C":
                    this.dataSearch[0].tipo = "QUADRO II - C"
                    break;
            }
        }

        if(this.tipoDocumento == "docGestaoSumario"){
            this.tipoForm = "gestaoSumario"
        }else if(this.tipoDocumento == "docGestaoEtiqueta"){
            this.tipoForm = "gestaoEtiqueta"
        }
    },
    props: {
        actions: Array,
        DataSearch: Array,
        labelSearch: String,
        SelectedItems: Array,
        tipoDocumento: String, 
        headers: Array,
        getData: Array,
        terminado: Boolean,
        hasSelect: Boolean,
        ationsBtn: Array,
        sub: String,
        edit: Function,
        labelSelect: {type: String, default: "Tipo de Formulário"},
        selectItem: String,
        disabledSelect: Boolean
    },
    data() {
        return {
            response: {},
            selected: [],
            dados: [],
            search: "",
            page: 0,
            rows: 10,
            sortBy: "",
            sortDesc: false,
            totalRows: 0,
            loading: true,
            insert: false,
            dataSearch: [],
            select: "*",
            data: [],
            tipoForm: ""
        };
    },
    components: {
        DataTable,
        InputSearch,
        BtnAddBack,
        SnackBar,
        BtnActions
    },    
    watch:{
        selected(val){
            this.showBtn = val.length > 0 ? true : false;
        },
        select(val){
            if(this.disabledSelect){
                return
            }

            switch (this.tipoDocumento) {
                case "formulario":
                    this.dataSearch = [{ tipo: "formulario", tipoForm: val, terminado: this.terminado, filtro: "formulario"}]
                    break;
                case "docGestaoEtiqueta":
                    this.dataSearch = [{ tipo: val == "*" ? "" : val,  filtro: "gestaoEtiqueta"}]
                    break;
            }
        },
        insert(val){
            this.$emit("getInsert", val)
        }
    },
    computed: {
         isSmOrXsScreen(){
            if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
                return true;
            }else{
                return false;
            }
        },
        selectColumn(){
            var col = 12
            if(this.sub){
                col = 7
            }else if(this.dataSearch){
                col = 6
            }
            if(this.sub && this.dataSearch){
                col = 4
            }

            return col
        },
        searchColumn(){
            var col = 12
            if(this.sub){
                col = 7
            }else if(this.SelectedItems){
                col = 6
            }
            if(this.sub && this.SelectedItems){
                col = 5
            }

            return col
        }
    },
    methods: {
        RefreshData(page=0, rows=10, sortBy="", sortDesc=false) {
            if(this.getData.length == 0){
                return;
            }

            var getForm = ""
            switch (this.tipoDocumento) {
                case "formulario":
                    getForm = "getForm"
                    break;
                case "docGestaoICMS":
                    getForm = "getDocsGestao"
                    break;
                case "docGestaoSumario":
                case "docGestaoEtiqueta":
                    getForm = "getGestao"
                    break;
                default:
                    return;
            }
            
            var _orderBy = ""
            if(!_.isEmpty(sortBy)){
                _orderBy = `/${sortBy == "Userresponsavel" ? "responsavel" : sortBy}`
                if(sortDesc){
                _orderBy += "/true"
                }
            }

            page = page - 1
            var dados = {};
            
            if(!_.isEmpty(this.select) && this.select != "*" && !this.disabledSelect){
                dados = {
                    terminado: this.terminado,
                    tipo: this.select,
                    filtro: "tipo"
                }
            }else{
                dados = this.getData[0]
            }

            dados.page = page
            dados.rows = rows
            dados.orderBy = _orderBy
            
            if(!_.isEmpty(this.search)){
                this.page = page
                this.rows = rows
                this.sortBy = sortBy
                this.sortDesc = sortDesc
            }else{
                this.$store.dispatch(getForm, dados).then(result => {
                    this.data = this.$store.state.DadosFormulario
                    this.totalRows = result.totalRows
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
    }
}
</script>