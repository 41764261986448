<template>
  <v-container>
    <Header :titulo="titulo" :itemsBreadCrumb="BreadCrumbs" />
    <v-card>
      <div :class="isSmScreen ? 'pb-5' : 'px-5 pt-3 mb-5'">
        <SnackBar @dismiss="(visible) => {response.visible = visible;}"
          :type="response.type"
          :snackbar="response.visible"
          :text="response.text"
        /> 

        <v-row>
          <v-col cols="12" sm="12" md="5" lg="6" xl="6" :class="isSmScreen ? 'text-center' : 'text-left'" v-if="this.$store.getters.User.level == '1'">
            <BtnAddBack :sub="sub" :insert="insert" @setInsert="(val) => {insert = val;}" />  
          </v-col>
          
          <v-col cols="12" sm="12" md="7" lg="6" xl="6" class="text-right mt-n4" v-if="!insert">
            <InputSearch
              :label="'Procure por Email, CPF ou Área de Atuação'"
              :dataSearch="dataSearch"
              :page="page"
              :rows="rows"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              :resetData="getUsers"
              @search="(val) => {search = val;}"
              @total="(val) => {totalRows = val;}"
              @terminado="(val) => {loading = val;}"
              @getUsers="(val) => {users = val;}"
            />
          </v-col>
        </v-row>
      </div>

      <template v-if="insert">
        <div class="rounded-lg elevation-1" style="background-color: white">
          <Inputs :campos="campos" />
          <v-col cols="12">
            <v-btn
              class="mx-3 my-1"
              x-large
              @click="
                insert ? (update ? updateUser() : insertUser()) : insertUser()
              "
              color="green"
              dark
            >
              {{
                insert ? (update ? "Atualizar" : "Inserir") : "Inserir"
              }}</v-btn
            >
          </v-col>
        </div>
      </template>

      <template v-else>
        <DataTable
          :tipoDocumento="'users'"
          :loading="loading"
          :headers="headers"
          :itemKey="'id'"
          :items="usersC"
          :actions="actions"
          :editItem="editUser"
          :RefreshData="getUsers"
          :totalText="'Utilizadores'"
          :search="search"
          :totalRows="totalRows"
        />
      </template>
    </v-card>
  </v-container>
</template>

<script>
import Inputs from "../../components/InputForm/Input";
import SnackBar from "../../components/WarningComponents/snackbar.vue";
import DataTable from "../../components/DataTables/DataTable.vue";
import InputSearch from "../../components/DataTables/InputSearch.vue";
import Header from "../../components/Header.vue";
import BtnAddBack from "../../components/BtnAddBack.vue";
import _ from "lodash";
import axios from "axios";

export default {
  components: {
    Inputs,
    SnackBar,
    DataTable,
    InputSearch,
    Header,
    BtnAddBack,
  },
  data: () => {
    return {
      titulo: "Usuários",
      sub: "Novo Usuário",
      BreadCrumbs: [
        {
          text: "Página Inicial",
          disabled: false,
          href: "/"
        },
        {
          text: "Usuários",
          disabled: true,
          href: "/usuarios",
        }
      ],
      dataSearch: [{ filtro: "Users" }],
      search: "",
      page: 0,
      rows: 10,
      sortBy: "",
      sortDesc: false,
      id: null,
      loading: true,
      insert: false,
      update: false,
      response: { type: "", text: "", visible: false },
      user: {},
      users: [],
      totalRows: 0,
      actions: ["edit", "delete"],
      campos: [
        {
          header: true,
          show: true,
          name: "nome",
          type: "text",
          label: "Nome",
          value: "",
          cols: "6",
        },
        {
          header: true,
          show: true,
          name: "cpf",
          type: "text",
          label: "CPF",
          value: "",
          cols: "6",
        },
        {
          header: false,
          show: true,
          name: "password",
          type: "password",
          label: "Senha",
          value: "",
          cols: "6",
        },
        {
          header: true,
          show: true,
          name: "email",
          type: "email",
          label: "Email",
          value: "",
          cols: "6",
        },
        {
          header: true,
          show: true,
          name: "atuacao",
          type: "text",
          label: "Área de Atuação",
          value: "",
          cols: "12",
        },
        {
          header: true,
          show: true,
          name: "level",
          type: "select",
          multiple: false,
          options: [
            { text: "Administrador", value: "1" },
            { text: "Usuário", value: "2" },
          ],
          label: "Tipo de Usuário",
          value: "",
          cols: "12",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("resetData")
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.getUsers();
    } else if(this.$route.params.editar) {
      this.insert = true   
    }else{
      this.getUsers();
    }

    if(this.$store.getters.User.level != 1){
      var camposTemp = []
      this.campos.map((campo) => {
          if (campo.name != 'level') {
            camposTemp.push(campo);
          }
        });

      this.campos = camposTemp    
    }
     
  },
  
  watch: {
    "$route.params.id"(val) {
      if (val) {
        this.id = val;
        this.getUsers();
      }
    },
  },
  computed: {
    headers() {
      var headers = [];
      this.campos.map((campo) => {
        if (campo.header) {
          headers.push({ text: campo.label, value: campo.name });
        }
      });
      headers.push({
        text: "Ações",
        value: "actions",
        align: "center",
        sortable: false,
      });
      return headers;
    },
    usersC() {
      var _users = [];
      this.users.map((user) => {
        user.tipo = user.level;
        if (user.tipo == "1") {
          user.level = "Administrador";
        } else if (user.tipo == "2") {
          user.level = "Usuário";
        }
        _users.push(user);
      });

      return _users;
    },
    isSmScreen(){
      if(this.$vuetify.breakpoint.xs){
        return true;
      }else{
        return false;
      }
    }
  },
  methods: {
    insertUser() {
      let formData = new FormData();
      this.campos.map((campo) => {
        formData.append(campo.name, _.escape(campo.value));
        
      });

      axios.post(`/api/users/register`, formData)
        .then((resp) => {
          this.response = {
            type: "success",
            text: resp.data.message,
            visible: true,
          };
          this.reset();
          this.insert = false;
          this.$router.push({ path: `/usuarios`});
        })
        .catch((error) => {
          this.response = {
            type: "danger",
            text: error.response.data.message,
            visible: true,
          };
        })
    },
    updateUser() {
      var user = []
      let formData = new FormData();
      this.campos.map((campo) => {
        formData.append(campo.name, campo.value);
        user[campo.name] = campo.value
      });
      formData.append("id", this.user.id);
      formData.append("cpf_user_login", this.$store.getters.User.cpf);

      if(this.$store.getters.User.level != 1){
        formData.append("level", 2); 
        user['level'] = "2"
      }

      axios.post(`/api/auth/users/update`, formData)
        .then((resp) => {
          this.response = {
            type: "success",
            text: resp.data.message,
            visible: true,
          }
          
          if(this.$store.getters.User.id == this.user.id) {
            var oldLevel = this.$store.getters.User.level
            this.$store.dispatch("updateUser", user)
            if(oldLevel != user.level){
              window.location.href = "/"
            }
          }
          
          if(this.$store.getters.User.level == 1){
            this.reset();
            this.insert = false;
            this.update = false;
            if (this.$route.params.id) {
              this.$router.push({ path: "/usuarios"});
            }
          }else{
            this.$router.push({path: "/"})
          }
        })
        .catch((error) => {
          this.response = {
            type: "danger",
            text: error.response.data.message,
            visible: true,
          };
        })
    },
    editUser(_user) {
      if(!this.$route.params.id){
        this.$router.push({ path: `/usuarios/editar/${_user.id}`});
      }
      this.user = _user;
      this.campos.map((campo) => {
        if (campo.name == "level") {
          campo.value = _user["tipo"];
        } else if (campo.name == "password") {
          campo.value = "";
        } else {
          campo.value = _user[campo.name];
          if(campo.name == 'cpf'){
            campo.disabled = true
          }
        }
      });
      this.insert = true;
      this.update = true;
    },
    getUsers(page=0, rows=10, sortBy="", sortDesc=false) {
      var _orderBy = ""
      if(!_.isEmpty(sortBy)){
        _orderBy = `/${sortBy}`
        if(sortDesc){
          _orderBy += "/true"
        }
      }

      let data = {};
      if (this.id) {
        data.id = this.id;
      }
      page = page - 1
      if(!_.isEmpty(this.search)){
        this.page = page
        this.rows = rows
        this.sortBy = sortBy
        this.sortDesc = sortDesc
      }else{
        axios.get(`/api/auth/users/get/${page}/${rows+_orderBy}`, { params: data }).then((response) => {
          if (this.id) {
            var user = response.data.results[0]

            user.tipo = user.level
            this.editUser(user);
            this.id = null;
            this.update = true;
          } else {
            this.users = response.data.results;
          }
          this.totalRows = parseInt(response.data.total)
        }).catch((error) => {
          this.response = {
            type: "danger",
            text: error.response.data.message,
            visible: true,
          };
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    reset() {
      this.insert = false;
      this.update = false;
      this.user = null;
      this.getUsers();
      this.campos.map((campo) => {
        campo.value = "";
      });
    },
  },
};
</script>
