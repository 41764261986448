<template>
  <div>
    <SnackBar @dismiss="(visible) => { response.visible = visible;} "
        :type="response.type"
        :snackbar="response.visible"
        :text="response.text"
      />
    <DialogAlertDelete :details="dialogDelete" :close="close" :confirm="deleteItem" />

    <v-data-table 
      :show-select="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? false : hasSelect"
      :headers="headers"
      :items="items"
      :item-key="itemKey"
      :items-per-page="itemsPerPage"
      :hide-default-footer="true"
      :custom-sort="customSort"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      loading-text="Carregando ..."
      :loading="loading"
      no-data-text="Sem Resultados"
      :header-props="{
        'sort-by-text': 'Ordenar por',
      }"
      :footer-props="{
        'show-current-page': true,
        'items-per-page-options': [5, 10, 15],
        'page-text': totalRows + ' ' + totalText,
        'items-per-page-text': 'dados por página:',
        'itemsLength': '3',
      }"
      class="elevation-1"
    >
      <!-- CheckBox Select -->
      <template v-slot:header.data-table-select>
        <!-- <v-checkbox color="colorGreyBoxVue" :checked="" @click="checkAll()" v-model="check" :value="check"></v-checkbox> -->
      </template>
      <template v-slot:item.data-table-select="{item}">
        <!-- se for necessário usar multiplo, usar eventos vuetify -->
        <v-checkbox color="colorGreyBoxVue" :checked="getCheck(item)" :value="item.checked" v-model="item.checked" @click="chancheSelected(item)"></v-checkbox>
      </template>
      <!-- Nome de responsável passando o rato por cima -->
      <template v-slot:item.Userresponsavel="{ item }">
          <td :title="item.UserName"> {{item.Userresponsavel}} </td>
      </template>
      <!-- Adicionando checkbox na tabela -->
      <template  v-slot:item.encaminhado="{ item }">
        <span style="display:flex;  justify-content: center;">
          <v-checkbox :disabled="disabledCheckList" v-model="item.encaminhado" @click="updateCheckList(item)">
          </v-checkbox>
        </span>
      </template>
      <!-- Ações dos botºoes (editar, ver, download, remover) -->
      <template v-slot:item.actions="{ item }">
        <span v-for="action in actions" :key="action">
          <v-icon
            v-if="action == 'edit'"
            :class="!isLowerLgScreen ? 'mr-2' : 'mr-4'"
            :style="isLowerLgScreen ? 'font-size: 30px' : ''"
            color="green"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>

          <v-icon
            v-if="action == 'delete'"
            :class="!isLowerLgScreen ? 'mr-2' : 'mr-4'"
            :style="isLowerLgScreen ? 'font-size: 30px' : ''"
            color="red"
            @click="ConfirmDelete(item)"
          >
            mdi-delete
          </v-icon>

          <v-icon
            v-if="action == 'view'"
            :class="!isLowerLgScreen ? 'mr-2' : 'mr-4'"
            :style="isLowerLgScreen ? 'font-size: 30px' : ''"
            color="black"
            @click="viewItem(item)"
          >
            mdi-file-outline
          </v-icon>

          <v-icon
            v-if="action == 'download'"
            :class="!isLowerLgScreen ? 'mr-2' : 'mr-4'"
            :style="isLowerLgScreen ? 'font-size: 30px' : ''"
            color="blue"
            @click="downloadItem(item)"
          >
            mdi-download
          </v-icon>
        </span>
      </template>
    </v-data-table>
    <!-- pagination -->
    <div>
      <div class="mt-5 elevation-1">
        <v-row>
          <v-col cols="12" sm="12" xl="4" md="4" lg="4">
            <v-select
              :value="itemsPerPage"
              class="mx-5"
              label="Dados por Página"
              :items="[{value: 5, text: 5}, {value: 10, text: 10}, {value: 15, text: 15}]"
              @input="itemsPerPage = parseInt($event, 10)"
            >
              <template slot="selection" slot-scope="data">
                <v-list-item-title style="white-space:normal; color: black;" class="pt-2 pb-1" >{{ data.item.text }}</v-list-item-title>
              </template>
              <template slot="item" slot-scope="data">
                <span style="text-align: justify;" class="my-2">{{ data.item.text }}</span>
              </template>                      
            </v-select>
          </v-col>
          <v-col cols="12" sm="12" xl="4" md="4" lg="4" :class="isXsSmScreen ? 'order-first' : ''">
              <v-pagination v-model="page" :value="page" circle @input="getDataPage(page)" color="bgp" :total-visible="6" :length="pageCount"></v-pagination>
          </v-col>
          <v-col cols="12" sm="12" xl="4" md="4" lg="4" :class="isXsSmScreen ? 'my-n4 order-first' : ''">
              <span :class="isXsSmScreen ? 'text-center' : 'float-right mx-4 mt-4'"> {{totalRows}} - {{totalText}} </span>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import SnackBar from '../WarningComponents/snackbar.vue'
import DialogAlertDelete from '../WarningComponents/DialogAlert.vue'

export default {
  components:{
    SnackBar,
    DialogAlertDelete,
  },
  mounted(){
    /* verificar se é admin */
    if(this.$store.state.User.level == '1'){
      this.disabledCheckList = false
    }

    this.calcPageCount();
  },
  props: {
    headers: Array,
    hasSelect: {type: Boolean, default: false},
    items: Array,
    itemKey: {type: String, default: "id"},
    actions: Array,
    loading: Boolean,
    itemsSelected: Array,
    totalText: {type: String, default: "formulários"},
    tipoDocumento: String,
    totalRows: {type: Number, default: 0},
    search: String,
    /* FUNÇÔES */
    RefreshData: Function,
    editItem: Function,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      selected: [],
      selected_temp: [],
      check: false,
      dialogDelete: [{ dialog: false }],
      response: {},
      disabledCheckList: true,
      sortDesc: [false],
      sortBy: []
    };
  },
  watch: {
    sortBy(val){
      if(val.length > 0){
        this.getDataPage(this.page, true)
      }
    },
    sortDesc(val){
      if(val[0]){
        this.getDataPage(this.page, true)
      }
    },
    search(val){
      if(!_.isEmpty(val)){
        this.page = 1
      }
    },
    selected(val){
      if(val.lenght <= 0){
        this.selected_temp = val
      }
    },
    /* passagem */
    itemsSelected(val){
      this.selected = val
      this.selected_temp = val
    },
    totalRows(){
      this.calcPageCount();
    },
    itemsPerPage(val){
      this.RefreshData(this.page, val)
      this.calcPageCount();
    }
  },
  computed: {
    isLowerLgScreen(){
      if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md){
        return true;
      }else{
        return false;
      }
    },
    isXsSmScreen(){
      if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
        return true;
      }else{
        return false;
      }
    }
  },
  methods: {
    customSort(){
      if(_.isEmpty(this.sortBy)){
        return this.items
      }else{
        return this.items
      }
    },
    /* CheckBox Sync */
    chancheSelected(item){
      var index =  _.findIndex(this.selected_temp, function(val) {
        return val.id == item.id;
      });
      
      if(index < 0){
        item.checked = true
        this.selected_temp.push(item)
      }else{
        item.checked = false
        this.selected_temp.splice(index, 1)
      }
    
      this.$emit("selected", this.selected_temp)
      this.selected = this.selected_temp
    },
    getCheck(item){
      var index =  _.findIndex(this.selected_temp, function(val) {
        return val.id == item.id;
      });

      if(index >= 0){
        item.checked = true
        return true
      }else{
        item.checked = false
        return false
      }
    },
    checkAll(){
      var checkAll = false
      this.items.map(val => {
        if(!this.getCheck(val)){
          checkAll = true
        }
      })
      
      this.items.map(val => {
        if(val.checked != checkAll){
          this.chancheSelected(val)
        }
      })

      this.check = checkAll
    }, 
    removeItemSelected(item){
      var index = _.findIndex(this.selected_temp, function(val) {
        return val.id == item.id
      })

      if(index >= 0){
        this.selected_temp.splice(index, 1)
        this.selected = this.selected_temp
      }
    },
    /* PAGINATION */
    calcPageCount(){
      this.pageCount = Math.ceil(this.totalRows/this.itemsPerPage);
      // this.pageCount = 3
      if(this.page > this.pageCount){
        this.getDataPage(0)
        this.page = 1
      }

      if(this.pageCount == 0){
        this.pageCount = 1
      }
    },
    getDataPage(page, sort=null){
      if(sort){
        this.RefreshData(page, this.itemsPerPage, this.sortBy[0], this.sortDesc[0] ? this.sortDesc[0] : false)
      }else{
        this.RefreshData(page, this.itemsPerPage)
      }
        this.selected = this.selected_temp
    },
    /* END PAGINATION */
    deleteItem(){
      this.dialogDelete[0].dialog = false
      var item = this.dialogDelete[0].item
      this.removeItemSelected(item)
      
      switch (this.tipoDocumento) {
        case "formulario":
          this.deleteForm(item)
          break;
        case "users":
          this.deleteUser(item)
          break;
        case "docGestaoICMS":
          this.deleteDocGestaoICMS(item)
          break;
        case "gestaoSumario":
          this.deleteGestao(item, "sumarioGestao")
          break;
        case "gestaoEtiqueta":
          this.deleteGestao(item, "etiquetaGestao")
          break;
      }
    },
    deleteGestao(item, route){
      this.$store.dispatch("removeGestao", { nameRoute: route, id: item.id }).then((resp) => {
        this.response = resp;
        this.RefreshData();
      });
    },
    deleteDocGestaoICMS(item){
      this.$store.dispatch("removeDocsGestao", { id: item.id }).then((resp) => {
        this.response = resp;
        this.RefreshData();
      });
    },
    deleteForm(item){
      this.$store.dispatch("removeForms", { id: item.id }).then((resp) => {
        this.response = resp;
        this.RefreshData();
      });
    },
    deleteUser(item){
        var post = {};
        item.cpf_user_login = this.$store.getters.User.cpf
        post.user = item;
        if (post.user) {
          axios.delete(`/api/auth/users/delete`, { params: post.user })
            .then((resp) => {
              this.response = {
                type: "success",
                text: resp.data.message,
                visible: true,
              };
              this.RefreshData();
              if (this.$store.state.User.id == post.user.id) {
                this.$store.dispatch("logout");
              }
            }).catch((error) => {
              this.response = {
                type: "danger",
                text: error.response.data.message,
                visible: true,
              };
            })
        }
    },
    /* metodo que servira apenas para fazer update aos documentos de gestão*/
    updateCheckList(item){
      axios.post(`https://gespa.dev.weblevel.pt/api/auth/documentosGestao/${item.tipo}/update`, item).then(() => {
        this.$store.dispatch("getNotifications")
      }).catch(error => {
          this.response =  {type:'danger',text:error.response.data.message,visible:true}
      })
    },
    /* normalmente estes dois metodos são usados só para os formulários */
    viewItem(item){
      var dados = {
        dados: item,
        id: item.id
      }

      if(this.tipoDocumento == "gestaoSumario" || this.tipoDocumento == "gestaoEtiqueta"){
        dados.tipo = "getGestao"
        dados.tipoDocumento = this.tipoDocumento
        this.$store.dispatch("seeGestao", dados)
      }else{
        dados.tipo = item.tipo == 'NovoBemDigitalizado' ? "formularioNovoBemDigitalizado" : "getFormulario"
        this.$store.dispatch("seeForm", dados)
      }
    },
    downloadItem(item){
      var dados = {
        dados: item,
        id: item.id,
        name: item.designacao
      }

      if(this.tipoDocumento == "gestaoSumario" || this.tipoDocumento == "gestaoEtiqueta"){
        dados.tipo = "getGestao"
        dados.tipoDocumento = this.tipoDocumento
      }else{
        dados.tipo = item.tipo == 'NovoBemDigitalizado' ? "formularioNovoBemDigitalizado" : "getFormulario"
      }

      this.$store.dispatch("downloadForm", dados)
    }, 
    close() {
      this.dialogDelete[0].dialog = false
    },
    ConfirmDelete(item){
      this.dialogDelete[0].title = item.cpf ? "Tem a certeza que pretende remover este usuário?" : "Tem a certeza que pretende remover este documento?";
      this.dialogDelete[0].item = null
      this.dialogDelete[0].item = item
      this.dialogDelete[0].dialog = true;
    },
  }
};
</script>