<template>
  <v-row justify="center">
    <v-dialog
      v-model="details[0].dialog"
      max-width="450"
    >
      <v-card>
        <v-card-title class="justify-center">
          <b>{{details[0].title}}</b>
        </v-card-title>

        <v-card-text v-if="details[0].description">
          <h3 style="color: black">{{details[0].description}}</h3>  
        </v-card-text>

        <div v-if="details[0].emptyFields">
          <v-card-text >
            <div v-for="fieldName in details[0].emptyFields" :key="fieldName">
                <span style="color:red">{{fieldName}}</span>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green"
              class="white--text"
              @click="close()"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </div>
        <div v-else>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="grey darken-1"
              style="opacity: 0.8;"
              text
              @click="close()"
            >
              Não
            </v-btn>

            <v-btn
              :loading="loading"
              color="green"
              class="white--text"
              @click="confirm()"
            >
            Sim
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    props:{
      details: Array,
      confirm: Function,
      loading: { type: Boolean, default: false },
      close: Function
    },  
  }
</script>

<style scoped>
.v-card__text, .v-card__title {
  word-break: normal !important; 
}
</style>