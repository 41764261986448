<template>
  <v-container>
    <Header :titulo="titulo" :itemsBreadCrumb="BreadCrumbs" />
    <div>
      <div v-if="insert">
        <v-card>
          <v-col cols="12" :class="isSmOrXsScreen ? 'text-center' : 'text-left ml-3'">
            <BtnAddBack :sub="sub" :insert="insert" @setInsert="(val) => {insert = val;}" />
          </v-col>
          <v-select class="mx-5 mt-4" outlined dense v-model="selectForm" :items="SelectedItemsForm" label="Tipo de Formulário"></v-select>
          <div class="rounded-lg elevation-1" style="background-color: white">
              <div v-if="selectForm && $store.state.upload.dialog == false">
                <div>
                    <Stepper :campos="campos" />
                </div>
                <div class="mt-9">
                  <BtnActions :tipoDocumento="'formulario'" :tipoFormulario="campos[0].value" :tipoQuadro="'QUADRO II - A'" :editId="EditId" :isNew="isNew" :campos="campos"  :Actions="[{type: 'terminado', terminado: terminadoBtn, cols:'12'}]" />
                </div>
              </div>
          </div>
        </v-card>
      </div>

      <div v-else>
        <ModalBaseTable :actions="actions" :ationsBtn="btnOption" :DataSearch="dataSearch" :edit="edit" :hasSelect="true" :headers="headers" :labelSearch="labelSearch" :getData="getData" :tipoDocumento="'formulario'" :terminado="false" :SelectedItems="SelectedItems" :sub="sub" :insert="insert" @getInsert="(val) => { insert = val }" />
      </div>
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import Stepper from '../../../components/InputForm/Stepper.vue'
import BtnActions from "../../../components/InputForm/BtnAction.vue";
import BtnAddBack from "../../../components/BtnAddBack.vue";
import ModalBaseTable from "../../../components/DataTables/modelBaseData.vue";
import Header from "../../../components/Header.vue";
/* campos */
import base from '../../../assets/formularios/icms/modelo_base_icms.json'
import modeloAcervos from '../../../assets/formularios/icms/modelo_icms_acervos.json'
import modeloBemMoveis from '../../../assets/formularios/icms/modelo_icms_bens_moveis.json'
import modeloCelebracao from '../../../assets/formularios/icms/modelo_icms_celebracao.json'
import modeloConjuntosUrbanos from '../../../assets/formularios/icms/modelo_icms_conjuntos_urbanos.json'
import modeloformasExpressao from '../../../assets/formularios/icms/modelo_icms_formas_expressao.json'
import modeloLugares from '../../../assets/formularios/icms/modelo_icms_lugares.json'
import modeloOficios from '../../../assets/formularios/icms/modelo_icms_oficios.json'
import modeloPatrimonio from '../../../assets/formularios/icms/modelo_icms_patrimonio.json'
import modeloSaberes from '../../../assets/formularios/icms/modelo_icms_saberes.json'
import modeloSitiosNaturais from '../../../assets/formularios/icms/modelo_icms_sitios_naturais.json'

export default {
  mounted() {
    this.SelectedItemsForm = _.clone(this.SelectedItems)
    this.SelectedItemsForm.pop()

    if (this.$route.params.id) {
      this.edit({ id: this.$route.params.id });
    }
  },
  components: {
    BtnActions,
    Header,
    ModalBaseTable,
    BtnAddBack,
    Stepper
  },
  data() {
    return {
      btnOption: [
        { cols: "6" },
        { cols: "3", type: "download" },
        { cols: "3", type: "remove" },
      ],
      dataSearch: [{ tipo: "formulario", tipoForm: "*", terminado: false, filtro: "formulario"}],
      actions: ["edit", "delete","view", "download"],
      getData: [
        {
          filtro: "quadro",
          tipo: "QUADRO II - A",
          terminado: false
        }
      ],
      SelectedItems: [
        { text: "Estruturas Arquitetônicas  Urbanisticas", value: "EstruturasArquitetonicasUrbanisticas"},
        { text: "Bens Móveis e Bens Integrados", value: "BensMoveisIntegrados"},
        { text: "Patrimônio Arqueológico", value: "PatrimonioArqueologico"},
        { text: "Sítios Naturais", value: "SitiosNaturais"},
        { text: "Conjuntos Urbanos", value: "ConjuntosUrbanos"},
        { text: "Acervos", value: "Acervos"},
        { text: "Manifestações Culturais", value: "ManifestacoesCulturais"},
        { text: "Celebração", value: "Celebracao"},
        { text: "Formas de Expressão", value: "FormasExpressao"},
        { text: "Lugares", value: "Lugares"},
        { text: "Saberes", value: "Saberes"},
        { text: "Ofícios e Modos de Fazer", value: "Oficios"},
        { text: "Todos os Documentos", value: "*"}
      ],
      tipoDocumento: "formulario",
      headers: [
        {
          text: "Designação",
          align: "start",
          sortable: true,
          value: "designacao",
          width: "25%"
        },
        { text: "Município", sortable: false, align: "start", value: "municipio", width: "25%" },
        { text: "Endereço", align: "start", value: "endereco", width: "25%" },
        { text: "Responsável", align: "start", value: "Userresponsavel", width: "10%" },
        { text: "Ações", sortable: false, align: "start", value: "actions", width: "15%" },
      ],
      labelSearch: "Procure por Designação, Endereço ou Responsável",
      
      selectForm: "",
      SelectedItemsForm: [],
      BreadCrumbs: [
        {
          text: "Página Inicial",
          disabled: false,
          href: "/",
        },
        {
          text: "Quadro II - A",
          disabled: true,
          href: "/inventarioProtecao",
        },
      ],
      campos: base,
      isNew: true,
      insert: false,
      titulo: "Inventário de Proteção do Patrimônio Cultural",
      sub: "Novo Formulário",
    };
  },
  watch: {
    "$route.params.id"(val) {
      if (val) {
        this.edit({ id: val });
      }
    },
    selectForm(){
      var tipoFormulario = this.selectForm

      if(!this.$route.params.id){
        this.$store.dispatch('loadingBasic', "Aguarde, por favor")
        setTimeout(() => {
          var _campos = this.getCampos(tipoFormulario)
          this.resetJson(_campos)
          this.campos = _campos
          this.campos[0].value = tipoFormulario
          this.$store.state.upload.dialog = false
        }, 1000)

      }else{
        var _campos = this.getCampos(tipoFormulario)
        this.saveEditItemToJson(_campos)
        this.campos = _campos
        this.campos[0].value = tipoFormulario
      }
    }
  },
  computed: {
    terminadoBtn(){
      const terminado = this.campos[0]["campos"].find(element => element.name == 'terminado');
      if(terminado.value != null){
        return terminado.value
      }else{
        return false
      }
    },
    EditId() {
      return !_.isEmpty(this.$store.state.EditForm) ? this.$store.state.EditForm[0].id : null;
    },
    isSmOrXsScreen(){
      if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
        return true;
      }else{
        return false;
      }
    },
  },
  methods: {
    edit(item) {
      if (!this.$route.params.id) {
        this.$router.push({ path: `/inventarioProtecao/editar/${item.id}` });
      }

      var dados = {
        id: item.id,
        filtro: "id",
        terminado: false,
        editar: true,
      };
      this.$store.dispatch("getForm", dados).then(() => {
        item = this.$store.state.EditForm[0];
        var type = item.tipo
        this.selectForm = type
        this.campos[0].value = type;
        this.campos = this.getCampos(type)

        this.saveEditItemToJson(this.campos)

        this.isNew = false;
        this.insert = !this.insert;
      });
    },
    getCampos(val){
      if(val){
        var _campos = []
        switch(val){
          case "EstruturasArquitetonicasUrbanisticas":
          case "ManifestacoesCulturais":
              _campos = base
              return _campos
          case "BensMoveisIntegrados":
              _campos = modeloBemMoveis
              return _campos
          case "Acervos":
              _campos = modeloAcervos
              return _campos
          case "PatrimonioArqueologico":
              _campos = modeloPatrimonio
              return _campos
          case "SitiosNaturais":
              _campos = modeloSitiosNaturais
              return _campos
          case "ConjuntosUrbanos":
              _campos = modeloConjuntosUrbanos
              return _campos
          case "Celebracao":
              _campos = modeloCelebracao
              return _campos
          case "FormasExpressao":
              _campos = modeloformasExpressao
              return _campos
          case "Lugares":
              _campos = modeloLugares
              return _campos
          case "Saberes":
              _campos = modeloSaberes
              return _campos
          case "Oficios":
              _campos = modeloOficios
              return _campos
        }
        return []
      }
    },
    saveEditItemToJson(json){
      if(json){
          var item = this.$store.state.EditForm[0];
          json.map((subTitulos) => {
          subTitulos.campos.map(campo => {
            if(campo.name == 'tipo'){
              campo.show = false
            }else if (campo.type == "file") {
              campo.idFile = this.EditId;
              campo.value = ""
              campo.show = true
            }else{
              campo.value = item[campo.name];
              if(campo.name == "terminado"){
                campo.value = false
              }else if(campo.type == 'number' && _.isEmpty(campo.value)){
                campo.value = 0
              }
            }
            if(campo.type=="radio" && item[`${campo.name}_especificar`]){
              campo.especificar = item[campo.name+"_especificar"]
            }
          });
        })
      }
    },
    resetJson(json){
      if(json){
        json.map(div => {
          div.campos.map(element => {
            if(element.name == 'tipo' && element.disabled){
              element.show = false
              element.disabled = false
            }
            if(element.type == 'switch'){
              element.value = false
            }else{
              element.value = ""
            }
          })
        })
      }
    },
  },
};
</script>