<template>
  <v-container>
      <Header :titulo="titulo" :itemsBreadCrumb="BreadCrumbs" />
      <div>
        <div v-if="insert">
          <v-card>
            <v-col cols="12" :class="isXsScreen ? 'text-center' : 'text-left ml-3'">
              <BtnAddBack :sub="sub" :insert="insert" @setInsert="(val) => {insert = val;}" />
            </v-col>
            <v-select class="mx-5 mt-4" outlined dense v-model="selectForm" :items="SelectedItemsForm" label="Tipo de Formulário"></v-select>
            <div style="background-color: white">
                <div v-if="selectForm && $store.state.upload.dialog == false">
                  <div>
                      <Stepper :campos="campos" />
                  </div>
                  <div class="mt-9">
                    <BtnActions :tipoDocumento="'formulario'" :tipoFormulario="campos[0].value" :tipoQuadro="tipoQuadro" :editId="EditId" :isNew="isNew" :campos="campos"  :Actions="[{type: 'terminado', terminado: terminadoBtn, cols:'12'}]" />
                  </div>
                </div>
            </div>
          </v-card>
        </div>
        <div v-else>
          <ModalBaseTable :actions="actions" :ationsBtn="btnOption" :DataSearch="dataSearch" :edit="edit" :hasSelect="true" :headers="headers" :labelSearch="labelSearch" :getData="getData" :tipoDocumento="'formulario'" :terminado="false" :SelectedItems="SelectedItems" :sub="sub" :insert="insert" @getInsert="(val) => { insert = val }" />
        </div>
      </div>
    </v-container>
</template>

<script>
import _ from "lodash";
import Stepper from '../../../components/InputForm/Stepper.vue'
import BtnActions from '../../../components/InputForm/BtnAction.vue';
import Header from "../../../components/Header.vue";
import BtnAddBack from "../../../components/BtnAddBack.vue";
import ModalBaseTable from "../../../components/DataTables/modelBaseData.vue";
/* formulários formato JSON */
import LaudoNhCp from '../../../assets/formularios/laudo/modelo_laudo_nh_cp_v1.json'
import LaudoSitiosNaturais from '../../../assets/formularios/laudo/modelo_laudo_sitios_naturais_v1.json'
import LaudoBemImovel from '../../../assets/formularios/laudo/modelo_laudo_bem_imovel_v1.json'
import LaudoBemMovelIntegrado from '../../../assets/formularios/laudo/modelo_laudo_bem_movel_v1.json'
import LaudoDocumentosArquivos from '../../../assets/formularios/laudo/modelo_laudo_documentos_v1.json'
import RelatorioSalvaguarda from '../../../assets/formularios/laudo/modelo_laudo_relatorio_v1.json'
import LaudoBase from '../../../assets/formularios/laudo/modelo_base_laudo.json'
import DiagnosticoEducacao from '../../../assets/formularios/laudo/modelo_laudo_diagnostico.json'
import Revalidacao from '../../../assets/formularios/laudo/modelo_laudo_revalidacao.json'

export default {
  mounted(){
    var num = this.$route.params.num
    switch (num) {
      case "A":
        this.SelectedItems = [
          { text: "Laudo NH CP", value: "LaudoNhCp"},
          { text: "Laudo Sitios Naturais", value: "LaudoSitiosNaturais"},
          { text: "Laudo Bem Imóvel", value: "LaudoBemImovel"},
          { text: "Laudo Bem Móvel E Integrado", value: "LaudoBemMovelIntegrado"},
          { text: "Laudo Documentos e Arquivos", value: "LaudoDocumentosArquivos"},
          { text: "Todos os Documentos", value: "*"}
        ]
        this.headers = [
          { text: "Tipo de Formulario", align: "start", sortable: false, value: "titulo", width: "25%"},
          { text: "Nome do Bem Tombado", align: "start", sortable: false, value: "nomeBemTombado", width: "25%"},
          { text: "Endereço", align: "start", value: "endereco", width: "25%" },
          { text: "Responsável", align: "start", value: "Userresponsavel", width: "10%" },
          { text: "Ações", sortable: false, align: "start", value: "actions", width: "15%" },
        ],
        this.tipoQuadro = "QUADRO III - Laudos"
        this.getData[0].tipo = this.tipoQuadro
        this.dataSearch[0].tipo = this.tipoQuadro
        break;
      case "B":
        this.SelectedItems = [
          { text: "Relatório Salvaguarda", value: "RelatorioSalvaguarda"},
          { text: "Todos os Documentos", value: "*"}
        ]
        this.tipoQuadro = "QUADRO III - Salvaguarda"
        this.getData[0].tipo = this.tipoQuadro
        this.dataSearch[0].tipo = this.tipoQuadro
        break;
      case "C":
        this.SelectedItems = [
          { text: "Diagnóstico e Plano de Ação de Educação para o Patrimônio e Difusão", value: "DiagnosticoEducacao"},
          { text: "Todos os Documentos", value: "*"}
        ]
        this.tipoQuadro = "QUADRO III - Educação"
        this.getData[0].tipo = this.tipoQuadro
        this.dataSearch[0].tipo = this.tipoQuadro
        break;
      case "D":
        this.SelectedItems = [
          { text: "Revalidação", value: "Revalidacao"},
          { text: "Todos os Documentos", value: "*"}
        ]
        this.tipoQuadro = "QUADRO III - Revalidação"
        this.getData[0].tipo = this.tipoQuadro
        this.dataSearch[0].tipo = this.tipoQuadro
        break;
      default:
        window.location.href = "/quadro invalido"
        break;
    }

    this.SelectedItemsForm = _.clone(this.SelectedItems)
    this.SelectedItemsForm.pop()

    if (this.$route.params.id) {
      this.edit({ id: this.$route.params.id });
    }

    this.titulo += this.tipoQuadro.split(" ")[3] ? this.tipoQuadro.split(" ")[3] : ""
  },
  data(){
    return{
      btnOption: [
        { cols: "6" },
        { cols: "3", type: "download" },
        { cols: "3", type: "remove" },
      ],
      tipoQuadro: '',
      dataSearch: [{ tipo: "", tipoForm: "*", terminado: false, filtro: "formulario"}],
      actions: ["edit", "delete","view", "download"],
      headers: [
        { text: "Tipo de Formulario", align: "start", sortable: false, value: "titulo", width: "25%"},
        { text: "Endereço", align: "start", value: "endereco", width: "25%" },
        { text: "Responsável", align: "start", value: "Userresponsavel", width: "25%" },
        { text: "Ações", sortable: false, align: "start", value: "actions", width: "25%" },
      ],
      tipoDocumento: "formulario",
      labelSearch: "Procure por Endereço ou Responsável",
      getData: [
        {
          filtro: "quadro",
          tipo: "",
          terminado: false
        }
      ],
      insert: false,
      titulo: "Quadro III - ",
      sub: "Novo Formulário",
      BreadCrumbs: [
        {
          text: "Página Inicial",
          disabled: false,
          href: "/",
        },
        {
          text: "Quadro III",
          disabled: true,
          href: "/quadro_III",
        },
      ],
      isNew: true,
      selectForm: "",
      SelectedItems: [],
      SelectedItemsForm: [],
      campos: LaudoBase,
    }
  },
  components:{
    ModalBaseTable,
    BtnActions,
    Header,
    BtnAddBack,
    Stepper
  },
  watch:{
    "$route.params.id"(val) {
      if (val) {
        this.edit({ id: val });
      }
    },
    selectForm(){
      var val = this.selectForm
      var tipoFormulario = this.selectForm
      if(val){
        if(!this.$route.params.id){
          this.$store.dispatch('loadingBasic', "Aguarde, por favor")
          setTimeout(() => {
            var _campos = this.getCampos(tipoFormulario)
            this.resetJson(_campos)
            this.campos = _campos
            this.campos[0].value = tipoFormulario
            this.$store.state.upload.dialog = false
          }, 1000)

        }else{
          var _campos = this.getCampos(tipoFormulario)
          this.campos = _campos
          
          this.campos[0].value = tipoFormulario
        }
      }
    },
  },
  computed:{
    terminadoBtn(){
      const terminado = this.campos[0]["campos"].find(element => element.name == 'terminado');
      if(terminado.value != null){
        return terminado.value
      }else{
        return false
      }
    },
    EditId() {
      return !_.isEmpty(this.$store.state.EditForm) ? this.$store.state.EditForm[0].id : null;
    },
    isSmOrXsScreen(){
      if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
        return true;
      }else{
        return false;
      }
    },
    isXsScreen(){
      if(this.$vuetify.breakpoint.xs){
        return true;
      }else{
        return false;
      }
    }
  },
  methods: {
    edit(item) {
      if (!this.$route.params.id) {
        this.$router.push({ path: `/quadro_III/${this.$route.params.num}/editar/${item.id}` }); 
      }

      var dados = {
        id: item.id,
        filtro: "id",
        terminado: false,
        editar: true,
      };
      this.$store.dispatch("getForm", dados).then(() => {
        item = this.$store.state.EditForm[0];
        
        var tipoLaudo = item.tipo
        this.selectForm = tipoLaudo
        
        this.campos[0].value = tipoLaudo;

        this.campos = this.getCampos(tipoLaudo)
        
        this.saveEditItemToJson(this.campos)

        this.isNew = false;
        this.insert = !this.insert;
      })
    },
    getCampos(val){
      if(val){
        var _campos = []
        switch(val){
          case "LaudoNhCp":
            _campos = LaudoNhCp
            return _campos
          case "LaudoSitiosNaturais":
            _campos = LaudoSitiosNaturais
            return _campos
          case "LaudoBemImovel":
            _campos = LaudoBemImovel
            return _campos
          case "LaudoBemMovelIntegrado":
            _campos = LaudoBemMovelIntegrado
            return _campos
          case "LaudoDocumentosArquivos":
            _campos = LaudoDocumentosArquivos
            return _campos
          case "RelatorioSalvaguarda":
            _campos = RelatorioSalvaguarda
            return _campos
          case "DiagnosticoEducacao":
            _campos = DiagnosticoEducacao
            return _campos
          case "Revalidacao":
            _campos = Revalidacao
            return _campos
        }
        return []
      }
    },
    saveEditItemToJson(json){
      if(json){
        var item = this.$store.state.EditForm[0];
          json.map((subTitulos) => {
            subTitulos.campos.map(campo => {
              if(campo.name == 'tipo'){
                campo.show = false
              }else if (campo.type == "file") {
                campo.idFile = this.EditId;
                campo.value = ""
              }else{
                campo.value = item[campo.name];
                if(campo.name == "terminado"){
                  campo.value = false
                }else if(campo.type == 'number' && _.isEmpty(campo.value)){
                  campo.value = 0
                }
              }
              if(campo.type=="radio" && item[`${campo.name}_especificar`]){
                campo.especificar = item[campo.name+"_especificar"]
              }
              if(campo.type == "radioMultipleInputs"){
                if(!_.isArray(campo.subElement) && campo.value == campo.showElement){
                  campo.subValue = item[campo.subElement]
                }else if(campo.value == campo.showElement){
                  var values = []
                  campo.subElement.map(element => {
                    values.push(item[element])
                  })
                  campo.subValue = values
                }
              }
            })
          });
      }
    },
    resetJson(json){
      if(json){
        json.map(div => {
          div.campos.map(element => {
            if(element.name == 'tipo' && element.disabled){
              element.show = false
              element.disabled = false
            }
            if(element.type == 'switch'){
              element.value = false
            }else{
              element.value = ""
            }
          })
        })
      }
    },
  }, 
};
</script>
