<template>
  <NotFoundPage :title="title" :description="description" :icon="icon" />
</template>

<script>
import NotFoundPage from '../../components/WarningComponents/NotFound.vue'

export default {
  data: () => {
    return {
      icon: null,
      title: '404 - Conteúdo não encontrado',
      description: 'A página ou conteúdo a que tentou aceder não existe, não se encontra disponível ou pode ter mudado de endereço.',
    }
  },
  components: {
    NotFoundPage
  }  
}
</script>