<template>
  <v-container>
    <Header :titulo="titulo" :itemsBreadCrumb="BreadCrumbs" />

    <div>
      <div v-if="insert">
        <v-card>
          <v-col cols="12" :class="isSmOrXsScreen ? 'text-center' : 'text-left ml-3'">
              <BtnAddBack :sub="sub" :insert="insert" @setInsert=" (val) => { insert = val; } " />
          </v-col>
          
          <div class="mt-4" style="background-color: white">
            <div class="mb-n11">
              <Inputs :campos="campos" />
            </div>
            <div>
              <BtnActions :tipoDocumento="'docGestaoICMS'" :tipoQuadro="quadro" :editId="EditId" :isNew="isNew" :campos="campos" :Actions="[{ type: 'terminado', terminado: false, cols: '12' }]" />
            </div>
          </div>
        </v-card>
      </div>

      <div v-else>
        <ModalBaseTable :actions="actions" :ationsBtn="btnOption" :DataSearch="dataSearch" :edit="edit" :hasSelect="true" :headers="headers" :labelSearch="labelSearch" :getData="getData" :tipoDocumento="'docGestaoICMS'" :sub="sub" :insert="insert" @getInsert="(val) => { insert = val }" />
      </div>
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import Header from "../../../components/Header.vue";
import BtnAddBack from "../../../components/BtnAddBack.vue";
import Inputs from "../../../components/InputForm/Input.vue";
import BtnActions from "../../../components/InputForm/BtnAction.vue";
import ModalBaseTable from "../../../components/DataTables/modelBaseData.vue"

export default {
  mounted() {
    this.$store.dispatch("getNotifications")
    var quadro = "";
    var nomeBreadCrumb = "";

    switch (this.$route.params.num) {
      case "I":
        quadro = "I";
        this.tipoDoc = "QUADRO I";
        this.quadro = "QUADRO I - A";
        this.titulo = "Quadro I - Gestão";
        nomeBreadCrumb = "Quadro I - Gestão";
        break;
      case "II B":
        quadro = "II B";
        this.tipoDoc = "QUADRO II - B";
        this.quadro = "QUADRO II - B";
        nomeBreadCrumb = "Quadro II - B";
        this.titulo =
          "Processos de Tombamento de Bens Materiais, na esfera Municipal";
        break;
      case "II C":
        quadro = "II C";
        this.tipoDoc = "QUADRO II - C";
        this.quadro = "QUADRO II - C";
        nomeBreadCrumb = "Quadro II - C";
        this.titulo =
          "Processos de Registro de Bens Imateriais, na esfera Municipal";
        break;

      default:
        window.location.href = "/nao encontrado";
        break;
    }

    this.BreadCrumbs = [
      {
        text: "Página Inicial",
        disabled: false,
        href: "/",
      },
      {
        text: nomeBreadCrumb,
        disabled: true,
        href: `/quadro/${quadro}`,
      },
    ];
    
    this.campos = [
      {
        show: true,
        name: "nome",
        type: "text",
        label: "Nome do Documento",
        required: true,
        value: "",
        cols: "12",
      },
      { show: true, name: "tipo", type: "", value: this.tipoDoc },
      { show: true, name: "encaminhado", type: "", value: "" },
    ];

    
    if (this.$route.params.id) {
      this.edit({ id: this.$route.params.id });
    }

    if(!_.isEmpty(this.tipoDoc)){
      this.dataSearch = [{ tipo: this.tipoDoc, filtro: "documentosGestao" }]
      this.getData = [{
        tipo: this.tipoDoc,
        route: "getDocsGestao",
      }]
    }
  },
  data() {
    return {
      titulo: "",
      quadro: "",
      tipoDoc: "",
      BreadCrumbs: [],
      response: {},
      sub: "Novo Documento",
      labelSearch: "Procure pelo nome do documento",
      insert: false,
      dataSearch: [{ tipo: "", filtro: "documentosGestao" }],
      search: "",
      page: 0,
      rows: 10,
      sortBy: "",
      sortDesc: false,
      totalRows: 0,
      loading: true,
      campos: [],
      isNew: true,
      getData: [],
      headers: [
        {
          text: "Documentos",
          align: "start",
          sortable: true,
          value: "nome",
          width: "55%",
        },
        {
          text: "Já foram encaminhados?",
          align: "center",
          sortable: true,
          value: "encaminhado",
          width: "30%",
        },
        {
          text: "Ações",
          sortable: false,
          align: "center",
          value: "actions",
          width: "15%",
        },
      ],
      actions: ["edit", "delete"],
      hasSelect: true,
      btnOption: [{ cols: "9" }, { cols: "3", type: "remove" }],
    };
  },
  components: {
    Header,
    ModalBaseTable,
    BtnAddBack,
    Inputs,
    BtnActions,
  },
  computed: {
    EditId() {
      return !_.isEmpty(this.$store.state.EditForm)
        ? this.$store.state.EditForm[0].id
        : null;
    },
    isXsScreen() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      } else {
        return false;
      }
    },
    isSmOrXsScreen(){
      if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
        return true;
      }else{
        return false;
      }
    },
  },
  methods: {
    edit(item) {
      if (!this.$route.params.id) {
        this.$router.push({ path: `/quadro/${this.$route.params.num}/editar/${item.id}` });
      }
        
      var dados = {
        tipo: this.tipoDoc,  
        id: item.id,
        filtro: "id",
        editar: true,
      };

      this.$store.dispatch("getDocsGestao", dados).then(() => {
        item = this.$store.state.EditForm[0];
        
        /* passagem de valores */
        this.campos[0].value = item.nome;
        this.campos[2].value = item.encaminhado;
        this.isNew = false;
        this.insert = !this.insert;
      });
    },
  },
};
</script>