<template>
  <div class="conteudo-centro d-flex" style="align-items:center; height:100%; margin-top: -50px">
    <v-container>
      <v-layout  row wrap >
        <v-flex  xs12 >
          <v-card color="transparent" flat >
            <div class="display-3 mt-5 mb-5" :style="isSmOrXsScreen ? 'font-size: 40px !important; color: red' : 'color:red'" >{{title}} <v-icon style="color:red;font-size:50px">{{icon}}</v-icon></div>
            <div class="grey--text lighten-5 mb-5" :style="isSmOrXsScreen ? 'font-size:20px !important' : 'font-size:25px'">
              {{description}}
            </div>
            <div class="mt-2" :style="isSmOrXsScreen ? 'font-size: 20px !important' : 'font-size: 25px'"> 
              Volte para a <a href="/">página inícial</a>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
    icon: String
  },
  computed: {
    isSmOrXsScreen(){
      if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
        return true;
      }else{
        return false;
      }
    },
  },
}
</script>