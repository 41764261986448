<template>
  <v-app>
    <div id="app" class="mb-15">
      <SideBar />
    </div>

    <router-view :key="$route.fullPath" />
    <v-btn v-scroll="onScroll" v-show="fab && this.$route.name != 'login'" fab dark fixed bottom right color="bgp" @click="toTop">
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
    
    <Loader :text="this.$store.state.upload.text" :colorLine="this.$store.state.upload.color" :dialog="this.$store.state.upload.dialog" :value="this.$store.state.upload.value" />
  </v-app>
</template>

<script>
import SideBar from "./components/SideBar/SideBar.vue";
import Loader from './components/WarningComponents/Loader.vue';

export default {
  data: () => {
    return {
      fab: false,
    }
  },
  mounted() {
    this.$store.dispatch('tokenExpired')
    this.$store.dispatch("resetData")
  },
  components: {
    SideBar,
    Loader,
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  }
};
</script>

<style>
  @import './assets/css/app.css';
</style>
