import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import NotFound from '../views/WarningPages/NotFound.vue'
import permissions from '../views/WarningPages/permissoes.vue'
import store from '../store/index.js'
/* Users */
import Utilizadores from '../views/Users/users.vue'
/* ICMS */
/* Quadro Gestao */
import Quadros_Gestao from '../views/ICMS/Quadros/Quadros_Gestao.vue'

import ArquivosFinalizados from '../views/ICMS/ArquivosFinalizados.vue'
/* Quadro II */
import InventarioProtecao from '../views/ICMS/Quadros/Quadro_II_A.vue'

/* Quadro III */
import Quadro_III from '../views/ICMS/Quadros/Quadro_III.vue'
/* Tombamentos e Registros */
import Arquivo from '../views/Tombamentos_Registos/ArquivosFinalizados.vue'
import FormularioNovoBem from '../views/Tombamentos_Registos/FormulariosNovoBem.vue'
import NovoBemDigitalizado from '../views/Tombamentos_Registos/NovoBemDigitalizado.vue'
import _ from 'lodash';

/* Documentos Gestão */
import sumarios from '../views/Gestao/sumarios.vue';
import etiquetas from '../views/Gestao/etiquetas.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {requireAuth: false},
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {requireAuth: false},
    beforeEnter: (to, from, next) => {
      if(!_.isEmpty(store.getters.User.token)){
        next("/")
      }else {
        next()
      }
    }
  },
  {
    path: "/usuarios/:editar?/:id?",
    name: "usuarios",
    component: Utilizadores,
    beforeEnter: (to, from, next) => {
      if(store.getters.User.level != 1){
        if(to.path == "/usuarios/editar/" + store.getters.User.id){
          next()
        }else {
          next("/permissoes")
        }
      }else{
        next()
      }
    },
  },
  {
    path:'*',
    name: "404",
    component:NotFound,
  },
  {
    path: "/permissoes",
    name: "permissoes",
    component: permissions,
  },
  /* ICMS */

  // quadro gestão
  {
    path: "/quadro/:num/:editar?/:id?",
    name: "quadro",
    component: Quadros_Gestao,
    beforeEnter: (to, from, next) => {
      if(store.getters.User.level != 1){
        if(to.path == "/usuarios/editar/" + store.getters.User.id){
          next()
        }else {
          next("/permissoes")
        }
      }else{
        next()
      }
    },
  },
  // quadro 2
  {
    path: "/inventarioProtecao/:editar?/:id?",
    name: "inventarioProtecao",
    component: InventarioProtecao
  },  
  {
    path: "/quadro_III/:num/:editar?/:id?",
    name: "quadro_III",
    component: Quadro_III
  },

  {
    path: "/arquivosFinalizadosICMS/:editar?/:id?",
    name: "arquivosFinalizadosICMS",
    component: ArquivosFinalizados
  },
  /* TOMBAMENTOS E REGISTROS */
  {
    path: "/arquivosFinalizadosNovoBem/:editar?/:id?/:tipo?",
    name: "arquivosFinalizadosNovoBem",
    component: Arquivo
  },
  {
    path: "/novoBem/:editar?/:id?",
    name: "novoBem",
    component: FormularioNovoBem
  },
  {
    path: "/novoBemDigitalizado/:editar?/:id?",
    name: "novoBemDigitalizado",
    component: NovoBemDigitalizado
  },
  /* Gestão documentos  */
  {
    path: "/sumarios/:editar?/:id?",
    name: "sumarios",
    component: sumarios,
    beforeEnter: (to, from, next) => {
      if(store.getters.User.level != 1){
        if(to.path == "/usuarios/editar/" + store.getters.User.id){
          next()
        }else {
          next("/permissoes")
        }
      }else{
        next()
      }
    },
  },
  {
    path: "/etiquetas/:editar?/:id?",
    name: "etiquetas",
    component: etiquetas,
    beforeEnter: (to, from, next) => {
      if(store.getters.User.level != 1){
        if(to.path == "/usuarios/editar/" + store.getters.User.id){
          next()
        }else {
          next("/permissoes")
        }
      }else{
        next()
      }
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to,from,next) => {
  if(to.meta.requireAuth == false){
    next();
  }else if(store.getters.User.token != ''){
    store.dispatch("tokenExpired")
    next();
  }else{
    next("/")
  }

  
});

export default router
