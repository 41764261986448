<template>
    <div>
      <div v-if="isDashboard">
      </div> 
      <v-row v-else>
          <v-col cols="12" sm="12" md="12" lg="12" xl="8" :class="isLowerLgScreen ? 'mb-n8': ''">
             <h1 :class="isLowerLgScreen ? 'text-center' : 'text-left justify-start d-flex'">{{ titulo }}</h1> 
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="4">
            <div style="" class="text-right" v-if="itemsBreadCrumb">
                <v-breadcrumbs style="white-space: nowrap; display: block; overflow-x: auto;" :items="itemsBreadCrumb">
                  <template v-slot:divider>
                    <v-icon class="grey--text">mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
            </div>
          </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
      titulo: {type: String, default: ""},
      itemsBreadCrumb: {type: Array, default: null },
      isDashboard: {type: Boolean, default: null },
    },
    computed: {
      isLowerLgScreen(){
        if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg){
          return true;
        }else{
          return false;
        }
      }
    }
}
</script>