<template>
  <v-card-title>
    <!-- <v-spacer></v-spacer> -->
    <v-text-field
      v-model.trim="search"
      :value="search"
      prepend-inner-icon="mdi-magnify"
      :label="label"
      single-line
      hide-details
      @click:clear="reset()"
      clearable
    ></v-text-field>
  </v-card-title>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'

export default {
  props: {
    label: String,
    dataSearch: Array,
    rows: Number,
    page: Number,
    sortBy: String,
    sortDesc: Boolean,
    resetData: Function
  },
  data: () => {
    return {
      search: "",
    };
  },
  watch: {
    dataSearch(){
      /* terminado refere-se ao loading -> ou seja loading = true */
      this.$emit("terminado", true);
      this.search = ""
      this.resetData()
    },
    page(){
      if(!_.isEmpty(this.search)){
        this.getItems();
      }
    },
    rows(){
      if(!_.isEmpty(this.search)){
        this.getItems();
      }
    },
    sortBy(){
      if(!_.isEmpty(this.search)){
        this.getItems();
      }
    },
    sortDesc(){
      if(!_.isEmpty(this.search)){
        this.getItems();
      }
    },
    search(val) {
      val = _.escape(val)
      this.$emit("search", val.trim());
      this.$emit("terminado", true);
      if(val.trim()){
        val = val.trim();
        this.$emit("search", val);
        this.dataSearch[0].pesquisa = val
        this.getItems();
      }else{
        this.$emit("search", val);
        this.resetData()
      }
    },
  },
  methods:{
    getItems(){
      var _orderBy = ""
      if(!_.isEmpty(this.sortBy)){
        _orderBy = `/${this.sortBy}`
        if(this.sortDesc){
          _orderBy += "/true"
        }
      }
      switch(this.dataSearch[0].filtro){
        case "formulario":
          this.$store.dispatch("searchForm", {orderBy: _orderBy, page: this.page, rows: this.rows, search: this.dataSearch[0]}).then(result => {
            this.$emit("total", result.totalRows);
          }).finally(() => {
            this.$emit("terminado", false);
            this.$emit("dados", this.$store.state.DadosFormulario);
          });
          break
        case "Users":
          axios.get(`/api/auth/users/search/${this.page}/${this.rows+_orderBy}`, { params: { pesquisa: this.search } }).then((response) => {
            this.users = response.data.campos;
            this.$emit("getUsers", response.data.campos);
            this.$emit("total", parseInt(response.data.total));
          }).finally(() => {
            this.$emit("terminado", false);
            this.$emit("dados", this.$store.state.DadosFormulario);
          });
          break
        case "documentosGestao": 
          this.$store.dispatch("searchDocsGestao", {orderBy: _orderBy, page: this.page, rows: this.rows, search: this.dataSearch[0]}).then(result => {
            this.$emit("total", result.totalRows);
          }).finally(() => {
              this.$emit("terminado", false);
              this.$emit("dados", this.$store.state.DadosFormulario);
            });
          break;
        case "gestaoSumario": 
          this.$store.dispatch("searchGestao", {orderBy: _orderBy, page: this.page, rows: this.rows, route: "sumarioGestao", search: this.dataSearch[0]}).then(result => {
            this.$emit("total", result.totalRows);
          }).finally(() => {
              this.$emit("terminado", false);
              this.$emit("dados", this.$store.state.DadosFormulario);
            });
          break;
        case "gestaoEtiqueta":
          this.$store.dispatch("searchGestao", {orderBy: _orderBy, page: this.page, rows: this.rows, route: "etiquetaGestao", search: this.dataSearch[0]}).then(result => {
            this.$emit("total", result.totalRows);
          }).finally(() => {
              this.$emit("terminado", false);
              this.$emit("dados", this.$store.state.DadosFormulario);
            });
          break;
        default:
          this.$emit("terminado", false)
          break;
      }
    },
    reset(){
      this.search = ""
    }
  },
};
</script>