<template>
    <v-container>
        <Header :titulo="titulo" :itemsBreadCrumb="BreadCrumbs" />
        <div>
            <div v-if="insert">
                <v-card>
                    <v-col cols="12" :class="isSmOrXsScreen ? 'text-center' : 'text-left ml-3'">
                        <BtnAddBack :sub="sub" :insert="insert" @setInsert="(val) => {insert = val;}" />
                    </v-col>
                    <v-select class="mx-5 mt-4" outlined dense v-model="selectForm" :items="SelectedItemsForm" label="Tipo de Etiqueta"></v-select>
                    <div  v-if="selectForm && $store.state.upload.dialog == false" class="" style="background-color: white">
                        <Inputs :campos="campos" />

                        <div class="mt-9">
                            <BtnActions :tipoDocumento="'docGestaoEtiqueta'" :editId="EditId" :isNew="isNew" :campos="campos"  :Actions="[{type: 'terminado', terminado: false, cols:'12'}]" />
                        </div>
                    </div>
                </v-card>
            </div>

            <div v-else>
                <ModalBaseTable :actions="actions" :ationsBtn="btnOption" :DataSearch="dataSearch" :edit="edit" :hasSelect="true" :headers="headers" :labelSearch="labelSearch" :getData="getData" :tipoDocumento="'docGestaoEtiqueta'" :terminado="false" :SelectedItems="SelectedItems" :sub="sub" :insert="insert" @getInsert="(val) => { insert = val }" />
            </div>
        </div>
    </v-container>
</template>

<script>
import _ from "lodash";
import Header from "../../components/Header.vue";
import BtnAddBack from "../../components/BtnAddBack.vue";
import Inputs from "../../components/InputForm/Input.vue";
import BtnActions from "../../components/InputForm/BtnAction.vue";
import ModalBaseTable from '../../components/DataTables/modelBaseData.vue'
/* Json formulario */
import etiquetaComum from '../../../src/assets/formularios/gestaodocumentos/etiqueta_comum.json'
import etiquetaTombamento from '../../../src/assets/formularios/gestaodocumentos/etiqueta_tombamento_ou_registro.json'

export default {
    mounted(){
        this.SelectedItemsForm = _.clone(this.SelectedItems)
        this.SelectedItemsForm.pop()
        /* verificar se é um admin */
        if(this.$store.state.User.level == '1'){
            this.hasSelect = true
        }

        if (this.$route.params.id) {
            this.edit({ id: this.$route.params.id });
        }
    },
    data(){
        return{
            titulo: "Gestão Etiquetas",
            BreadCrumbs: [
                {
                    text: "Página Inicial",
                    disabled: false,
                    href: "/",
                },
                {
                    text: "Gestão - Etiquetas",
                    disabled: true,
                    href: "/etiquetas",
                },
            ],
            sub: "Nova Etiqueta",
            insert: false,
            dataSearch: [{ tipo: "",  filtro: "gestaoEtiqueta"}],
            labelSearch: "Procure pelo nome do documento",
            getData: [{
                tipo: "",
                route: "etiquetaGestao",
            }],
            campos: etiquetaComum,
            isNew: true,
            headers: [
                { text: "Nome da Etiqueta", align: "start", sortable: true, value:"nome", width: "30%"},
                { text: "Tipo", align: "start", sortable: true, value:"tipo", width: "20%"},
                { text: "Responsável", align: "center", sortable: true, value: "responsavel",  width: "30%"},
                { text: "Ações", sortable: false, align: "center", value: "actions", width: "20%" }
            ],
            actions: ["edit", "delete", "view", "download"],
            hasSelect: false,
            btnOption: [
                {cols: "6"},  
                {cols: "3", type: "download"},
                {cols: "3", type: "remove"}
            ],
            selectForm: "",
            SelectedItemsForm: [],
            SelectedItems: [
                { text: "Etiqueta Comum", value: "Etiqueta Comum"},
                { text: "Etiqueta Tombamento ou Registro", value: "Etiqueta Tombamento ou Registro"},
                { text: "Todos", value: "*"}
            ],
        }
    },
    components:{
        Header,
        BtnAddBack,
        Inputs,
        ModalBaseTable,
        BtnActions,
    },
    watch:{
        insert(){
            if (!this.$route.params.id) {
                this.resetJson(this.campos)
            }
        },
        selectForm(){
            var val = this.selectForm
            var tipoFormulario = this.selectForm
            
            if(val){
                if(!this.$route.params.id){
                    this.$store.dispatch('loadingBasic', "Aguarde, por favor")
                    setTimeout(() => {
                        var _campos = this.getCampos(tipoFormulario)
                        this.resetJson(_campos)
                        this.campos = _campos
                        this.campos[0].value = tipoFormulario
                        this.$store.state.upload.dialog = false
                    }, 1000)
                }else{
                    var _campos = this.getCampos(tipoFormulario)
                    this.campos = _campos
                    this.campos[0].value = tipoFormulario
                    this.saveEditItemToJson(this.campos)
                }
            }
        },
    },
    computed: {
        EditId() {
            return !_.isEmpty(this.$store.state.EditForm) ? this.$store.state.EditForm[0].id : null;
        },
        isXsScreen(){
            if(this.$vuetify.breakpoint.xs){
                return true;
            }else{
                return false;
            }
        },
        isSmOrXsScreen(){
            if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
                return true;
            }else{
                return false;
            }
        },
    },
    methods:{
        edit(item) {
            if (!this.$route.params.id) {
                this.$router.push({ path: `/etiquetas/editar/${item.id}` });
             }

            var dados = {
                id: item.id,
                editar: true,
                route: "etiquetaGestao"
             };

            this.$store.dispatch("getGestao", dados).then(() => {
                item = this.$store.state.EditForm[0];
                this.selectForm = item.tipo 
                
                this.campos = this.getCampos(item.tipo)
                this.saveEditItemToJson(this.campos)

                this.isNew = false;
                this.insert = !this.insert;
            });
        },
        getCampos(val){
            if(val){
                var _campos = []
                switch(val){
                case "Etiqueta Comum":
                    _campos = etiquetaComum
                    return _campos
                case "Etiqueta Tombamento ou Registro":
                    _campos = etiquetaTombamento
                    return _campos
                }
                return []
            }
        },
        saveEditItemToJson(json){
            if(json){
                var item = this.$store.state.EditForm[0];
                /* passagem de valores */
                this.campos.map(campo => {
                    if (campo.type == "file") {
                        campo.idFile = this.EditId;
                        campo.value = ""
                        campo.show = true
                    }else{
                        campo.value = item[campo.name] ? item[campo.name] : item.campos[campo.name];
                        if(campo.type == 'number' && _.isEmpty(campo.value)){
                            campo.value = 0
                        }
                    }
                });
            }
        },
        resetJson(json){
            if(json){
                json.map(element => {
                    element.value = ""
                })
                this.campos = json
            }
        }
    }
}
</script>