<template>
    <div>
        <!-- <v-btn color="primary" class="float-left">Legenda de Imagens</v-btn> -->
        <v-row v-if="imgs[0].length > 0">
            <v-dialog
                justify="center"
                v-model="dialog"
                persistent
                max-width="650px"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Legendar Imagens</span>
                    </v-card-title>
                    <v-card-text>
                        <v-row v-for="img in imgs[0]" :key="img['name']">
                            <v-col cols="12">
                                <label style="color:black" :title="img['name']">{{convert(img['name'])}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field
                                label="Legenda da imagem"
                                :value="img['legenda']"
                                v-model.lazy="img['legenda']"
                                required
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field
                                label="Autoria da imagem"
                                :value="img['autoria']"
                                v-model.lazy="img['autoria']"
                                required
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-menu :close-on-content-click="true" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field 
                                        type="text"
                                        v-model="img['data']"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        min="0"
                                        label="Data"
                                        required
                                    />
                                    </template>
                                    <v-date-picker locale="pt" v-model="img['data']" ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                        color="grey darken-1"
                        style="opacity: 0.8;"
                        text
                        @click="dialog = false"
                        >
                        Cancelar
                        </v-btn>
                        <v-btn
                        color="green"
                        class="white--text"
                        @click="sendInfo()"
                        >
                        Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
export default {
    mounted(){
        
    },
    props: {
        imgs: Array,
        show: Boolean
    },
    data: () => {
        return {   
            dialog: false,
        }
    },
    methods: {
        convert(nameFile){
            if(nameFile.length > 45){
                var newTitle = nameFile.substring(0, 44)
                newTitle += "..." + nameFile.substring(nameFile.length - 6,nameFile.length)
                return newTitle
            }
            return nameFile
        },
        sendInfo(){
            this.$emit("newFiles", this.imgs)
            this.dialog = false
        }
    },
    watch: {
        show(){
            if(this.imgs.length>0){
                this.dialog = true
            }
        }
    }
}
</script>