<template>
  <v-container>
    <div class="mb-3">
      <Header :isDashboard="true" />
    </div>
    <!-- NovoBem -->
    <div>
      <ModalBaseTable :actions="actions" :ationsBtn="btnOption" :DataSearch="formNovoBem.dataSearch" :hasSelect="true" :headers="formNovoBem.headers" :labelSearch="formNovoBem.labelSearch" :getData="formNovoBem.getData" :tipoDocumento="'formulario'" :terminado="true" :SelectedItems="SelectedItems" :selectItem="'novoBem*'" :disabledSelect="true" />
    </div>
    <!-- ICMS -->
    <div class="my-15">
      <ModalBaseTable :actions="actions" :ationsBtn="btnOption" :hasSelect="true" :headers="formICMS.headers" :getData="formICMS.getData" :tipoDocumento="'formulario'" :terminado="true" :DataSearch="formICMS.dataSearch" :labelSearch="formICMS.labelSearch" :SelectedItems="SelectedItems" :selectItem="'QUADROS'" :disabledSelect="true" />
    </div>
  </v-container>
</template>

<script>
import Header from "../components/Header.vue";
import ModalBaseTable from '../components/DataTables/modelBaseData.vue'

export default {
  components: {
    Header,
    ModalBaseTable
  },
  mounted() {
    /* verificar se é admin */
    if(this.$store.state.User.level == "1"){
      /* verificar se existem documentos pro encaminhar */
      this.dataWarning = [
        {route: "/quadro/I", nome: "Quadro I - Gestão", num: 0, tipo: "QUADRO I" },
        {route: "/quadro/II B", nome: "Processos de Tombamento de Bens Materiais, na esfera Municipal", num: 0, tipo: "QUADRO II - B" },
        {route: "/quadro/II C", nome: "Processos de Registro de Bens Imateriais, na esfera Municipal", num: 0, tipo: "QUADRO II - C" }
      ]
      this.dataWarning.map(camp => {
        this.$store.dispatch("getDocsGestao", {tipo: camp.tipo, encaminhado: "false", filtro: "encaminhado"}).then(resp => {
          camp.num = resp
        })
      })
      
      this.warning = true
    }
  },
  data() {
    return {
      btnOption: [
        {cols: "9"},
        {cols: "3", type: "download"},
      ],
      dataWarning: [],
      warning: false,
      showBtn: false,
      
      actions: ["view", "download"],
      SelectedItems: [
        { text: "ICMS - Cultural", value: "QUADROS"},
        { text: "Tombamentos e Registros", value: "novoBem*"}
      ],
      formNovoBem: {
        labelSearch: "Procure por Endereço, Proteção ou Processo",
        headers: [
          { text: "Proteção", align: "start", sortable: true, value: "protecao", width: "25%"},
          { text: "Endereço", align: "start", sortable: true, value: "endereco", width: "25%"},
          { text: "Nº do Processo", align: "start", sortable: true, value: "processo", width: "15%"},
          { text: "Data da Última Alteração", align: "start", sortable: true, value: "update", width: "15%"},
          { text: "Ano", align: "start", value: "ano", width: "10%"},
          { text: "Ações", align: "start", sortable: false, value: "actions", width: "10%" },
        ],
        dataSearch: [{ tipo: "novoBem*", terminado: true, filtro: "formulario"}],
        getData: [{filtro: "novoBem*",terminado: true}],
      },
      formICMS: {
        labelSearch: "Procure por Endereço ou Município",
        headers: [
          { text: "Quadro", align: "start", sortable: true, value: "quadro", width: "20%" },
          { text: "Município", align: "start", sortable: false, value: "municipio", width: "25%" },
          { text: "Endereço", align: "start", value: "endereco", width: "25%" },
          { text: "Data da Última Alteração", align: "start", sortable: true, value: "update", width: "15%"},
          { text: "Ações", sortable: false, align: "start", value: "actions", width: "15%" },
        ],
        dataSearch: [{ tipo: "QUADROS", tipoForm: "*", terminado: true, filtro: "formulario"}],
        getData: [{filtro: "QUADROS", terminado: true}],
      },
      
    };
  },
};
</script>
