<template>
  <v-card-text>
    <SnackBar @dismiss="(visible) => {response.visible = visible;}"
      :snackbar="response.visible"
      :text="response.text"
      :type="response.type"
    />
    <DialogAlert :close="Close" :confirm="RemoveFile" :details="dialogDetails" />

    <v-row class="text-center">
      <v-col class="mb-5" v-for="item in Ficheiros" :key="item.nome" cols="12" sm="12" md="6" lg="3" xl="3">
        <v-card color="grey lighten-4" max-width="300">
          <v-row class="mx-1" v-if="!mostrarLegenda">
            <v-col cols="12">
              <img
                style="margin:0 auto; max-height:150px; max-width:100px"
                :src="'https://gespa.dev.weblevel.pt/api/auth/documentos/imagem/thumb/'+$store.state.User.id+'/'+item.id"
              />
            </v-col>
            <v-col cols="12">
              <a
                :title="item.nome"
                target="_blank"
                :href="'https://gespa.dev.weblevel.pt/api/auth/documentos/imagem/'+$store.state.User.id+'/'+item.id"
                :id="'a_' + item.id"
                >{{ Convert(item.nome) }}</a>
            </v-col>
            <v-col cols="12">
              <v-btn color="red" @click="DeleteConfirm(item)" class="white--text">Remover</v-btn>
            </v-col>
          </v-row>
          <v-row class="mx-1" v-else>
            <v-col cols="12">
              <img  
                style="margin:0 auto; max-height:150px; max-width:100px"
                :src="'https://gespa.dev.weblevel.pt/api/auth/documentos/imagem/thumb/'+$store.state.User.id+'/'+item.id"
              />
            </v-col>
            <v-col cols="12">
              <a
                :title="item.nome"
                target="_blank"
                :href="'https://gespa.dev.weblevel.pt/api/auth/documentos/imagem/'+$store.state.User.id+'/'+item.id"
                :id="'a_' + item.id"
                >{{ Convert(item.nome) }}</a>
            </v-col>
            <v-col cols="12">
              <v-text-field :value="item.legenda" v-model.lazy="item.legenda" :title="item.legenda" label="Legenda"></v-text-field>
            </v-col>
            <v-col cols="12" class="mt-n6">
              <v-text-field :value="item.autoria" v-model.lazy="item.autoria" :title="item.autoria" label="Autoria"></v-text-field>
            </v-col>
            <v-col cols="12" class="mt-n6">
              <v-menu :close-on-content-click="true" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                <v-text-field 
                    type="text"
                    v-model.lazy="item.data"
                    :value="item.data"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    min="0"
                    label="Data"
                    required
                />
                </template>
                <v-date-picker locale="pt" :value="item.data" v-model.lazy="item.data"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" class="mt-n6">
              <!-- <v-icon color="blue darken-4" large @click="saveLegend(item)">mdi-content-save-outline</v-icon> -->
              <!-- <v-icon color="red" @click="DeleteConfirm(item)">mdi-delete</v-icon> -->
              <v-btn color="blue" @click="saveDataImage(item)" class="white--text mr-1">Salvar</v-btn>
              <v-btn color="red" @click="DeleteConfirm(item)" class="white--text">Remover</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import SnackBar from "../WarningComponents/snackbar.vue";
import DialogAlert from '../WarningComponents/DialogAlert.vue';
import axios from 'axios';

export default {
  mounted() {
    if(this.$route.params.id){
      if (this.id) {
        var dados = {
          tipo: this.tipo, 
          id: this.id 
        }
        this.$store.dispatch("getDocuments", dados).then((result) => {
          result.map(img => {
            if(!Date.parse(img.data)){
              img.data = ""
            }
          })
          this.Ficheiros = result
        })
      }
    }
  },
  props: {
    tipo: String,
    id: String,
    mostrarLegenda: Boolean
  },
  components: {
    SnackBar,
    DialogAlert
  },
  data: () => {
    return {
      response: {},
      Ficheiros: [],
      dialogDetails: [{dialog: false}],
    };
  },
  watch: {
  },
  methods: {
    Close(){
      this.dialogDetails[0].dialog = false
    },
    Convert(item){
      if(item.length > 25){
          var newTitle = item.substring(0, 15)
          newTitle += "..." + item.substring(item.length - 6,item.length)
          return newTitle
      }
      return item
    },
    DeleteConfirm(file){
      this.dialogDetails[0].dialog = true
      this.dialogDetails[0].title = "Tem a certeza que pretende apagar?"
      this.dialogDetails[0].file = file
    },
    RemoveFile() {
      var item = this.dialogDetails[0].file
      this.dialogDetails[0].dialog = false
      
      var dados = {
        tipo: item.tipo,
        id: item.id,
        id_formulario: item.documento_id,
        nome: item.nome
      }

      this.$store.dispatch("removeDocument", dados).then((res) => {
          this.response = res;
          this.$store.dispatch("getDocuments", {tipo: item.tipo, id:this.id}).then((result) => {
            result.map(img => {
              if(!Date.parse(img.data)){
                img.data = ""
              }
            })
            this.Ficheiros = result
          });
        });
    },
    saveDataImage(item){
      axios.post("https://gespa.dev.weblevel.pt/api/auth/documentos/update",  { id:item.id, dados: item }).then(response => {
        this.response =  { type: 'success', text: response.data.message, visible: true }
      }).catch(error => {
        this.response =  { type: 'danger', text: error.response.data.message, visible: true }
      })
    }, 
  },
};
</script>