<template>
    <v-card-text>
        <SnackBar @dismiss="(visible) => {response.visible = visible;}"
        :snackbar="response.visible"
        :text="response.text"
        :type="response.type"
        />
        <DialogAlert :close="Close" :confirm="RemoveFile" :details="dialogDetails" />

        <v-card-title v-if="Ficheiros.length > 0">Ordem da Documentação Fotográfica</v-card-title>
        <draggable v-model="Ficheiros" class="row">
            <v-col
            class="mb-7"
            v-for="(item, index) in Ficheiros"
            :key="item.path"
            cols="6"
            sm="6"
            md="6"
            lg="3"
            xl="3"
            >
            <v-card color="grey lighten-4" style="cursor:move">
                <v-row class="mx-1">
                    <v-col cols="12" class="mb-n9">
                        <v-icon class="float-right" color="red" @click="DeleteConfirm(item)">mdi-delete</v-icon>
                    </v-col>
                    <v-col cols="12"> <!--255 max width-->
                        <v-img
                        :max-height="$vuetify.breakpoint.xl ? '150' : '100'"
                        class="mb-2"
                        :src="'https://gespa.dev.weblevel.pt/api/auth/documentos/imagem/thumb/'+$store.state.User.id+'/'+item.id"
                        ></v-img>
                        <a
                        :title="item.nome"
                        target="_blank"
                        :href="'https://gespa.dev.weblevel.pt/api/auth/documentos/imagem/'+$store.state.User.id+'/'+item.id"
                        >{{index = index += 1}} - {{ Convert(item.nome) }}</a>
                    </v-col>
                    <!-- <v-col cols="2">
                        <v-icon color="red" @click="DeleteConfirm(item.path)">mdi-delete</v-icon>
                    </v-col> -->
                </v-row>
            </v-card>
            </v-col>
            </draggable>
        </v-card-text>
</template>

<script>
import draggable from 'vuedraggable';
import DialogAlert from '../WarningComponents/DialogAlert.vue';
import SnackBar from "../WarningComponents/snackbar.vue";

export default {
    components: {
        draggable,
        DialogAlert,
        SnackBar
    },
    props: {
        idDocDigitalizado: String,    
    },
    mounted(){
        if(this.idDocDigitalizado){
            var dados = {
                tipo: 'dfDigitalizado',
                id: this.idDocDigitalizado 
            }

            this.$store.dispatch("getDocuments", dados).then((result) => {
                this.Ficheiros = result
            })
        }
    },
    data: () => {
        return {
            Ficheiros: [],
            dragging: false,
            response: {},
            dialogDetails: [{dialog: false}]
        }
    },
    watch: {
        Ficheiros(val){
            this.$emit("getFilesOrganize", val)    
        }
    },
    methods: {
        Close(){
            this.dialogDetails[0].dialog = false
        },
        DeleteConfirm(file){
            this.dialogDetails[0].dialog = true
            this.dialogDetails[0].title = "Tem a certeza que pretende apagar?"
            this.dialogDetails[0].description = "Se alterou as posições das imagens, guarde primeiro as alterações e depois remova a imagem"
            this.dialogDetails[0].file = file
        },
        RemoveFile() {
            var item = this.dialogDetails[0].file
            this.dialogDetails[0].dialog = false
            var dados = {
                tipo: item.tipo,
                id: item.id,
                id_formulario: item.documento_id,
                nome: item.nome
            }

            this.$store.dispatch("removeDocument", dados).then((res) => {
                this.response = res;
                this.$store.dispatch("getDocuments", {tipo: 'dfDigitalizado', id:this.idDocDigitalizado}).then((result) => {
                    this.Ficheiros = result
                });
            });
        },
        Convert(item){
            if(item.length > 25){
                var newTitle = item.substring(0, 15)
                newTitle += "..." + item.substring(item.length - 6,item.length)
                return newTitle
            }
            return item
        }
    },
}
</script>

<style scoped>
.v-card__text, .v-card__title {
  word-break: normal !important; 
}
</style>