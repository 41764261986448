<template>
    <div>
        <v-btn color="primary" @click="dialog = true" class="float-left">{{btnText}}</v-btn>
        <v-row>
            <v-dialog
                justify="center"
                v-model="dialog"
                persistent
                max-width="650px"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{title}}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-row v-for="(data, dataIndex) in dados" :key="dataIndex">
                            <v-col cols="12">
                                <label class="black--text text-h5 float-left"> {{btnText}} </label>
                                <v-btn color="red" class="float-right white--text" @click="removeItem(dataIndex)"><v-icon>mdi-delete</v-icon></v-btn>
                            </v-col>
                            <v-col v-for="(input, index) in data" :key="index"  cols="12" :sm="input.cols" :md="input.cols" :lg="input.cols" :xl="input.cols">
                                <template v-if="input.type == 'text' || input.type == 'number'">
                                    <input min="0" :placeholder="input.label" :title="input.label" class="border-input" :id="`${input.name}_${dataIndex}`" :value="input.value" :type="input.type" />
                                </template>
                                <template v-else-if="input.type == 'description'">
                                    <textarea class="border-textarea" min="0" :placeholder="input.label" :title="input.label" :id="`${input.name}_${dataIndex}`" :value="input.value" type="textarea"></textarea>
                                </template>
                                <template v-else-if="input.type == 'date'">
                                    <input min="0" :placeholder="input.label" :title="input.label" class="border-input" :id="`${input.name}_${dataIndex}`" :value="input.value" :type="input.type" />
                                </template>
                                <div v-if="input.type == 'radio'">
                                    <v-radio-group class="my-n2" :value="input.value" :multiple="input.multiple" :label="input.label" :title="input.label" :id="`${input.name}_${dataIndex}`">
                                        <div v-for="campo in input.options" :key="campo.value">
                                            <div>
                                            <v-radio
                                                :value="campo.value"
                                                :label="campo.text"
                                                @click="input.multiple ? onClickBoxMultiple(`${input.name}_${dataIndex}`, campo.value, input) : onClickBox(`${input.name}_${dataIndex}`, campo.value, input)"
                                            ></v-radio>
                                            </div>
                                        </div>
                                    </v-radio-group>
                                </div>
                            </v-col>
                        </v-row>
                        <template v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" class="my-9" >
                            <v-btn class="mt-5" color="primary" @click="newItem()">{{btnText}}</v-btn>
                        </template>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm" color="primary" @click="newItem()">{{btnText}}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                        color="grey darken-1"
                        style="opacity: 0.8;"
                        text
                        @click="dialog = false"
                        >
                        Cancelar
                        </v-btn>
                        <v-btn
                        color="green"
                        class="white--text"
                        @click="saveInfo()"
                        >
                        Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import _ from 'lodash'
export default {
    mounted(){
        const inputs = JSON.stringify(this.inputs) 
        this.dados.push(JSON.parse(inputs))
    },
    props:{
        inputs: Array,
        title: String,
        value: Array,
        btnText: String
    },
    data: () =>{
        return{
            dados: [],
            dialog: false,
            date: new Date().toISOString().substr(0, 10)
        }
    },
    watch:{
        dialog(val){
            if(val && this.value.length > 0){
                this.dados = []
                const inputs = JSON.stringify(this.inputs) 
                this.value.map((dados, index) => {
                    this.dados.push(JSON.parse(inputs))
                    this.dados[index].map(element => {
                        element.value = _.isString(dados[element.subname]) ? _.unescape(dados[element.subname]) : dados[element.subname]
                        
                    })
                }) 
                //console.log(this.value[0][this.dados[0][0]['subname']]) º->> logica para pegar elemento certo
            }
        }
    },
    methods: {
        saveInfo(setDataEmpty=null){
            if(setDataEmpty){
                this.$emit('getValues', [])
            }else{
                this.setValues()
                var saveData = []

                this.dados.map(element => {
                    var jsonString = "{"
                    element.map((subElement, index) => {
                        subElement.value = subElement.value ? subElement.value : ""
                        subElement.value = _.isString(subElement.value) ? _.unescape(subElement.value) : subElement.value
                        subElement.value = _.isString(subElement.value) ? _.escape(subElement.value) : subElement.value

                        if(subElement.value || subElement.subname){
                            if(_.isArray(subElement.value)){
                                jsonString += `"${subElement.subname}" : ${JSON.stringify(subElement.value)} ${element.length == (index+=1) ? '' : ','}`
                            }else{
                                jsonString += `"${subElement.subname}" : "${subElement.value}" ${element.length == (index+=1) ? '' : ','}`
                            }
                        }
                    })
                    jsonString += '}'
                    
                    saveData.push(JSON.parse(jsonString))
                })
                this.$emit('getValues', saveData)
            }
            this.dialog = false
        },
        newItem(){
            this.setValues()
            const inputs = JSON.stringify(this.inputs) 
            this.dados.push(JSON.parse(inputs))
        },
        removeItem(index){
            this.setValues()
            this.removeDocumentElement(this.dados[index], index)
            this.dados.splice(index, 1)
            
            if(this.dados.length <=0){
                const inputs = JSON.stringify(this.inputs) 
                this.dados.push(JSON.parse(inputs))
                this.saveInfo(true)
            }
        },
        onClickBoxMultiple(name, value, input){
            this.setValues()
            var element = document.getElementById(name)
            if(!element){
                return;
            }
            
            var dados = element.value ? element.value : []
            if(input.value){
                dados = input.value
                input.value = null
            }

            if(dados.length > 0){
                const valueExistIndex = dados.findIndex(element => element == value)
                if(valueExistIndex != -1){
                    dados.splice(valueExistIndex, 1)
                    element.value = dados
                    input.value = dados
                    return;
                }
            }
            dados.push(value)
            element.value = dados
            input.value = dados
        },
        onClickBox(name, value, input){
            var element = document.getElementById(name)
            if(!element){
                return;
            }
            element.value = value
            input
        },
        setValues(){
            this.dados.map((element, elementIndex) => {
                element.map(data => {
                    const input = document.getElementById(`${data.name}_${elementIndex}`)
                    if(input && data.type != "radio"){
                        data.value = input.value
                    }else{
                        data.value = input.value ? input.value : data.value;
                    }
                })
            })
        },
        removeDocumentElement(data, index){
            data.map(data => {
                const input = document.getElementById(`${data.name}_${index}`)
                if(input){
                    input.value = null
                    data.value = null
                }
            })
        }
    }
}
</script>

<style scoped>
.border-input{
    border-bottom:1px solid rgb(118, 118, 118);
    padding: 8px;
    height:32px;
    width: 100%;
}
.border-textarea{
    border-bottom:1px solid rgb(118, 118, 118);
    padding: 8px;
    width: 100%;
}
.border-input:hover, .border-textarea:hover{
    border-color:black;
}
.border-input:focus, .border-textarea:focus{
    border-bottom:2px solid #1976d2;
    outline: none;
}
  
/* input[type="date"]:before {
    content: attr(placeholder) !important;
    color: #aaa;
    margin-right: 0.5em;
}
input[type="date"]:focus:before,
input[type="date"]:valid:before {
    content: "";
} */
</style>