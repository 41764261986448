<template>
  <div class="mx-5">
    <DialogAlert :close="closeDialog" :confirm="closeDialog" :details="dialogAlert" />
    <v-form ref="form">
      <v-row>
        <template v-for="(campo, index) in campos">
          <v-col v-show="campo.show" :key="campo.name" cols="12" sm="12" :md="campo.cols" :lg="campo.cols" :xl="campo.cols" :class="campo.name=='terminado' && isXsSmScreen ? 'order-first' : ''">
            <template
              v-if="
                campo.type == 'text' ||
                campo.type == 'password' ||
                campo.type == 'email' ||
                campo.type == 'number' 
              "
            >
              <v-text-field
                :type="campo.type"
                :value="campo.value"
                :label="campo.label"
                min="0"
                :id="campo.name"
                required
              >
              </v-text-field>
            </template>

            <template v-if="campo.type == 'date'">
              <v-menu  v-model.lazy="campos[index].menuPick" :close-on-content-click="false" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field 
                    type="text"
                    v-model.trim="campos[index].value"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    min="0"
                    :max="date"
                    :label="campo.label"
                    required
                  />
                </template>
                <v-date-picker locale="pt" v-model.trim="campos[index].value" :max="date" @input="campos[index].menuPick = false"></v-date-picker>
              </v-menu>
            </template>

            <template v-if="campo.type == 'description'">
              <v-textarea
                type="text"
                :value="campo.value"
                :id="campo.name"
                :label="campo.label"
                required
              >
              </v-textarea>
            </template>

            <template v-if="campo.type == 'select'">
              <v-select
                outlined
                :items="campo.options"
                :value="campo.value"
                :disabled="campo.disabled ? campo.disabled : false"
                :id="campo.name"
                item-text="text"
                :class="!isXsScreen ? 'mb-n9' : 'mb-n9 mt-1'"
                :multiple="campo.multiple"
                :label="campo.label"
                dense
              >
                <template slot="selection" slot-scope="data">
                  <v-list-item-title style="white-space:normal;" class="pt-2 pb-1 colorDisabled" >{{ data.item.text }}</v-list-item-title>
                </template>
                <template slot="item" slot-scope="data">
                  <span style="text-align: justify;" class="my-2">{{ data.item.text }}</span>
                </template>
              </v-select>
            </template>

            <template v-if="campo.type == 'switch'">
              <v-switch
                :value="campo.value"
                :id="campo.name"
                v-model="campo.value"
                :label="campo.label"
              ></v-switch>
            </template>

            <template v-if="campo.type == 'title'">
              <!-- colorGrey -->
              <span class="float-left">{{ campo.label }}</span>
            </template>

            <template v-if="campo.type == 'radio'">
              <div class="textAlignLeft"><span >{{ campo.title }}</span></div>
              <v-radio-group :multiple="campo.multiple" :value="campo.value" :id="campo.name">
                <div :id="campo.name+'_div'">
                  <div v-for="campo in campo.options" :key="campo.value">
                    <div>
                      <v-radio
                        :label="campo.text"
                        :value="campo.value"
                        @click="campos[index].multiple ? onClickBoxMultiple(campos[index].name, campo.value, campos[index]) : onClickBox(campo.value, campos[index].name, campo.description, campo.placeholder, campo.showElements, campo.hideElements)"
                      ></v-radio>
                    </div>
                  </div>
                </div>
              </v-radio-group>
            </template>

            <template v-if="campo.type == 'radioMultipleInputs'">
              <div class="textAlignLeft"><span >{{ campo.title }}</span></div>
              <v-radio-group :value="campo.value" :id="campo.name">
                <div :id="campo.name+'_div'">
                  <div v-for="campo in campo.options" :key="campo.value">
                    <div>
                      <v-radio
                        :label="campo.text"
                        :value="campo.value"
                        @click="onClickBoxPercent(campo, campos[index].name, campos[index].subElement, campos[index].subPlaceholder, campos[index].showElement, campos[index].typeSubELement)"
                      ></v-radio>
                    </div>
                  </div>
                </div>
              </v-radio-group>
            </template>

            <template v-if="campo.type == 'multipleData'">
              <DialogInput :title="campo.title" :btnText="campo.btnText" :inputs="campo.inputs" :value="campo.value ? campo.value : []" @getValues="(data) => {campo.value = data}" />
            </template>

            <template v-if="campo.type == 'file'">
              <v-row>
                <v-col cols="12">
                  <input
                    class="float-left break-text-input"
                    :id="campo.name"
                    :ref="campo.name"
                    @change="getFiles(campo)"
                    type="file"
                    :multiple="campo.multiple"
                    required
                  />
                  <div v-if="campo.showLegendImage">
                    <LegendarImagem :imgs="[campos[index].value]" @newFiles="(data) => { setLegendFiles(campo.value,data);}" :show="campo.showDialog" />
                  </div>
                  <div v-if="campo.idFile">
                    <CardFiles class="mt-14" :id="campo.idFile" :tipo="campo.name" :mostrarLegenda="campo.showLegendImage" />
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-col>
        </template>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import CardFiles from '../InputForm/CardFiles.vue';
import DialogAlert from '../WarningComponents/DialogAlert.vue';
import LegendarImagem from '../WarningComponents/LegendarImagem';
import DialogInput from '../InputForm/DialogInput.vue'
import _ from 'lodash'

export default {
  mounted() {
    this.campos.map((campo) => {
      if(campo.type == "radio" && campo.value){
        var element = document.getElementById(campo.name)
        element.value = campo.value
      }
      /* mostrar/esconder elementos */
      if(campo.type == "radio"){
        var continuar = true
        campo.options.map(option => {  
          if(option.showElements && campo.value == option.value){
            continuar = false
            option.showElements.map(element => {
              var indexElement = _.findIndex(this.campos, function(val) { return val.name == element })
              this.campos[indexElement].show = true
            })
          }else if(option.hideElements && continuar){
            option.hideElements.map(element => {
              var indexElement = _.findIndex(this.campos, function(val) { return val.name == element })
              this.campos[indexElement].show = false
            })
          }
        })
      }
      
      /* */
      if(campo.especificar && campo.type == "radio"){
        var placeholder = campo.placeholder ? campo.placeholder : "Especificar"
          this.createElement(campo.name, campo.name+"_especificar", "textarea", placeholder, "border-textarea", campo.especificar)
          campo.especificar = null
      }else if(campo.type == 'radioMultipleInputs'){
          if(!_.isArray(campo.subElement) && campo.value == campo.showElement){
            this.createElement(campo.name, campo.subElement, "number", "Apresenta Problemas (%)", "border-textarea", campo.subValue)
          }else if(campo.value == campo.showElement){
            campo.subElement.map((element, index) => {
              var typeElment = "number"
              if(_.isArray(campo.typeSubELement)){
                typeElment = campo.typeSubELement[index] ? campo.typeSubELement[index] : "number"
              }else{
                typeElment = campo.typeSubELement ? campo.typeSubELement : "number"
              }
              this.createElement(campo.name, campo.subElement[index], typeElment, campo.subPlaceholder[index], "border-textarea", campo.subValue[index])  
            })
          }
      }else if(campo.type == 'file'){
        this.filesInputRefId.push(campo.name)
      }
     })
  },
  components: {
    CardFiles,
    LegendarImagem,
    DialogInput,
    DialogAlert
  },
  props: {
    campos: Array,
    docTerminado: {type: Boolean, default: false},
    tipoFormulario: {type: String, default: ''}
  },
  data(){
    return{
      date: new Date().toISOString().substr(0, 10),
      menuPick: false,
      filesInputRefId: [],
      dialogAlert: [{ dialog: false }]
    }
  },
  methods: {
      onClickBoxMultiple(name, value, input){
        var element = document.getElementById(name)
        if(!element){
            return;
        }

        var dados = element.value ? element.value : []
        if(dados.length > 0){
            const valueExistIndex = dados.findIndex(element => element == value)
            if(valueExistIndex != -1){
                dados.splice(valueExistIndex, 1)
                element.value = dados
                input.value = dados
                return;
            }
        }
        dados.push(value)
        element.value = dados
        input.value = dados
    },
    onClickBox(value, id, description=null, placeholder=null, showElements=null, hideElements=null){
      document.getElementById(id).value = value
      var especificar = document.getElementById(id+"_especificar")
      
      if(description && !especificar){
        var placeholderInput = placeholder ? placeholder : "Especificar"
        this.createElement(id, id+"_especificar", "textarea", placeholderInput, "border-textarea", null)
      }else if(especificar && !description){
        especificar.remove()
      }

      if(showElements){
        showElements.map(element => {
          var indexElement = _.findIndex(this.campos, function(val) { return val.name == element })
          this.campos[indexElement].show = true
        })
      }else if (hideElements){
        hideElements.map(element => {
          var indexElement = _.findIndex(this.campos, function(val) { return val.name == element })
          this.campos[indexElement].show = false
        })
      }
    },
    getFiles(campo) {
      var count = this.getLengthFiles()
      
      if(count > 20) {
        this.dialogAlert = [{dialog: true, title: "Máximo 20 imagens em cada edição/inserção", emptyFields: ['']}]
        var files = document.getElementById(campo.name)
        if(files){
          files.value = null;
        }
      }else{
        campo.value = this.$refs[campo.name][0].files;
        campo.showDialog = !campo.showDialog;
      }
    },
    getLengthFiles(){
      var count = 0
      this.filesInputRefId.map(filename => {
        count += this.$refs[filename][0].files.length
      })
      return count
    },
    setLegendFiles(campoValue, fileList){
      for (let index = 0; index < fileList[0].length; index++) {
        campoValue[index]['legenda'] = fileList[0][index]['legenda']
        campoValue[index]['data'] = fileList[0][index]['data']
        campoValue[index]['autoria'] = fileList[0][index]['autoria']
      }
    },
    /* radio percent */
    onClickBoxPercent(campo, id, subElement, subPlaceholder=null, showElement, typeSubELement=null){
        document.getElementById(id).value = campo.value
        
        if(!_.isArray(subElement)){
            var percent = document.getElementById(subElement)
            if(!percent && campo.value == showElement){
                this.createElement(id, subElement, "number", "Apresenta Problemas (%)", "border-textarea", null)
            }else if(percent && campo.value != showElement){
              percent.remove()
            }
        }else{
          var percentSubElements = document.getElementById(subElement[0])
          if(!percentSubElements && campo.value == showElement){
            subElement.map((element, index) => {
              var typeElment = "number"
              if(_.isArray(typeSubELement)){
                typeElment = typeSubELement[index] ? typeSubELement[index] : "number"
              }else{
                typeElment = typeSubELement ? typeSubELement : "number"
              }
              this.createElement(id, subElement[index], typeElment, subPlaceholder[index], "border-textarea", null)  
            })
          }else if(percentSubElements && campo.value != showElement){
            subElement.map((element, index) => {
              percentSubElements = document.getElementById(subElement[index])
              percentSubElements.remove()
            })
          }
        }
    },
    createElement(idNode, idElement, type, placeholder, className, value=null){
      var input = null

      switch (type) {
        case "number":
        case "text":
          input = document.createElement("input")
          input.setAttribute("id", idElement)
          input.setAttribute("type", type)
          input.setAttribute("placeholder", placeholder)
          input.setAttribute("class", className+" mt-5")
          input.setAttribute("min", "0")
          break;
        case "textarea":
          input = document.createElement("textarea")
          input.setAttribute("id", idElement)
          input.setAttribute("placeholder", placeholder)
          input.setAttribute("class", className+" mt-4")
          break;
      }
      var referenceNode = document.querySelector('#'+idNode+"_div");
      
      referenceNode.after(input);

      if(value){
        var inputElement = document.getElementById(idElement)
        inputElement.value = value
      }
    },
    closeDialog(){
      this.dialogAlert = [{ dialog: false }]
    }
  },
  computed: {
    isXsScreen(){
      if(this.$vuetify.breakpoint.xs){
        return true;
      }else{
        return false;
      }
    },
    isXsSmScreen(){
      if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
        return true;
      }else{
        return false;
      }
    }
  }
};
</script>

<style>
.border-textarea{
  border-bottom:1px solid rgb(118, 118, 118);
  height:100px;
}
.border-textarea:hover{
  border-color:black;
}
.border-textarea:focus{
border-bottom:2px solid #1976d2;
}
</style>