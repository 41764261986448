
<template>
  <v-container>
    <Header :titulo="titulo" :itemsBreadCrumb="BreadCrumbs" />
    <div>
      <div v-if="insert">
        <v-card>
          <v-row>
            <v-col cols="12" :class="isSmOrXsScreen ? 'text-center' : 'text-left ml-3'">
              <BtnAddBack :insert="insert" @setInsert="(val) => {insert = val;}" />
            </v-col>
          </v-row>
          <div class="rounded-lg elevation-1" style="background-color: white">
              <div v-if="insert && $store.state.upload.dialog == false">
                <div class="my-5">
                  <Stepper :campos="campos" />
                </div>
                <div class="mt-9">
                  <BtnActions :tipoDocumento="'formulario'" :tipoFormulario="campos[0].value" :editId="EditId" :isNew="false" :campos="campos"  :Actions="[{type: 'terminado', terminado: terminadoBtn, cols:'12'}]" />
                </div>
              </div>
          </div>
        </v-card>
      </div>

      <div v-else>
        <ModalBaseTable :actions="actions" :ationsBtn="btnOption" :edit="edit" :hasSelect="true" :headers="headers" :getData="getData" :tipoDocumento="'formulario'" :terminado="true" :insert="insert" @getInsert="(val) => { insert = val }" :DataSearch="dataSearch" :labelSearch="labelSearch" />
      </div>
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import ModalBaseTable from "../../components/DataTables/modelBaseData.vue";
import Stepper from "../../components/InputForm/Stepper.vue";
import BtnActions from '../../components/InputForm/BtnAction.vue';
import Header from "../../components/Header.vue";
import BtnAddBack from "../../components/BtnAddBack.vue";
/* formulários json */
/* laudo */
import LaudoNhCp from '../../assets/formularios/laudo/modelo_laudo_nh_cp_v1.json'
import LaudoSitiosNaturais from '../../assets/formularios/laudo/modelo_laudo_sitios_naturais_v1.json'
import LaudoBemImovel from '../../assets/formularios/laudo/modelo_laudo_bem_imovel_v1.json'
import LaudoBemMovelIntegrado from '../../assets/formularios/laudo/modelo_laudo_bem_movel_v1.json'
import LaudoDocumentosArquivos from '../../assets/formularios/laudo/modelo_laudo_documentos_v1.json'
import RelatorioSalvaguarda from '../../assets/formularios/laudo/modelo_laudo_relatorio_v1.json'
import DiagnosticoEducacao from '../../assets/formularios/laudo/modelo_laudo_diagnostico.json'
import Revalidacao from '../../assets/formularios/laudo/modelo_laudo_revalidacao.json'

/* icms */
import base from '../../assets/formularios/icms/modelo_base_icms.json'
import modeloAcervos from '../../assets/formularios/icms/modelo_icms_acervos.json'
import modeloBemMoveis from '../../assets/formularios/icms/modelo_icms_bens_moveis.json'
import modeloCelebracao from '../../assets/formularios/icms/modelo_icms_celebracao.json'
import modeloConjuntosUrbanos from '../../assets/formularios/icms/modelo_icms_conjuntos_urbanos.json'
import modeloformasExpressao from '../../assets/formularios/icms/modelo_icms_formas_expressao.json'
import modeloLugares from '../../assets/formularios/icms/modelo_icms_lugares.json'
import modeloOficios from '../../assets/formularios/icms/modelo_icms_oficios.json'
import modeloPatrimonio from '../../assets/formularios/icms/modelo_icms_patrimonio.json'
import modeloSaberes from '../../assets/formularios/icms/modelo_icms_saberes.json'
import modeloSitiosNaturais from '../../assets/formularios/icms/modelo_icms_sitios_naturais.json'

export default {
  mounted() {
    if(parseInt(this.$store.state.User.level) == 1){
      this.btnOption = [
        { cols: "6" },
        { cols: "3", type: "download" },
        { cols: "3", type: "remove" },
      ],
      this.actions = ["edit", "delete", "view", "download"]
    }

    if (this.$route.params.id) {
      this.edit({ id: this.$route.params.id });
    }
  },
  components: {
    ModalBaseTable,
    Stepper,
    BtnActions,
    Header,
    BtnAddBack,
  },
  data() {
    return {
      btnOption: [
        {cols: "9"},
        {cols: "3", type: "download"},
      ],
      tipoDocumento: "formulario",
      actions: ["view", "download"],
      getData: [
        {
          filtro: "QUADROS",
          terminado: true
        }
      ],
      headers: [
        {
          text: "Quadro",
          align: "start",
          sortable: true,
          value: "quadro",
          width:"10%"
        },
        { text: "Endereço", align: "start", value: "endereco", width:"25%"},
        { text: "Município", align: "start", sortable:false, value: "municipio", width:"25%" },
        { text: "Responsável", align: "start", value: "Userresponsavel", width:"10%" },
        { text: "Data da Última Alteração", align: "start", value: "update", width:"15%" },
        { text: "Ações", sortable: false, align: "start", value: "actions", width:"15%" },
      ],  
      dataSearch: [{ tipo: "QUADROS", tipoForm: "*", terminado: true, filtro: "formulario"}],
      labelSearch: "Procure por Endereço ou Responsável",
      tipoFormulario: '',
      titulo: "Arquivos Finalizados ICMS - Cultural",
      loading: true,
      BreadCrumbs: [
        {
          text: "Página Inicial",
          disabled: false,
          href: "/",
        },
        {
          text: "Arquivos Finalizados ICMS - Cultural",
          disabled: true,
          href: "/arquivosFinalizadosICMS",
        },
      ],
      campos: [],
      insert: false,
    };
  },
  watch: {
    "$route.params.id"(val) {
      if (val) {
        this.edit({ id: val });
      }
    },
  },
  computed: {
    terminadoBtn(){
      const terminado = this.campos[0]["campos"].find(element => element.name == 'terminado');
      if(terminado.value != null){
        return terminado.value
      }else{
        return false
      }
    },
    EditId() {
      return !_.isEmpty(this.$store.state.EditForm) ? this.$store.state.EditForm[0].id : null;
    },
    isSmOrXsScreen(){
      if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
        return true;
      }else{
        return false;
      }
    },
  },
  methods: {
    edit(item) {
      if (!this.$route.params.id) {
        this.$router.push({ path: `/arquivosFinalizadosICMS/editar/${item.id}` });
      }

      var dados = {
        id: item.id,
        filtro: "id",
        terminado: true,
        editar: true
      };

      this.$store.dispatch("getForm", dados).then(() => {
        item = this.$store.state.EditForm[0];
        var type = item.tipo
        this.tipoFormulario = type
        this.campos = this.getCampos(type)
        
        this.campos[0].value = type;
        this.campos.map((subTitulos) => {
          subTitulos.campos.map(campo => {
            if(campo.name == 'tipo'){
              campo.show = true
              campo.disabled = true
              campo.value = item.tipo
            }else if (campo.type == "file") {
              campo.idFile = this.EditId;
              campo.value = ""
            }else{
              campo.value = item[campo.name];
              if(campo.name == "terminado"){
                campo.value = true
              }else if(campo.type == 'number' && _.isEmpty(campo.value)){
                campo.value = 0
              }
            }
            if(campo.type=="radio" && item[`${campo.name}_especificar`]){
              campo.especificar = item[campo.name+"_especificar"]
            }
            if(campo.type=="radioMultipleInputs"){
              if(!_.isArray(campo.subElement) && campo.value == campo.showElement){
                campo.subValue = item[campo.subElement]
              }else if(campo.value == campo.showElement){
                var values = []
                campo.subElement.map(element => {
                  values.push(item[element])
                })
                campo.subValue = values
              }

              }
          });
        })
        this.insert = !this.insert;
      });
    },
    getCampos(val){
      if(val){
        var _campos = []
        switch(val){
          case "LaudoNhCp":
            _campos = LaudoNhCp
            return _campos
          case "LaudoSitiosNaturais":
            _campos = LaudoSitiosNaturais
            return _campos
          case "LaudoBemImovel":
            _campos = LaudoBemImovel
            return _campos
          case "LaudoBemMovelIntegrado":
            _campos = LaudoBemMovelIntegrado
            return _campos
          case "LaudoDocumentosArquivos":
            _campos = LaudoDocumentosArquivos
            return _campos
            case "EstruturasArquitetonicasUrbanisticas":
          case "ManifestacoesCulturais":
              _campos = base
              return _campos
          case "BensMoveisIntegrados":
              _campos = modeloBemMoveis
              return _campos
          case "Acervos":
              _campos = modeloAcervos
              return _campos
          case "PatrimonioArqueologico":
              _campos = modeloPatrimonio
              return _campos
          case "SitiosNaturais":
              _campos = modeloSitiosNaturais
              return _campos
          case "ConjuntosUrbanos":
              _campos = modeloConjuntosUrbanos
              return _campos
          case "Celebracao":
              _campos = modeloCelebracao
              return _campos
          case "FormasExpressao":
              _campos = modeloformasExpressao
              return _campos
          case "Lugares":
              _campos = modeloLugares
              return _campos
          case "Saberes":
              _campos = modeloSaberes
              return _campos
          case "Oficios":
              _campos = modeloOficios
              return _campos
          case "RelatorioSalvaguarda":
            _campos = RelatorioSalvaguarda
            return _campos
          case "DiagnosticoEducacao":
            _campos = DiagnosticoEducacao
            return _campos
          case "Revalidacao":
            _campos = Revalidacao
            return _campos
        }
        return []
      }
    },
    resetJson(json){
      if(json){
        json.map(div => {
          div.campos.map(element => {
            if(element.type == 'switch'){
              element.value = true
            }else{
              element.value = ""
            }
          })
        })
      }
    },
  },
};
</script>
