<template>
  <div class="upload">
    <v-dialog v-model="dialog" persistent width="300" v-if="colorLine">
      <v-card color="bgp" dark>
        <v-card-text v-if="value != null">
          {{text}}
          <v-progress-linear :value="value" :color="colorLine" height="15">
            <template>
              <strong style="color: white">{{ value }}%</strong>
            </template>
          </v-progress-linear>
        </v-card-text>

        <v-card-text v-else>
          {{text}}
          <v-progress-linear indeterminate :value="value" :color="colorLine" >
          </v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="300" persistent v-else>
      <v-card color="bgp" dark>
        <v-card-text>
          {{text}}
        </v-card-text>
        <v-card-actions class="justify-center mt-n6">
         <v-progress-circular
          :size="30"
          color="white"
          indeterminate
        ></v-progress-circular>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    colorLine: String,
    value: Number,
    text: String,
    dialog: Boolean,
  },
};
</script>