<template>
    <v-container>
        <Header :titulo="titulo" :itemsBreadCrumb="BreadCrumbs" />
        <div>
            <div v-if="insert">
                <v-card>
                    <v-col cols="12" :class="isSmOrXsScreen ? 'text-center' : 'text-left ml-3'">
                        <BtnAddBack :sub="sub" :insert="insert" @setInsert="(val) => {insert = val;}"  />
                    </v-col>
                    <div class="mt-4" style="background-color: white">
                        <Inputs :campos="campos" />

                        <div class="mt-9">
                            <BtnActions :tipoDocumento="'docGestaoSumario'" :editId="EditId" :isNew="isNew" :campos="campos"  :Actions="[{type: 'terminado', terminado: false, cols:'12'}]" />
                        </div>
                    </div>
                </v-card>
            </div>

            <div v-else>
                <ModalBaseTable :actions="actions" :ationsBtn="btnOption" :DataSearch="dataSearch" :edit="edit" :hasSelect="true" :headers="headers" :labelSearch="labelSearch" :getData="getData" :tipoDocumento="'docGestaoSumario'" :terminado="false" :sub="sub" :insert="insert" @getInsert="(val) => { insert = val }" />
            </div>
        </div>
    </v-container>
</template>

<script>
import _ from "lodash";
import Header from "../../components/Header.vue";
import ModalBaseTable from '../../components/DataTables/modelBaseData.vue'
import BtnAddBack from "../../components/BtnAddBack.vue";
import Inputs from "../../components/InputForm/Input.vue";
import BtnActions from "../../components/InputForm/BtnAction.vue";
/* Json formulario */
import sumarioJson from '../../../src/assets/formularios/gestaodocumentos/sumarios.json'

export default {
    mounted(){
        /* verificar se é um admin */
        if(this.$store.state.User.level == '1'){
            this.hasSelect = true
            this.headers.push({ text: "Ações", sortable: false, align: "center", value: "actions", width: "15%" })
        }

        if (this.$route.params.id) {
            this.edit({ id: this.$route.params.id });
        }
    },
    data(){
        return{
            titulo: "Gestão Sumários",
            BreadCrumbs: [
                {
                    text: "Página Inicial",
                    disabled: false,
                    href: "/",
                },
                {
                    text: "Gestão - Sumários",
                    disabled: true,
                    href: "/sumarios",
                },
            ],
            getData: [{tipo: "", route: "sumarioGestao",}],
            labelSearch: "Procure pelo nome do documento",
            sub: "Novo Sumário",
            insert: false,
            dataSearch: [{ tipo: "",  filtro: "gestaoSumario"}],
            campos: sumarioJson,
            isNew: true,
            headers: [
                { text: "Nome do Sumário", align: "start", sortable: true, value:"nome", width: "55%"},
                { text: "Responsável", align: "center", sortable: true, value: "responsavel",  width: "30%"},
            ],
            actions: ["edit", "delete", "view", "download"],
            hasSelect: false,
            btnOption: [
                {cols: "6"},  
                {cols: "3", type: "download"},
                {cols: "3", type: "remove"}
            ],
        }
    },
    components:{
        Header,
        ModalBaseTable,
        BtnAddBack,
        Inputs,
        BtnActions,
    },
    watch:{
        insert(){
            if (!this.$route.params.id) {
                this.resetJson(this.campos)
            }
        }
    },
    computed: {
    EditId() {
      return !_.isEmpty(this.$store.state.EditForm) ? this.$store.state.EditForm[0].id : null;
    },
    isSmOrXsScreen(){
        if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
            return true;
        }else{
            return false;
        }
    },
  },
    methods:{
        edit(item) {
            if (!this.$route.params.id) {
                this.$router.push({ path: `/sumarios/editar/${item.id}` });
             }

            var dados = {
                id: item.id,
                editar: true,
                route: "sumarioGestao"
             };

            this.$store.dispatch("getGestao", dados).then(() => {
                item = this.$store.state.EditForm[0];

                /* passagem de valores */
                this.campos.map(campo => {
                    if (campo.type == "file") {
                        campo.idFile = this.EditId;
                        campo.value = ""
                        campo.show = true
                    }else{
                        campo.value = item[campo.name] ? item[campo.name] : item.campos[campo.name];
                        if(campo.type == 'number' && _.isEmpty(campo.value)){
                            campo.value = 0
                        }
                    }
                });

                this.isNew = false;
                this.insert = !this.insert;
            });
        },
        resetJson(json){
            if(json){
                json.map(element => {
                    element.value = ""
                })
                this.campos = json
            }
        },
    },
}
</script>