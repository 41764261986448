import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store'
import router from './router'
import axios from 'axios'
/* Sentry */
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://b81a18716566438f8fd5e67d1119bb38@sentry.weblevel.pt/27",
  environment:"dev",
  integrations: [
    new VueIntegration({
      Vue,
      tracing: true,
    }),
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

Vue.config.productionTip = false

if(store.getters.User.token){
  var token = store.getters.User.token
  const accessToken = 'Bearer ' + token || ''  
  axios.defaults.headers.common['Authorization'] = accessToken;
}

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
