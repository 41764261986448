import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import Axios from 'axios'
import _ from 'lodash'
import jwt_decode from 'jwt-decode'
import moment from 'moment'
import { unescapeObject, conectObject } from "../functions.js"

Vue.use(Vuex)

var url
if (Vue.config.devtools) {
  url = "https://gespa.dev.weblevel.pt"
} else {
  url = ""
}

export default new Vuex.Store({
  state: {
    /* LOADER */
    upload: {
      color: "",
      value: 0,
      dialog: false,
      text: '',
    },
    /* LOGIN */
    User: {
      name: '',
      token: '',
      email: '',
      cpf: '',
      id: '',
      level: '',
    },
    /* FORMULARIO */
    DadosFormulario: [],
    EditForm: {},
    /* Notifications */
    numMessages: 0,
    messagesNotifications: [],
  },
  mutations: {
    /* LOGIN */
    setUser(state, data) {
      state.User = {
        name: data['nome'],
        token: data['token'],
        email: data['email'],
        cpf: data['cpf'],
        level: data['level'],
        id: data['id']
      }
    },
    logout(state) {
      state.User = {
        name: '',
        token: '',
        email: '',
        cpf: '',
        id: '',
        level: '',
      }
      window.location.href = "/";
    },
    /* TOKEN */
    verificarToken(state) {
      if (state.User.token != '') {
        var ExpToken = jwt_decode(state.User.token).exp;
        const expiryDate = new Date(ExpToken * 1000)

        if (expiryDate < Date.now()) {
          this.dispatch("logout")
        }
      }
    },
    /* FORMULARIO */
    setEditForm(state, dados) {
      state.EditForm = dados
    },
    setForm(state, dados) {
      state.DadosFormulario = dados
    },
    resetData(state) {
      state.upload = { color: "", value: 0, dialog: false, text: '' }
      state.DadosFormulario = []
      state.EditForm = {}
    },
    /* messages notification */
    setMessages(state, data) {
      state.numMessages = data.numMessages
      state.messagesNotifications = data.messages

    }
  },
  actions: {
    loadingBasic({state}, text){
      return state.upload = { text: text, color: null, value: null, dialog: true }
    },
    /* TOKEN */
    tokenExpired({ commit, state }) {
      Axios.interceptors.response.use(req => {
        return req;
      }, error => {
        if (error.response.data.logout) {
          commit("logout")
        }
        if (error.response.status !== 401) {
          return new Promise((resolve, reject) => {
            reject(error);
          });
        }
        var ExpToken = jwt_decode(state.User.token).exp;
        const expiryDate = new Date(ExpToken * 1000)

        if (expiryDate < Date.now()) {
          commit("logout")
        }
      });
      commit("verificarToken")
    },
    /* LOGIN */
    updateUser({ commit, state }, data) {
      var dados = [];
      dados['nome'] = data.nome
      dados['cpf'] = data.cpf
      dados['level'] = data.level
      dados['email'] = data.email
      dados['id'] = state.User.id
      dados['token'] = state.User.token
      commit("setUser", dados)
    },
    setUser({ commit }, data) {
      commit("setUser", data)
    },
    logout({ commit }) {
      Axios.get(`${url}/api/auth/user-logout`).catch(()=>{
        console.log("Erro!")
      })
      commit("logout")
    },

    /* FORMULARIO */
    searchForm({ commit, state }, data) {
      var userNames = []
      return Axios.get(`/api/users/getNames`).then(response => {
        userNames = response.data.dados
        /* passagem de id user obrigatorio, para verificar que não foi removido */
        if (state.User.id) {
          data.u_id = state.User.id
        }
        return Axios.get(`${url}/api/formulario/search/${data.page}/${data.rows}`, { params: data.search }).then(response => {
          var item = response.data.campos
          var obj = [];
          /* unescape de valores */
          item.forEach(element => {
            var f_camp = element.f_campos ? element.f_campos : {};
            f_camp = unescapeObject(f_camp)
            f_camp = conectObject(f_camp, element, "f_")
            
            /* get user name */
            userNames.map(user => {
              if (user.cpf == element.f_responsavel) {
                f_camp.UserName = user.nome ? user.nome : ""
              }
            })

            f_camp.Userresponsavel = element.f_responsavel
            
            obj.push(f_camp)
          });
          
          commit('setForm', obj)
          return { type: 'success', text: response.data.message, visible: true, totalRows: parseInt(response.data.total) }
        }).catch(error => {
          return { type: 'danger', text: error.response.data.message, visible: true }
        }).finally(() => { return true })
      })
    },
    getForm({ commit, state }, data) {
      /* passagem de id user obrigatorio, para verificar que não foi removido */
      if (state.User.id) {
        data.u_id = state.User.id
      }
      var userNames = []
      return Axios.get(`${url}/api/users/getNames`).then(response => {
        userNames = response.data.dados
        return Axios.get(`${url}/api/formulario/${data.tipo}/get/${data.page}/${data.rows+data.orderBy}`, { params: data }).then(response => {
          var item = response.data.campos
          var obj = [];
          /* unescape de valores */

          item.forEach(element => {
            var f_camp = element.f_campos ? element.f_campos : {};
            f_camp = unescapeObject(f_camp)
            f_camp = conectObject(f_camp, element, "f_")
            
            /* get user name */
            userNames.map(user => {
              if (user.cpf == element.f_responsavel) {
                f_camp.UserName = user.nome ? user.nome : ""
              }
            })

            f_camp.Userresponsavel = element.f_responsavel
            
            obj.push(f_camp)
          });

          if (data.editar == true) {
            commit("setEditForm", obj)
            return { type: 'success', text: response.data.message, visible: true }
          } else {
            commit('setForm', obj)
            return { type: 'success', text: response.data.message, visible: true, totalRows: parseInt(response.data.total) }
          }
        }).catch(error => {
          return { type: 'danger', text: error, visible: true }
        })
      })

    },
    removeForms({ state }, data) {
      data.responsavel = state.User.cpf
      return Axios.get(`${url}/api/auth/formulario/delete?id=${state.User.id}&nome=${state.User.name}`, { params: data }).then(response => {
        return { type: 'success', text: response.data.message, visible: true }
      }).catch(error => {
        return { type: 'danger', text: error.response.data.message, visible: true }
      })
    },
    /* FICHEIROS */
    downloadForm({ state }, data) {
      var _url = ""
      switch (data.tipo) {
        case "getGestao":
          _url = `${url}/api/tcpdf/${data.tipoDocumento}/${data.id}/${data.tipo}`
          data.name = _.isEmpty(data.name) ? data.dados.nomeDocumento : data.name; 
          break;
      
        default:
          data.name = data.tipo;
          data.name = _.isEmpty(data.name) ? data.tipo : data.name; 
          
          _url = `${url}/api/tcpdf/${data.dados.id}/${data.tipo}`
          break;
      }
      
      state.upload.text = "A descarregar o pdf"
      state.upload.color = "grey"
      state.upload.value = null
      state.upload.dialog = true

      Axios({
        method: "POST",
        url: _url,
        responseType: "arraybuffer",
        data: data.dados,
      }).then(function (response) {
        state.upload.dialog = false
        const filename = `${data.name}_${moment().format('DD/MM/YYYY')}.pdf`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      }).catch(() => { state.upload.dialog = false });
    },
    multipleDownloads({ state }, data) {
      state.upload.text = "A descarregar pdf(s)"
      state.upload.color = "grey"
      state.upload.value = null
      state.upload.dialog = true

      var lengthData = data.dados.length
      var dados = []

      data.dados.forEach((element, index) => {
        if(element.tipo == 'NovoBemDigitalizado'){
          data.tipo = "formularioNovoBemDigitalizado"
        }else{
          data.tipo = "getFormulario"
        }
        
        element.name = _.isEmpty(element.designacao) ? data.tipo : element.designacao;
        dados.push({ id: element.id, tipo: element.tipo, nome: index + "_" + element.name })

        Axios({
          method: "POST",
          url: `${url}/api/tcpdf/${element.id}/${data.tipo}`,
          responseType: "arraybuffer",
          data: element,
        }).then(function (response) {
          lengthData--;
          response
          lengthData
        }).catch(() => {
          state.upload.dialog = false
        }).finally(() => {
          /* depois de converter todos em pdf e terem sido enviados para o servidor, ai sim é gerado o ZIP de ficheiros */
          if (lengthData == 0) {
            Axios({
              method: "POST",
              url: `${url}/api/tcpdf/formulario/generateZIP`,
              responseType: "arraybuffer",
              data: dados,
            }).then(response => {
              const filename = "Documentos_" + moment().format('DD/MM/YYYY') + ".zip";
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");

              link.href = url;
              link.setAttribute("download", filename);
              document.body.appendChild(link);
              link.click();
              link.remove();
              window.URL.revokeObjectURL(url);
              state.upload.dialog = false
              state.upload.dialog = false
            })
          }
        });
      });
    },
    multipleDownloadsGestao({ state }, data) {
      var tipoDocumento = ""

      switch (data.type) {
        case "docGestaoEtiqueta":
          tipoDocumento = "gestaoEtiqueta"
          break;
        case "docGestaoSumario":
          tipoDocumento = "gestaoSumario"
          break;
      }

      state.upload.text = "A descarregar pdf(s)"
      state.upload.color = "grey"
      state.upload.value = null
      state.upload.dialog = true

      var lengthData = data.dados.length
      var dados = []

      data.dados.forEach((element, index) => {
        element.name = _.isEmpty(element.nome) ? element.tipo : element.nome; 
        
        dados.push({ id: element.id, tipo: tipoDocumento, nome: index + "_" + element.name })
        
        Axios({
          method: "POST",
          url: `${url}/api/tcpdf/${data.type}/${element.id}/getGestao`,
          responseType: "arraybuffer",
          data: element,
        }).then(function (response) {
          lengthData--;
          response
          lengthData
        }).catch(() => {
          state.upload.dialog = false
        }).finally(() => {
          /* depois de converter todos em pdf e terem sido enviados para o servidor, ai sim é gerado o ZIP de ficheiros */
          if (lengthData == 0) {
            Axios({
              method: "POST",
              url: `${url}/api/tcpdf/${tipoDocumento}/generateZIP`,
              responseType: "arraybuffer",
              data: dados,
            }).then(response => {
              const filename = "Documentos_" + moment().format('DD/MM/YYYY') + ".zip";
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");

              link.href = url;
              link.setAttribute("download", filename);
              document.body.appendChild(link);
              link.click();
              link.remove();
              window.URL.revokeObjectURL(url);
              state.upload.dialog = false
              state.upload.dialog = false
            })
          }
        });
      });
    },
    seeForm({ state }, data) {
      state.upload.text = "Aguarde para visualizar o pdf"
      state.upload.color = "grey"
      state.upload.value = null
      state.upload.dialog = true
      
      Axios({
        method: "POST",
        url: `${url}/api/tcpdf/${data.id}/${data.tipo}`,
        responseType: "arraybuffer",
        data: data.dados,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: 'application/pdf' }),

          url = window.URL.createObjectURL(blob)

        window.open(url)
        state.upload.dialog = false
      });
    },
    seeGestao({ state }, data) {
      state.upload.text = "Aguarde para visualizar o pdf"
      state.upload.color = "grey"
      state.upload.value = null
      state.upload.dialog = true
      
      Axios({
        method: "POST",
        url: `${url}/api/tcpdf/${data.tipoDocumento}/${data.id}/${data.tipo}`,
        responseType: "arraybuffer",
      }).then(function (response) {
        let blob = new Blob([response.data], { type: 'application/pdf' }),

          url = window.URL.createObjectURL(blob)

        window.open(url)
        state.upload.dialog = false
      });
    },
    getDocuments(context, data) {
      return Axios.get(`${url}/api/auth/documentos/get`, { params: data }).then(response => {
        return response.data
      }).catch(() => {
        return []
      })
    },
    /* remover esta função, assim que não for necessária */
    removeDocument(context, data) {
      return Axios.delete(`${url}/api/auth/documentos/delete`, { params: data, }).then(response => {
        return { type: 'success', text: response.data.message, visible: true }
      }).catch(error => {
        return { type: 'danger', text: error.response.data.message, visible: true }
      })
    },
    /* RESET.. */
    resetData({ commit }) {
      commit("resetData")
    },
    resetEditForm({ commit }) {
      commit("setEditForm", [])
    },
    /* Documentos de Gestão */
    getDocsGestao({ commit }, data) {
      return Axios.get(`${url}/api/auth/documentosGestao/${data.tipo}/get/${data.page}/${data.rows+data.orderBy}`, { params: data }).then(response => {
        var item = response.data.campos
        
        item.map(element => {
          if(_.isString(element.nome)){
            element.nome = _.unescape(element.nome)
          }
        })
        
        if (data.filtro == "encaminhado") {
          return response.data.total
        }
        
        if (data.editar == true) {
          commit("setEditForm", item)
          return { type: 'success', text: response.data.message, visible: true }
        } else {
          commit('setForm', item)
          return { type: 'success', text: response.data.message, visible: true, totalRows: parseInt(response.data.total) }
        }
      }).catch(error => {
        return { type: 'danger', text: error, visible: true }
      })
    },
    searchDocsGestao({ commit }, data) {
      return Axios.get(`${url}/api/auth/documentosGestao/search/${data.page}/${data.rows}`, { params: data.search }).then(response => {
        var item = response.data.campos
        
        item.map(element => {
          if(_.isString(element.nome)){
            element.nome = _.unescape(element.nome)
          }
        })

        if (data.editar == true) {
          commit("setEditForm", item)
          return { type: 'success', text: response.data.message, visible: true }
        } else {
          commit('setForm', item)
          return { type: 'success', text: response.data.message, visible: true, totalRows: parseInt(response.data.total) }
        }
      }).catch(error => {
        return { type: 'danger', text: error, visible: true }
      })
    },
    searchGestao({ commit }, data) {
      return Axios.get(`${url}/api/auth/${data.route}/search/${data.page}/${data.rows}`, { params: data.search }).then(response => {
        var item = response.data.campos
        
        item.map(element => {
          if(_.isString(element.nome)){
            element.nome = _.unescape(element.nome)
          }
        })

        if (data.editar == true) {
          commit("setEditForm", item)
          return { type: 'success', text: response.data.message, visible: true }
        } else {
          commit('setForm', item)
          return { type: 'success', text: response.data.message, visible: true, totalRows: parseInt(response.data.total) }
        }
      }).catch(error => {
        return { type: 'danger', text: error, visible: true }
      })
    },
    getGestao({ commit }, data) {
      return Axios.get(`${url}/api/auth/${data.route}/get/${data.page}/${data.rows+data.orderBy}`, { params: data }).then(response => {
        var item = response.data.campos
        
        item.map(element => {
          element.campos = JSON.parse(element.campos)
          element = unescapeObject(element)
        })
        
        if (data.editar == true) {
          commit("setEditForm", item)
          return { type: 'success', text: response.data.message, visible: true }
        } else {
          commit('setForm', item)
          return { type: 'success', text: response.data.message, visible: true, totalRows: parseInt(response.data.total) }
        }
      }).catch(error => {
        return { type: 'danger', text: error, visible: true }
      })
    },
    removeDocsGestao({ state, dispatch }, data) {
      data.responsavel = state.User.cpf
      return Axios.get(`${url}/api/auth/documentosGestao/delete`, { params: data }).then(response => {
        dispatch("getNotifications")
        return { type: 'success', text: response.data.message, visible: true }
      }).catch(error => {
        return { type: 'danger', text: error.response.data.message, visible: true }
      })
    },
    removeGestao({ state, dispatch }, data) {
      data.responsavel = state.User.cpf
      return Axios.get(`${url}/api/auth/${data.nameRoute}/delete`, { params: data }).then(response => {
        dispatch("getNotifications")
        return { type: 'success', text: response.data.message, visible: true }
      }).catch(error => {
        return { type: 'danger', text: error.response.data.message, visible: true }
      })
    },
    /* getNotifications */
    getNotifications({ commit, dispatch }) {
      var count = 0;
      var dataNotificationTemp = []
      var messagesTemp = 0
      var dataNotification = [
        { route: "/quadro/I", nome: "Quadro I - Gestão", num: 0, tipo: "QUADRO I" },
        { route: "/quadro/II B", nome: "Processos de Tombamento de Bens Materiais, na esfera Municipal", num: 0, tipo: "QUADRO II - B" },
        { route: "/quadro/II C", nome: "Processos de Registro de Bens Imateriais, na esfera Municipal", num: 0, tipo: "QUADRO II - C" }
      ]
      dataNotification.map(camp => {
        dispatch("getDocsGestao", { tipo: camp.tipo, encaminhado: "false", filtro: "encaminhado" }).then(resp => {
          if(resp){
            camp.num = resp
            count++
            if (camp.num > 0) {
              dataNotificationTemp.push(camp)
              messagesTemp += 1;
            }
          }
        }).finally(() => {
          if (count == 3) {
            commit("setMessages", { numMessages: messagesTemp, messages: dataNotificationTemp });
          }
        })
      })
    },
  },
  modules: {
  },
  getters: {
    User(state) {
      return state.User;
    },
  },
  plugins: [createPersistedState()]
})
