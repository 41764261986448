<template>
  <div class="mx-5">
    <DialogAlert :close="closeDialog" :confirm="closeDialog" :details="dialogAlert" />
    <v-form ref="form">
      <v-row>
        <template v-for="(campo, index) in campos">
          <v-col v-show="campo.show" :key="campo.name" cols="12" sm="12" :md="campo.cols" :lg="campo.cols" :xl="campo.cols" :class="campo.name=='terminado' && isXsSmScreen ? 'order-first' : ''">
            <template
              v-if="
                campo.type == 'text' ||
                campo.type == 'password' ||
                campo.type == 'email' ||
                campo.type == 'number' 
              "
            >
              <v-text-field
                :type="campo.type"
                v-model.trim="campos[index].value"
                :label="campo.label"
                :disabled="campo.disabled ? campo.disabled : false"
                min="0"
                :max="campo.max ? campo.max : ''"
                required
              >
              </v-text-field>
            </template>

            <template v-if="campo.type == 'date'">
              <!-- <v-text-field
                :type="campo.type"
                v-model.trim="campos[index].value"
                :label="campo.label"
                min="0"
                :max="date"
                required
              >
              </v-text-field> -->
              <v-menu v-model="campos[index].menuPick" :close-on-content-click="false" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field 
                    type="text"
                    v-model.trim="campos[index].value"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    min="0"
                    :max="date"
                    :label="campo.label"
                    required
                  />
                </template>
                <v-date-picker  locale="pt" v-model.trim="campos[index].value" :max="date" @input="campos[index].menuPick = false"></v-date-picker>
              </v-menu>
            </template>

            <template v-if="campo.type == 'description'">
              <v-textarea
                type="text"
                v-model.trim="campos[index].value"
                :label="campo.label"
                required
                counter
              >
              </v-textarea>
            </template>

            <template v-if="campo.type == 'multipleData'">
                <DialogInput :title="campo.title" :btnText="campo.btnText" :inputs="campo.inputs" :value="campo.value ? campo.value : []" @getValues="(data) => {campo.value = data}" />
            </template>

            <template v-if="campo.type == 'select'">
              <v-select
                v-if="!isXsScreen"
                outlined
                :items="campo.options"
                v-model="campos[index].value"
                item-text="text"
                class="mb-n9"
                :multiple="campo.multiple"
                :label="campo.label"
                dense
              >
              </v-select>
              <v-select
                v-else
                outlined
                class="mb-n9 mt-1"
                :items="campo.options"
                v-model="campos[index].value"
                item-text="text"
                :multiple="campo.multiple"
                :label="campo.label"
                dense
              >
                <template slot="selection" slot-scope="data">
                  <v-list-item-title style="white-space:normal; color: black;" class="pt-2 pb-1" >{{ data.item.text }}</v-list-item-title>
                </template>
                <template slot="item" slot-scope="data">
                  <span style="text-align: justify;" class="my-2">{{ data.item.text }}</span>
                </template>
              </v-select>
            </template>

            <template v-if="campo.type == 'switch'">
              <v-switch
                v-model="campos[index].value"
                :label="campo.label"
              ></v-switch>
            </template>

            <template v-if="campo.type == 'title'">
              <!-- colorGrey -->
              <span class="float-left"><b>{{ campo.label }}</b></span>
            </template>

            <template v-if="campo.type == 'radio'">
              <div class="textAlignLeft"><span >{{ campo.title }}</span></div>
              <v-radio-group v-model="campos[index].value">
                <div v-for="campo in campo.options" :key="campo.value">
                  <div v-if="campo.text != 'Outro' && campo.text.toUpperCase() != 'OUTROS'">
                    <v-radio
                      :label="campo.text"
                      :value="campo.value"
                    ></v-radio>
                  </div>
                  <div :class="campo.type == 'description' ? '' : 'radio-outro-especificar'" v-else>
                    <v-radio
                      :label="campo.text"
                      :value="campo.value"
                      class="mr-3"
                      hide-details
                    ></v-radio>
                    <v-text-field
                      v-if="campo.type != 'description'"
                      maxlength="45"
                      counter
                      :value="campo.especificar"
                      v-model.trim="campo.especificar"
                      placeholder="Especificar"
                    ></v-text-field>
                    <v-textarea
                      v-else
                      type="text"
                      :disabled="campos[index].value != 'Outros'"
                      v-model.trim="campo.especificar"
                      label="Descrição"
                      required
                      counter
                    ></v-textarea>
                  </div>
                </div>
              </v-radio-group>
            </template>

            <template v-if="campo.type == 'file'">
              <v-row>
                <v-col cols="12">
                  <input
                    class="float-left break-text-input"
                    :ref="campo.name"
                    @change="getFiles(campo)"
                    type="file"
                    :multiple="campo.multiple"
                    required
                  />
                  <div v-if="campo.showLegendImage">
                    <LegendarImagem :imgs="[campos[index].value]" @newFiles="(data) => { setLegendFiles(campo.value,data);}" :show="campo.showDialog" />
                  </div>
                </v-col>
                <v-col cols="12" v-if="campo.idFile">
                  <CardFiles :id="campo.idFile" :tipo="campo.name" />
                </v-col>
              </v-row>
            </template>
          </v-col>
        </template>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import CardFiles from '../InputForm/CardFiles.vue';
import DialogAlert from '../WarningComponents/DialogAlert.vue';
import LegendarImagem from '../WarningComponents/LegendarImagem';
import DialogInput from '../InputForm/DialogInput.vue';

export default {
  mounted() {
    this.campos.map(campo => {
      if(campo.type == 'file'){
        this.filesInputRefId.push(campo.name)
      }
    })
  },
  components: {
    CardFiles,
    LegendarImagem,
    DialogAlert,
    DialogInput
  },
  props: {
    campos: Array,
    docTerminado: {type: Boolean, default: false},
    tipoFormulario: {type: String, default: ''}
  },
  data(){
    return{
      date: new Date().toISOString().substr(0, 10),
      menuPick: false,
      filesInputRefId: [],
      dialogAlert: [{ dialog: false }]
    }
  },
  methods: {
    getFiles(campo) {
      var count = this.getLengthFiles()
      
      if(count > 20) {
        this.dialogAlert = [{dialog: true, title: "Máximo 20 imagens em cada edição/inserção", emptyFields: ['']}]
        var files = document.getElementById(campo.name)
        if(files){
          files.value = null;
        }
      }else{
        campo.value = this.$refs[campo.name][0].files;
        campo.showDialog = !campo.showDialog;
      }
    },
    getLengthFiles(){
      var count = 0
      this.filesInputRefId.map(filename => {
        count += this.$refs[filename][0].files.length
      })
      return count
    },
    setLegendFiles(campoValue, fileList){
      for (let index = 0; index < fileList[0].length; index++) {
        campoValue[index]['legenda'] = fileList[0][index]['legenda']
        campoValue[index]['data'] = fileList[0][index]['data']
        campoValue[index]['autoria'] = fileList[0][index]['autoria']
      }
    },
    closeDialog(){
      this.dialogAlert = [{ dialog: false }]
    }
  },
  computed: {
    isXsScreen(){
      if(this.$vuetify.breakpoint.xs){
        return true;
      }else{
        return false;
      }
    },
    isXsSmScreen(){
      if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
        return true;
      }else{
        return false;
      }
    }
  }
};
</script>