<template>
  <div id="body">
    <SnackBar  @dismiss="(visible) => {response.visible = visible;}"
      :snackbar="response.visible"
      :text="response.text"
      :type="response.type"
    />
  
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-toolbar class="bgp">
              <v-toolbar-title class="white--text">{{login ? 'Login' : "Recuperar Senha"}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form onSubmit="return false;" v-if="login">
                <v-text-field
                  prepend-icon="mdi-account"
                  name="login"
                  label="CPF"
                  v-model="cpf"
                  type="text"
                  v-on:keyup.enter="Login()"
                ></v-text-field>
                <v-text-field
                  id="password"
                  prepend-icon="mdi-lock"
                  name="password"
                  :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="() => (value = !value)"
                  v-model="password"
                  label="Senha"
                  :type="value ? 'password' : 'text'"
                  v-on:keyup.enter="Login()"
                ></v-text-field>
                <a class="text-left float-left" @click="login = false">Recuperar senha?</a>
              </v-form>
              <v-form onSubmit="return false;" v-else>
                <v-text-field
                  prepend-icon="mdi-email"
                  name="email"
                  label="Digite seu email, para recuperar senha"
                  v-model="email"
                  type="text"
                  v-on:keyup.enter="RecuperarConta()"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="bgp white--text" color="blue" @click="login = true; email = ''" v-if="!login">Voltar</v-btn>
              <v-btn class="bgp white--text" @click="login ? Login() : RecuperarConta()">{{ login ? 'Entrar' : 'Recuperar'}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import SnackBar from "../components/WarningComponents/snackbar.vue";
import axios from "axios";
export default {
  data: () => {
    return {
      cpf: "",
      password: "",
      email: "",
      response: {},
      login: true,
      value: true,
    };
  },
  components: {
    SnackBar,
  },
  watch: {
    login(val){
      if(val){
        this.cpf = ""
        this.password = ""
        this.value = true
      }
    }
  },
  methods: {
    Login() {
      axios
        .post(`https://gespa.dev.weblevel.pt/api/users/login`, {
          cpf: this.cpf,
          password: this.password,
        })
        .then((response) => {
          this.$store.dispatch("setUser", response.data);
          window.location.href = "/";
        })
        .catch((error) => {
          this.response = {
            type: "danger",
            text: error.response.data.message,
            visible: true,
          };
          if (error.response.data.error == 1) {
            this.cpf = "";
            this.password = "";
          } else {
            this.password = "";
          }
        })
        
    },
    RecuperarConta(){
      axios.post(`https://gespa.dev.weblevel.pt/api/users/recuperarSenha`, {
          email: this.email,
        }).then((response) => {
            this.response = {type: "success", text: response.data.message, visible: true}
            this.email = ""
            this.login = true
        }).catch((error) => {
          this.response = {
            type: "danger",
            text: error.response.data.message,
            visible: true,
          };
          this.email = ""
        })
    }
  },
};
</script>

<style scoped>
#body {
  background: url("../assets/img/bglogin.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 125%;
  margin-top: -14%;
}
</style>