<template>
  <div>
    <v-stepper v-model="step" v-if="campos">
      <div v-for="(item, index) in campos.length" :key="item">
        <v-stepper-step style="width: 100%" @click="scrollTo(`${item <= 1 ? campos[0]['name'] : campos[item-2]['name']}`)" editable :complete="step > index" :step="index" :id="`top-${campos[item-1]['name']}`">
          <span style="display: block !important;" class="ml-2">{{campos[item-1]['label']}}</span>
        </v-stepper-step>
        <v-stepper-content :step="index" class="pa-1">
          <div class="my-5">
            <Inputs :campos="campos[item-1]['campos']" />
          </div>
          <div v-if="index == step">
            <v-btn 
              color="primary"
              @click="newStep(item, campos[item-1]['name'])"
            >
              Continuar
            </v-btn>
          </div>
        </v-stepper-content>
        
      </div>
    </v-stepper>
  </div>
</template>

<script>
import Inputs from '../InputForm/InputWithoutVmodel.vue'
import scrollTo from 'vue-scrollto'

export default {
  created(){
  },     
  props: {
      campos: Array
  },
  data () {
    return {
      step: 0,
      camps: []
    }
  },
  components: {
      Inputs
  },
  methods: {
    newStep(item, nome){
        if(item != this.campos.length){ 
            this.step =  item
            this.scrollTo(`${nome}`)
        }else{
            this.step = 0
            this.scrollTo(`${this.campos[0]['name']}`)
        }
    },
    scrollTo(name){
      scrollTo.scrollTo(`#top-${name}`)
    }
  },
  watch: {
    campos(){
      this.step = 0
    }
  }
}
</script>

<style >
.v-stepper__label{
  display: block !important;
}
</style>
