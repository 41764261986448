<template>
  <div class="mb-10">
    <nav>
      <v-app-bar style="z-index: 9;" app flat color="#800000">
          <v-app-bar-nav-icon  @click="drawer = true" v-if="isSmOrXsScreen && this.$store.getters.User.token != ''">
            <v-icon color="white">{{drawer ? 'mdi-close' : 'mdi-menu'}}</v-icon>
          </v-app-bar-nav-icon>
        <a href="/" style="text-decoration:none">
        <img
          class="mr-3"
          src="../../assets/img/logo.png"
          max-width="2%"
          height="50px"
          style="filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7472%) hue-rotate(26deg) brightness(123%) contrast(100%);"
        /></a>
        <v-spacer></v-spacer> 
        <MenuDropdown color="white" icon="mdi-home" v-if="this.$store.getters.User.token != ''" />
        <v-btn href="/login" style="color: black !important;" v-else-if="this.$route.path == '/'">ENTRAR</v-btn>
        <v-btn color="transparent" fav text href="/gespa" v-else-if="this.$route.name == 'login'"><v-icon color="white">mdi-earth</v-icon></v-btn>
      </v-app-bar>
      <v-navigation-drawer 
        :style="!isSmOrXsScreen ? 'z-index: 10;' : 'margin-top:56px'" 
        :width="!isSmOrXsScreen ? '335px' : '85%'"  
        class="bgp"  
        :permanent="isSmOrXsScreen ? false : true"
        :expand-on-hover="isSmOrXsScreen ? false : true"
        app 
        v-model="drawer"
        
        v-if="this.$store.getters.User.token != ''">
        
        
        <div v-for="(link,index) in links" :key="link.text">
          <template v-if="link.sublink && checkPermission(link.permissao)">
            <!-- 1 nivel com sublink -->
            <v-list-group no-action color="white" class="mb-5" @click="close(index)" v-model="link.active">
              <v-icon color="white" slot="appendIcon">mdi-chevron-down</v-icon>

                <template slot="activator">
                  <v-list-item class="pa-0">
                    <v-icon color="white">{{ link.icon }}</v-icon>
                    <v-list-item-content class="font-weight-medium text-left ml-3 white--text">
                      {{ link.text }}
                    </v-list-item-content>
                  </v-list-item>
                </template>
    
                <div v-for="(sublink, indexSub) in link.sublink" :key="sublink.text">
                  <template v-if="sublink.sublink && checkPermission(sublink.permissao)">
                    <!-- 3 nivel  com sublink -->
                    <v-list-group no-action prepend-icon="" @click="closeSubLinks(indexSub)" v-model="sublink.active">
                      <v-icon class="white--text" slot="appendIcon">
                        mdi-chevron-down
                      </v-icon>

                      <template slot="activator">
                        <v-list-item>
                          <v-list-item-content class="font-weight-medium text-left white--text">
                            {{ sublink.text }}
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <div v-for="children in sublink.sublink" :key="children.text">
                        <v-list-item class="bgs" :to="children.route" v-if="checkPermission(children.permissao)">
                        <template>
                          <v-list-item-content class="font-weight-medium text-left white--text"> <!--AQUI ----->
                            {{ children.text }}
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                      </div>
                    </v-list-group>
                  </template>

                  <template v-else-if="checkPermission(sublink.permissao)">
                      <!-- 3 nivel sem sublink -->
                      <v-list-group prepend-icon="" append-icon="">
                        <template v-slot:activator>
                          <v-list-item :to="sublink.route" @click="closeSubLinks(-1)">
                            <v-list-item-content class="font-weight-medium text-left white--text">
                              {{sublink.text}}
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list-group>
                  </template>
                </div>
            </v-list-group>
          </template>
          <template v-else-if="checkPermission(link.permissao)">
            <!-- 1 nivel sem sublink -->
            <v-list-item  @click="closeAll()" class="mb-5" link :to="link.route" :key="index" color="white">
                <v-icon color="white">{{ link.icon }}</v-icon>
                <v-list-item-title color="white" class="font-weight-medium text-left ml-3 white--text">
                  {{ link.text }} 
                </v-list-item-title>
            </v-list-item>
          </template> 
        </div>
      </v-navigation-drawer>
    </nav>
  </div>
</template>

<script>
import MenuDropdown from "./MenuDropdown.vue";
import _ from "lodash";
export default {
  mounted() {
    this.$store.dispatch('tokenExpired')
    _.isEmpty(this.drawer);
  },
  data: () => {
    return {
      drawer: false,
      links: [
        {
          icon: "mdi-home-outline",
          text: "Página Inicial",
          route: "/",
          permissao: ["1", "2"],
          sublink: null,
        },
        {
          icon: "mdi-bank-outline",
          text: "ICMS - Cultural",
          permissao: ["1", "2"],
          active:false,
          sublink: [
            {
              icon: "mdi-plus",
              text: "Quadro I - Gestão",
              permissao: ["1"],
              route: "/quadro/I",
            },
            {
              icon: "mdi-plus",
              text: "Quadro II - Proteção",
              permissao: ["1", "2"],
              active:false,
              route: "",
              sublink: [
                {
                  text: "A) Inventário de Proteção do Patrimônio Cultural",
                  permissao: ["1", "2"],
                  route: "/inventarioProtecao",
                },
                {
                  text: "B) Processos de Tombamento de Bens Materiais, na Esfera Municipal",
                  permissao: ["1"],
                  route: "/quadro/II B",
                },
                {
                  text: "C) Processos de Registro de Bens Imateriais, na Esfera Municipal",
                  permissao: ["1"],
                  route: "/quadro/II C",
                },
              ],
            },
            {
              icon: "mdi-plus",
              text: "Quadro III",
              permissao: ["1", "2"],
              active:false,
              route: "",
              sublink: [
                {
                  text: "Laudos",
                  permissao: ["1", "2"],
                  route: "/quadro_III/A",
                },
                {
                  text: "Salvaguarda",
                  permissao: ["1", "2"],
                  route: "/quadro_III/B",
                },
                {
                  text: "Educação",
                  permissao: ["1", "2"],
                  route: "/quadro_III/C",
                },
                {
                  text: "Revalidação",
                  permissao: ["1", "2"],
                  route: "/quadro_III/D",
                },
              ],
            },
            {
              icon: "mdi-plus",
              text: "Arquivos Finalizados",
              permissao: ["1", "2"],
              route: "/arquivosFinalizadosICMS",
            },
          ],
        },
        {
          icon: "mdi-pillar",
          text: "Tombamentos e Registros",
          permissao: ["1", "2"],
          active:false,
          sublink: [
            {
              icon: "mdi-plus",
              text: "Novo Bem",
              permissao: ["1", "2"],
              route: "/novoBem",
            },
            {
              icon: "mdi-plus",
              text: "Novo Bem Digitalizado",
              permissao: ["1", "2"],
              route: "/novoBemDigitalizado",
            },
            {
              icon: "mdi-plus",
              text: "Arquivos Finalizados",
              permissao: ["1", "2"],
              route: "/arquivosFinalizadosNovoBem",
            },
          ],
        },
        {
          icon: "mdi-book-multiple-outline",
          text: "Gestão",
          permissao: ["1"],
          active:false,
          sublink: [
            {
              text: "Sumários",
              permissao: ["1"],
              route: "/sumarios",
            },
            {
              text: "Etiquetas",
              permissao: ["1"],
              route: "/etiquetas",
            }
          ],
        },
        {
          icon: "mdi-account-outline",
          text: "Usuários",
          route: "/usuarios",
          permissao: ["1"],
          sublink: null,
        },
        
      ],
    };
  },
  components: {
    MenuDropdown,
  },
  computed: {
    isSmOrXsScreen(){
      if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md){
        return true;
      }else{
        return false;
      }
    }
  },
  methods: {
    checkPermission(permissao) {
      return _.includes(permissao, this.$store.getters.User.level);
    },
    close(id){
      this.links.map((link,index) =>{
        if(index == id){
          this.$set(this.links,'active',true)
        }else{
          this.$set(link,'active',false)
        }
      })
      this.closeSubLinks(-1)
    },
    closeSubLinks(id){
      this.links.map((link) =>{
        // 1 nivel com sublink
        if(link.sublink){
          link.sublink.map((sublink, index) => {
            // 2 nivel com sublink
            if(sublink.sublink){
              if(index == id){
                //este função supostamente não está a funcionar, o mesmo para o método que está no close(id), porque ele retorna o active a true independentemente dessa função
              this.$set(this.links,'active',true)
              }else{
                this.$set(sublink,'active',false)
              } 
            }
          })
        }
      })
    },
    closeAll(){
      this.links.map((link) =>{
        if(link.active){
          this.$set(link,'active',false) 
        }
      })
    },
    logout() {
      this.$store.dispatch("logout");
      window.location.href = "/";
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  * {
    font-size: 15px;
  }
}
.v-application a, .v-application a:hover{
  color: white !important;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled){
  color: white !important;
}
</style>