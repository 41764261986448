import _ from 'lodash';

export function unescapeObject(object){
    if(!_.isObject(object)){
        return object;
    }

    //Object
    Object.keys(object).map(function(key) {
        if(_.isString(object[key])){
            object[key] = _.unescape(object[key])
        }else if(_.isObject(object[key])){ // object -> object
            if(_.isArray(object[key])){ // object -> object/array
                object[key].map(element => {
                    if(_.isObject(element)){
                        Object.keys(element).map(function(subkey) {
                            if(_.isString(element[subkey])){
                                element[subkey] = _.unescape(element[subkey])
                            }else if(_.isObject(element[subkey])){
                                // console.log(element[subkey])
                            }
                        })
                    }else if(_.isString(element)){
                        element = _.unescape(element)
                    }
                })
            }else{
                Object.keys(object[key]).map(function(subkey) {
                    if(_.isString(object[key][subkey])){
                        object[key][subkey] = _.unescape(object[key][subkey])
                    }else if(_.isObject(object[key][subkey])){ // object -> object -> object/array
                        if(_.isArray(object[key][subkey])){ // if array = array -> object
                            object[key][subkey].map(element => {
                                if(_.isObject(element)){
                                    if(_.isArray(object[key])){
                                        // alert("error!!")
                                    }else {
                                        Object.keys(element).map(function(lastkey) {
                                            if(_.isString(element[lastkey])){
                                                element[lastkey] = _.unescape(element[lastkey])
                                            }
                                        })
                                    }
                                }
                            })
                        }
                    }
                })
            }
        }
    });

    return object;
}

/* conectar um objeto a outro */
export function conectObject(object, cloneObject, replace){
    var new_key = ""
    Object.keys(cloneObject).map(function(key) {
        if(_.isString(cloneObject[key]) && key != "f_responsavel"){
            new_key = key.replace(replace, "")
            object[new_key] = _.unescape(cloneObject[key])
        }else if(key != "f_campos" && key != "f_responsavel"){
            new_key = key.replace(replace, "")
            object[new_key] = cloneObject[key]
        }
    })

    return object
}