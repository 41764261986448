<template>
  <v-container>
    <Header :titulo="titulo" :itemsBreadCrumb="BreadCrumbs" />
    <div>
      <div v-if="insert">
        <v-card>
          <v-col cols="12" :class="isSmOrXsScreen ? 'text-center' : 'text-left ml-3'">
            <BtnAddBack :sub="sub" :insert="insert" @setInsert="(val) => {insert = val;}" />
          </v-col>
          <div class="rounded-lg elevation-1" style="background-color: white">
            <v-select class="mx-5 mt-4" outlined dense v-model="selectForm" :items="SelectedItemsForm" label="Tipo de Formulário"></v-select>
            <div style="background-color: white">
              <div v-if="selectForm && $store.state.upload.dialog == false">
                <div>
                  <Stepper :campos="campos" />
                </div>
                <div class="mt-9">
                  <BtnActions :tipoDocumento="'formulario'" :tipoFormulario="campos[0].value" :editId="EditId" :isNew="isNew" :campos="campos"  :Actions="[{type: 'terminado', terminado: TerminadoBtn, cols:'12'}]" />
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
      <div v-else>
        <ModalBaseTable :actions="actions" :ationsBtn="btnOption" :DataSearch="dataSearch" :edit="edit" :hasSelect="true" :headers="headers" :labelSearch="labelSearch" :getData="getData" :tipoDocumento="'formulario'" :terminado="false" :SelectedItems="SelectedItems" :sub="sub" :insert="insert" @getInsert="(val) => { insert = val }" />
      </div>
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import Stepper from "../../components/InputForm/Stepper.vue";
import BtnActions from '../../components/InputForm/BtnAction.vue';
import Header from "../../components/Header.vue";
import BtnAddBack from "../../components/BtnAddBack.vue";
import ModalBaseTable from '../../components/DataTables/modelBaseData.vue';
/* campos */
import novoBem from '../../assets/formularios/novobem/novobem.json'
import NovoBemTombado from '../../assets/formularios/novobem/novo_bem_tombado.json'
import NovoBemRegistro from '../../assets/formularios/novobem/novo_bem_registro.json'

export default {
  mounted() {
    this.SelectedItemsForm = _.clone(this.SelectedItems)
    this.SelectedItemsForm.pop()

    if (this.$route.params.id) {
      this.edit({ id: this.$route.params.id });
    }
  },
  components: {
    Stepper,
    ModalBaseTable,
    BtnActions,
    Header,
    BtnAddBack,
  },
  data() {
    return {
      showBtn: false,
      btnOption: [
        { cols: "6" },
        { cols: "3", type: "download" },
        { cols: "3", type: "remove" },
      ],
      labelSearch: "Procure por Endereço, Proteção ou Designação",
      getData: [
        { terminado: false, filtro: "novoBemNonDigitalizado" }
      ],
      actions: ["edit", "delete","view", "download"],
      titulo: "Novo Bem",
      sub: "Novo Formulário",
      dataSearch: [{ tipo: "novoBemNonDigitalizado", terminado: false, filtro: "formulario"}],
      BreadCrumbs: [
        {
          text: "Página Inicial",
          disabled: false,
          href: "/",
        },
        {
          text: "Formulário Novo Bem",
          disabled: true,
          href: "/novoBem",
        },
      ],
      isNew: true,
      insert: false,
      headers: [
        {
          text: "Designação",
          align: "start",
          sortable: true,
          value: "designacao",
          width: "25%"
        },
        { text: "Endereço", align:"start", value: "endereco", width: "25%" },
        { text: "Proteção", align:"start", value: "protecao", width: "25%" },
        { text: "Responsável", align:"start", value: "Userresponsavel", width: "10%" },
        { text: "Ações", value: "actions", align: "start", sortable: false, width: "15%" },
      ], 
      selectForm: "",
      SelectedItemsForm: [],
      SelectedItems: [
        { text: "Novo Bem Tombamento", value: "NovoBemTombado"},
        { text: "Novo Bem Registro", value: "NovoBemRegistro"},
        { text: "Todos os Documentos", value: "*"}
      ],
      campos: novoBem,
    };
  },
  watch: {
    "$route.params.id"(val) {
      if (val) {
        this.edit({ id: val });
      }
    },
    selectForm(){
      var val = this.selectForm
      var tipoFormulario = this.selectForm
      if(val){
        if(!this.$route.params.id){
          this.$store.dispatch('loadingBasic', "Aguarde, por favor")
          setTimeout(() => {
            var _campos = this.getCampos(tipoFormulario)
            this.resetJson(_campos)
            this.campos = _campos
            this.campos[0].value = tipoFormulario
            this.$store.state.upload.dialog = false
          }, 1000)

        }else{
          var _campos = this.getCampos(tipoFormulario)
          this.saveEditItemToJson(_campos)
          this.campos = _campos
          this.campos[0].value = tipoFormulario        
        }
      }
    }
  },
  computed: {
    EditId() {
      return !_.isEmpty(this.$store.state.EditForm) ? this.$store.state.EditForm[0].id : null;
    },
    isSmOrXsScreen(){
      if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
        return true;
      }else{
        return false;
      }
    },
    TerminadoBtn(){
      const terminado = this.campos[0]["campos"].find(element => element.name == 'terminado');
      if(terminado.value != null){
        return terminado.value
      }else{
        return false
      }
    },
  },
  methods: {
    edit(item) {
      if (!this.$route.params.id) {
        this.$router.push({ path: `/novoBem/editar/${item.id}` });
      }

      var dados = {
        filtro: "id",
        id: item.id,
        terminado: false,
        editar: true,
      };

      this.$store.dispatch("getForm", dados).then(() => {
        item = this.$store.state.EditForm[0];
        var type = item.tipo
        this.selectForm = type
      
        this.campos[0].value = type;

        this.campos = this.getCampos(type)
        
        this.saveEditItemToJson(this.campos)

        this.isNew = false;
        this.insert = !this.insert;
      });
    },
    getCampos(val){
      if(val){
        var _campos = []
        switch(val){
          case "NovoBemTombado":
            _campos = NovoBemTombado
            return _campos
          case "NovoBemRegistro":
            _campos = NovoBemRegistro
            return _campos
        }
        return []
      }
    },
    saveEditItemToJson(json){
      if(json){
        var item = this.$store.state.EditForm[0];
          json.map((subTitulos) => {
            subTitulos.campos.map(campo => {
              if(campo.name == 'tipo'){
                campo.show = false
            }else if (campo.type == "file") {
              campo.idFile = this.EditId;
              campo.value = ""
            }else{
              campo.value = item[campo.name];
              if(campo.name == "terminado"){
                campo.value = false
              }else if(campo.type == 'number' && _.isEmpty(campo.value)){
                campo.value = 0
              }
            }
            if(campo.type=="radio" && item[`${campo.name}_especificar`]){
              campo.especificar = item[campo.name+"_especificar"]
            }
            if(campo.type=="radioMultipleInputs"){
              if(!_.isArray(campo.subElement) && campo.value == campo.showElement){
                campo.subValue = item[campo.subElement]
              }else if(campo.value == campo.showElement){
                var values = []
                campo.subElement.map(element => {
                  values.push(item[element])
                })
                campo.subValue = values
              }

            }
            })
          });
      }
    },
    resetJson(json){
      if(json){
        json.map(div => {
          div.campos.map(element => {
            if(element.name == 'tipo' && element.disabled){
              element.show = false
              element.disabled = false
            }
            if(element.type == 'switch'){
              element.value = false
            }else{
              element.value = ""
            }
          })
        })
      }
    },
  }
};
</script>

