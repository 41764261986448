<template>
  
    <v-btn :width="$vuetify.breakpoint.xs  ? '90%' : ''" :color="insert ? 'blue' : 'green'" class="white--text" @click="btnAdd();">
      <v-icon> {{ insert ? "mdi-undo-variant" : "mdi-plus" }} </v-icon>
      {{ insert ? "voltar" : sub }}
    </v-btn>
  
</template>

<script>
export default {
    mounted(){
        if(this.$route.params.editar && !this.$route.params.id){
            this.$emit("setInsert", true)
        }
    },
    props:{
        sub: String,
        insert: Boolean,
    },
    methods:{
        btnAdd() {
            var insert = !this.insert
            var path = "/" + this.$route.name
            this.$emit("setInsert", insert);
            
            if(this.$route.params.num){
                path += "/" + this.$route.params.num
            }

            if (insert) {
                this.$router.push({ path: path + "/adicionar" });
            } else {
                this.$router.push({ path: path });
            }
        },
    }
}
</script>