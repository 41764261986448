<template>
  <v-container>
    <Header :titulo="titulo" :itemsBreadCrumb="BreadCrumbs" />
    <div>
      <div v-if="insert">
        <v-card>
          <v-col cols="12" :class="isSmOrXsScreen ? 'text-center' : 'text-left ml-3'">
            <BtnAddBack :insert="insert" @setInsert="(val) => {insert = val;}" />
          </v-col>
          <template v-if="insert && this.$route.params.tipo != 'NovoBemDigitalizado'">
            <div class="rounded-lg elevation-1" style="background-color: white">
                <div v-if="insert && $store.state.upload.dialog == false">
                  <div class="my-5">
                    <Stepper :campos="campos" />
                  </div>
                  <div class="mt-9">
                    <BtnActions :tipoDocumento="'formulario'" :tipoFormulario="this.$route.params.tipo ? this.$route.params.tipo : ''" :editId="EditId" :isNew="false" :campos="campos"  :Actions="[{type: 'terminado', terminado: TerminadoBtn, cols:'12'}]" />
                  </div>
                </div>
            </div>
          </template>

          <template v-else-if="insert && this.$route.params.tipo == 'NovoBemDigitalizado'">
            <div class="rounded-lg elevation-1" style="background-color: white">
              <Inputs :campos="campos" :docTerminado="TerminadoBtn" :tipoFormulario="tipoFormulario" />
              <div v-if="this.$route.params.tipo == 'NovoBemDigitalizado'">
                <br>
                <Draggable :idDocDigitalizado="EditId" @getFilesOrganize="(val) => {campos[8].value = val}" />
              </div>
              <BtnActions :tipoDocumento="'formulario'" :tipoFormulario="this.$route.params.tipo ? this.$route.params.tipo : ''" :editId="EditId" :isNew="false" :campos="campos"  :Actions="[{type: 'terminado', terminado: TerminadoBtn, cols:'12'}]" />
            </div>
          </template>
        </v-card>
      </div>

      <div v-else>
        <ModalBaseTable :actions="actions" :ationsBtn="btnOption" :DataSearch="dataSearch" :edit="edit" :hasSelect="true" :headers="headers" :labelSearch="labelSearch" :getData="getData" :tipoDocumento="'formulario'" :terminado="true" :SelectedItems="SelectedItems" :insert="insert" @getInsert="(val) => { insert = val }" />
      </div>
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import Inputs from "../../components/InputForm/Input";
import Stepper from "../../components/InputForm/Stepper.vue";
import BtnActions from '../../components/InputForm/BtnAction.vue';
import Header from "../../components/Header.vue";
import BtnAddBack from "../../components/BtnAddBack.vue";
import Draggable from '../../components/InputForm/draggable.vue';
import ModalBaseTable from '../../components/DataTables/modelBaseData.vue'
/* campos */
import novoBem from '../../assets/formularios/novobem/novobem.json'
import novoBemRegistro from '../../assets/formularios/novobem/novo_bem_registro.json'
import novoBemTombado from '../../assets/formularios/novobem/novo_bem_tombado.json'
import novoBemDigitalizado from '../../assets/formularios/novobem/novobemdigitalizado.json'

export default {
  mounted() {
    if(parseInt(this.$store.state.User.level) == 1){
      this.btnOption = [
        { cols: "6" },
        { cols: "3", type: "download" },
        { cols: "3", type: "remove" },
      ],
      this.actions = ["edit", "delete", "view", "download"]
    }

    this.$store.dispatch("resetData")

    if (this.$route.params.id) {
      this.edit({id: this.$route.params.id});
    }
  },
  components: {
    Inputs,
    BtnActions,
    ModalBaseTable,
    Stepper,
    Header,
    BtnAddBack,
    Draggable
  },
  data() {
    return {
      tipoFormulario: '',
      btnOption: [
        {cols: "9"},
        {cols: "3", type: "download"},
      ],
      titulo: "Arquivos Finalizados Novo Bem",
      dataSearch: [{ tipo: "novoBem*", terminado: true, filtro: "formulario"}],
      labelSearch: "Procure por Endereço, Proteção ou Designação",
      SelectedItems: [
        { text: "Novo Bem Tombamento", value: "NovoBemTombado"},
        { text: "Novo Bem Registro", value: "NovoBemRegistro"},
        { text: "Novo Bem Digitalizado", value: "NovoBemDigitalizado"},
        { text: "Todos os Documentos", value: "*"}
      ],
      getData: [
        {
          filtro: "novoBem*",
          terminado: true
        }
      ],
      BreadCrumbs: [
        {
          text: "Página Inicial",
          disabled: false,
          href: "/",
        },
        {
          text: "Arquivos Finalizados Novo Bem",
          disabled: true,
          href: "/arquivosFinalizadosNovoBem",
        },
      ],
      /* DataTable */
      actions: ["view", "download"],
      headers: [
        { text: "Endereço", align: "start", value: "endereco", width: "25%" },
        { text: "Proteção", align: "start", value: "protecao", with: "25%%" },
        { text: "Processo", align: "start", value: "processo", width: "20%" },
        { text: "Data da Última Alteração", align: "start", value: "update", width: "15%" },
        { text: "Ações", value: "actions", align: "start", sortable: false, width: "15%" },
      ],
      campos:novoBem,
      insert: false,
    };
  },
  watch: {
    "$route.params.id"(val) {
      if (val) {
        this.edit({id: val})
      }
    },
  },
  computed: {
    EditId() {
      return !_.isEmpty(this.$store.state.EditForm) ? this.$store.state.EditForm[0].id : null;
    },
    TerminadoBtn(){ 
      let terminado = this.campos;
      if(this.campos[0]["campos"]){
        terminado = this.campos[0]["campos"].find(element => element.name == 'terminado');
      }else{
        terminado = this.campos.find(element => element.name == 'terminado');
      }

      if(terminado.value != null){
        return terminado.value
      }else{
        return false
      }
    },
    isSmOrXsScreen(){
      if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
        return true;
      }else{
        return false;
      }
    }
  },
  methods: {
    edit(item) {
      if(!this.$route.params.id){
        this.$router.push({ path: `/arquivosFinalizadosNovoBem/editar/${item.id}/${item.tipo}` })
      }
      
      var dados = {
        filtro: "id",
        id: item.id,
        terminado: true,
        editar: true,
      };

      this.$store.dispatch("getForm", dados).then(() => {
        item = this.$store.state.EditForm[0];
        var type = item.tipo
        this.tipoFormulario = type
        this.campos[0].value = type

        this.campos = this.getCampos(type)
        

        this.saveEditItemToJson(this.campos, type == 'NovoBemDigitalizado' ? true : false)

        this.insert = !this.insert;
      });
    },
    getCampos(val){
      if(val){
        var _campos = []
        switch(val){
          case "NovoBemTombado":
            _campos = novoBemTombado
            return _campos
          case "NovoBemRegistro":
            _campos = novoBemRegistro
            return _campos
          case "NovoBemDigitalizado":
            _campos = novoBemDigitalizado
            return _campos
        }
        return []
      }
    },
    saveEditItemToJson(json, isDigitalizado){
      if(json){
        var item = this.$store.state.EditForm[0];
        if(isDigitalizado){
        this.campos.map((campo) => {
          if(campo.type == "file"){
            campo.idFile = this.EditId
            campo.value = ""
          }else{
            campo.value = item[campo.name];
            if(campo.name == 'terminado'){ 
              campo.value = true
            }else if(campo.type == 'number' && _.isEmpty(campo.value)){
              campo.value = 0
            }
          }
        });
      }else{
          json.map((subTitulos) => {
            subTitulos.campos.map(campo => {
              if(campo.name == 'tipo'){
                campo.show = true
                campo.disabled = true
                campo.value = item.tipo
            }else if (campo.type == "file") {
              campo.idFile = this.EditId;
              campo.value = ""
            }else{
              campo.value = item[campo.name];
              if(campo.name == "terminado"){
                campo.value = true
              }else if(campo.type == 'number' && _.isEmpty(campo.value)){
                campo.value = 0
              }
            }
            if(campo.type=="radio" && item[`${campo.name}_especificar`]){
              campo.especificar = item[campo.name+"_especificar"]
            }
            if(campo.type=="radioMultipleInputs"){
              if(!_.isArray(campo.subElement) && campo.value == campo.showElement){
                campo.subValue = item[campo.subElement]
              }else if(campo.value == campo.showElement){
                var values = []
                campo.subElement.map(element => {
                  values.push(item[element])
                })
                campo.subValue = values
              }
            }
          })
        });
      }
      }
    },
  },
};
</script>

