<template>
  <PermissionPage :title="title" :description="description" :icon="icon" />
</template>

<script>
import PermissionPage from '../../components/WarningComponents/NotFound.vue'

export default {
  data: () => {
    return {
      icon: 'mdi-account-lock',
      title: 'Permissão negada',
      description: 'Não tem permissão para aceder a este conteudo',
    }
  },
  components: {
    PermissionPage
  }  
}
</script>