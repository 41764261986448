<template>
  <div>
     <!-- DropDown notificações -->
    <v-badge v-if="this.$store.state.User.level == 1" style="margin-right:15px" :content="messages" :value="messages" color="red" overlap>
      <v-menu :offset-y="true" left >
        <template v-slot:activator="{on,attrs}">
          <v-icon v-bind="attrs" color="white" v-on="on">
            mdi-bell-outline
          </v-icon>
        </template>
        <v-list style="max-width: 600px" v-if="messages > 0">
          <v-list-item :class="$vuetify.breakpoint.xs ? 'list-item-hover my-2' : 'list-item-hover'" link v-for="(item, index) in dataNotification" :key="index" :to="item.route">
            <v-list-item-title class="text-left" style="white-space:normal;">Tem <strong>{{item.num}}</strong> {{item.num > 1 ? 'documentos' : 'documento'}} não {{item.num > 1 ? 'encaminhados' : 'encaminhado'}} {{item.route == "/quadro_I" ? 'no' : 'nos'}} {{item.nome}}
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-list v-else>
          <v-list-item>
            <v-list-item-title>
              Sem notificações
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-badge>
    <!-- DropDown menu perfil -->
    <v-menu right rounded="rounded" offset-y offset-x max-width="60%">
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          v-click-outside="onClickOutside"
          color="transparent"
          class="white--text mr-n1"
          v-bind="attrs"
          @click="state = !state"
          v-on="on"
          elevation="0"
        >
          <v-icon>mdi-account-circle</v-icon> <v-icon v-if="!state">mdi-chevron-down</v-icon
          ><v-icon v-else>mdi-chevron-up</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
        class="cor-items"
          v-for="item in items"
          :key="item.tipo"
          link
          @click="evento(item.tipo)"
        >
          <v-icon :color="item.color"> {{ item.icon }}</v-icon>
          <v-list-item-content
            class="text-left"
            v-text="item.nome"
          ></v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  mounted() {
    /* verificar se é admin */
    if(this.$store.state.User.level == "1"){
      this.$store.dispatch('getNotifications');
    }

    var user = this.$store.getters.User;
    if (user.name != "") {
      this.name = user.name;
    } else {
      this.name = user.cpf;
    }
  },
  computed: {
    messages() {
      return this.$store.state.numMessages
    },
    dataNotification() {
      return this.$store.state.messagesNotifications
    }
  },  
  data: () => {
    return {
      state: false,
      name: "",
      items: [
        {
          nome: "Meu Perfil",
          icon: "mdi-account",
          tipo: "edit",
          color: "black",
        },
        { nome: "Sair", icon: "mdi-logout", tipo: "logout", color: "red" },
      ],
    };
  },
  watch: {
    "$store.state.User"(val) {
      if (val.name != "") {
        this.name = val.name;
      } else {
        this.name = val.cpf;
      }
    },
  },
  methods: { 
    onClickOutside() {
      if (this.state) {
        this.state = !this.state;
      }
    },
    evento(tipo) {
      switch (tipo) {
        case "logout":
          this.$store.dispatch("logout");
          break;
        case "edit":
          var path = "/usuarios/editar/" + this.$store.getters.User.id;
          if(path != this.$route.path){
              this.$router.push({
              path: path,
            });
          }
          break;
      }
    },
  },
};
</script>

