<template>
  <div>
    <v-snackbar :color="color" top centered v-model="snackbar" v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn :color="colorbtn" text v-bind="attrs" @click="clear">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar :color="color" top right v-model="snackbar" v-else>
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn :color="colorbtn" text v-bind="attrs" @click="clear">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    snackbar: Boolean,
    type: String,
  },
  updated() {
    this.setColors()
  },
  data: () => {
    return {
      colorbtn: "",
      color: "",
    };
  },
  watch: {
    snackbar(val){
      if(val){
        this.ResetResponse()
      }
    }
  },
  methods: {
    setColors() {
      if (this.type == "success") {
        this.colorbtn = "white";
        this.color = "success";
      } else if (this.type == "danger") {
        this.colorbtn = "white";
        this.color = "red";
      }
    },
    ResetResponse() {
      setTimeout(() => {
        this.$emit("dismiss", false)
      }, 4000);  
    },
    clear(){
      this.$emit("dismiss",false); 
    }
  },
};
</script>