<template>
  <v-container>
    <Header :titulo="titulo" :itemsBreadCrumb="BreadCrumbs" />

    <div>
      <div v-if="insert">
        <v-card>
          <v-col cols="12" :class="isSmOrXsScreen ? 'text-center' : 'text-left ml-3'">
            <BtnAddBack :sub="sub" :insert="insert" @setInsert="(val) => {insert = val;}" />
          </v-col>
          <div class="mt-4" style="background-color: white">
            <Inputs :campos="campos" />
            <br>
            <Draggable :idDocDigitalizado="EditId" @getFilesOrganize="(val) => {campos[8].value = val}" />

            <BtnActions
              :tipoDocumento="'formulario'"
              :tipoFormulario="'NovoBemDigitalizado'"
              :editId="EditId"
              :isNew="isNew"
              :campos="campos"
              :Actions="[{ type: 'terminado', terminado: campos[1].value, cols: '12' }]"
            />
          </div>
        </v-card>
      </div>

      <div v-else>
        <ModalBaseTable :actions="actions" :ationsBtn="btnOption" :DataSearch="dataSearch" :edit="edit" :hasSelect="true" :headers="headers" :labelSearch="labelSearch" :getData="getData" :tipoDocumento="'formulario'" :terminado="false" :sub="sub" :insert="insert" @getInsert="(val) => { insert = val }" />
      </div>
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
// import moment from "moment";
import Header from "../../components/Header.vue";
import BtnAddBack from "../../components/BtnAddBack.vue";
import Inputs from "../../components/InputForm/Input";
import BtnActions from "../../components/InputForm/BtnAction.vue";
import Draggable from '../../components/InputForm/draggable.vue';
import ModalBaseTable from '../../components/DataTables/modelBaseData.vue';
/* campos */
import novoBemDigitalizado from '../../assets/formularios/novobem/novobemdigitalizado.json'

export default {
  mounted() {
    if (this.$route.params.id) {
      this.edit({ id: this.$route.params.id });
    }
  },
  data() {
    return {
      titulo: "Novo Bem Digitalizado",
      BreadCrumbs: [
        {
          text: "Página Inicial",
          disabled: false,
          href: "/",
        },
        {
          text: "Novo Bem Digitalizado",
          disabled: true,
          href: "/novoBemDigitalizado",
        },
      ],
      getData: [
        {
          terminado: false,
          filtro: "tipo",
          tipo: "NovoBemDigitalizado",
        }
      ],
      labelSearch: "Procure por Endereço, Proteção ou Designação",
      sub: "Novo Bem Digitalizado",
      insert: false,
      isNew: true,
      dataSearch: [
        { tipo: "NovoBemDigitalizado", terminado: false, filtro: "formulario" },
      ],
      campos: novoBemDigitalizado,
      headers: [
        {
          text: "Nº do processo",
          align: "start",
          sortable: true,
          value: "processo",
          width: "25%",
        },
        { text: "Endereço", align: "start", value: "endereco", width: "25%" },
        { text: "Proteção", align: "start", value: "protecao", width: "25%" },
        {
          text: "Responsável",
          align: "start",
          value: "Userresponsavel",
          width: "10%",
        },
        {
          text: "Ações",
          value: "actions",
          align: "start",
          sortable: false,
          width: "15%",
        },
      ],
      actions: ["edit", "delete", "view", "download"],
      btnOption: [
        { cols: "6" },
        { cols: "3", type: "download" },
        { cols: "3", type: "remove" },
      ],
    };
  },
  watch: {
    insert(){
      if(!this.$route.params.id){
        this.resetJson(this.campos)
      }
    },
    "$route.params.id"(val) {
      if (val) {
        this.edit({ id: val });
      }
    },
  },
  components: {
    Header,
    BtnAddBack,
    Inputs,
    ModalBaseTable,
    BtnActions,
    Draggable
  },
  computed: {
    EditId() {
      return !_.isEmpty(this.$store.state.EditForm) ? this.$store.state.EditForm[0].id : null;
    },
    isSmOrXsScreen(){
      if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
        return true;
      }else{
        return false;
      }
    },
  },
  methods: {
    edit(item) {
      if (!this.$route.params.id) {
        this.$router.push({ path: `/novoBemDigitalizado/editar/${item.id}` });
      }

      var dados = {
        filtro: "id",
        id: item.id,
        terminado: false,
        editar: true,
      };
      
      this.$store.dispatch("getForm", dados).then(() => {
        item = this.$store.state.EditForm[0];

        this.campos.map((campo) => {
          if (campo.type == "file") {
            campo.idFile = this.EditId;
            campo.value = ""
          } else {
            campo.value = item[campo.name];
            if (campo.name == "terminado") {
              campo.value = false;
            }else if(campo.type == 'number' && _.isEmpty(campo.value)){
              campo.value = 0
            }
          }
        });

        this.isNew = false;
        this.insert = !this.insert;
      });
    },
    resetJson(json){
      json.map(element => {
        if(element.type == 'switch'){
          element.value = false
        }else{
          element.value = ""
        }
      })
    },
  },
};
</script>








