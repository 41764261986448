<template>
  <div>
    <DialogAlert :details="dialogAlert" :confirm="Salvar" :close="Close" />
    <Loader :colorLine="loader.color" :dialog="loader.dialog" :text="loader.text" :value="loader.value" />
    <DialogAlert :close="Close" :confirm="RemoveForms" :details="dialogDelete" :loading="loading" />
    <SnackBar
      @dismiss="(visible) => {response.visible = visible;}"
      :type="response.type"
      :snackbar="response.visible"
      :text="response.text"
    />
    <v-row>
      <template v-for="(action, index) in Actions">
        <v-col
          :key="index"
          :cols="action.cols"
          :sm="action.sm ? action.sm : action.cols"
          :md="action.md ? action.md : action.cols"
          :lg="action.lg ? action.lg : action.cols"
          :xl="action.xl ? action.xl : action.cols"
        >
          <template v-if="action.type == 'download'">
            <v-btn
              @click="DownloadForms()"
              color="blue"
              class="white--text"
              height="50px"
              width="100%"
            >
              <v-icon>mdi-download</v-icon>
              Descarregar
            </v-btn>
          </template>
          <template v-if="action.type == 'remove'">
            <v-btn
              @click="ConfirmDelete()"
              color="bgp"
              class="white--text"
              height="50px"
              width="100%"
            >
              <v-icon>mdi-delete</v-icon>
              Remover
            </v-btn>
          </template>
          <template v-if="action.type == 'terminado'">
            <v-btn
              class="mx-3"
              x-large
              @click="terminado(action.terminado)"
              color="green"
              :loading="loading"
              dark
            >
              {{ action.terminado ? "Finalizar" : "Salvar" }}
            </v-btn>
          </template>
          <template v-else>
            <v-col :key="action.name" :cols="action.cols"></v-col>
          </template>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import DialogAlert from "../WarningComponents/DialogAlert.vue";
import Loader from "../WarningComponents/Loader.vue"
import SnackBar from "../WarningComponents/snackbar.vue";
import _ from "lodash";
import axios from "axios";

export default {
  mounted(){
    this.loading = false
  },
  props: {
    Actions: Array,
    Selected: Array,
    campos: Array,
    isNew: Boolean,
    editId: String,
    tipoQuadro: String,
    tipoFormulario: String,
    tipoDocumento: String,
    RefreshData: Function,
  },
  data: () => {
    return {
      loading: false,
      dialogDelete: [{ dialog: false }],
      response: { visible: false },
      dialogAlert: [{ dialog: false }],
      loader: {text: "", dialog: false, color: "", value: 0},
    };
  },
  components: {
    DialogAlert,
    SnackBar,
    Loader
  },
  methods: {
    terminado(val) {
      if (val) {
        this.Finalizar();
      } else {
        this.Salvar();
      }
    },
    Salvar() {
      this.loading = true
      this.loader = {text: "", dialog: false, color: "", value: 0}
      switch (this.tipoDocumento) {
        case "formulario":
          this.salvarFormulario()
          break;
        case "docGestaoICMS":
          this.salvarDocumentoGestaoICMS()
          break;
        case "docGestaoSumario":
          this.salvarDocumentosGestao("sumarioGestao")
          break;
        case "docGestaoEtiqueta":
          this.salvarDocumentosGestao("etiquetaGestao")
          break;
      }
    },
    salvarDocumentosGestao(route){
        let formData = new FormData();
        var form = {}
        var typeAxios = this.isNew ? "add" : "update";
        if (typeAxios == "update") {
          formData.append("id", this.editId);
        }

        this.campos.map(campo => {
          if (campo.type == "file") {
            if (!_.isEmpty(campo.value)) {
              Array.from(campo.value).map((file) => {
                formData.append(campo.name + "[]", file);
              });
            }
          } else if (campo.type != "title" && campo.type != "multipleData") {
            if (campo.intoFormDate == true) {
              formData.append(campo.name, _.escape(campo.value));
            }

            form[campo.name] = _.isString(campo.value) ? _.escape(campo.value) : campo.value;
          } else if (campo.type == "multipleData") {
            if (!_.isEmpty(campo.value)) {
              campo.value.map(element => {
                Object.keys(element).map(function(key) {
                  if(_.isString(element[key])){
                    element[key] = _.unescape(element[key])
                    element[key] = _.escape(element[key])
                  }
                })
              })
              form[campo.name] = campo.value;
            }
          }
        })

        formData.append("campos", JSON.stringify(form));
        formData.append("responsavel", this.$store.state.User.cpf); 

        axios.post(`https://gespa.dev.weblevel.pt/api/auth/${route}/${typeAxios}`, formData).then((response) => {
          if (!response.data.error) {
            this.$store.dispatch("getNotifications");
            this.$router.push({ path: "/" + this.$route.name });
          }
        }).catch((error) => {
          this.response = {
            type: "danger",
            text: error.response.data.message,
            visible: true,
          };
        }).finally(()=>{
          this.loading = false
        });
    },
    salvarDocumentoGestaoICMS(){
      if (_.includes(["QUADRO I", "QUADRO II - B", "QUADRO II - C"], this.campos[1].value)) {
          let formData = new FormData();
          var typeAxios = this.isNew ? "add" : "update";
          if (typeAxios == "update") {
            formData.append("id", this.editId);
            formData.append("encaminhado", this.campos[2].value);
          }

          formData.append("nome", _.escape(this.campos[0].value));
          formData.append("responsavel", this.$store.state.User.cpf);

          axios.post(`https://gespa.dev.weblevel.pt/api/auth/documentosGestao/${this.campos[1].value}/${typeAxios}`, formData).then((response) => {
              if (!response.data.error) {
                this.$store.dispatch("getNotifications");
                this.$router.push({ path: "/" + this.$route.name + "/" + this.$route.params.num });
              }
            }).catch((error) => {
              this.response = {
                type: "danger",
                text: error.response.data.message,
                visible: true,
              };
            }).finally(()=>{
              this.loading = false
            });
        } else {
          this.response = {
            type: "danger",
            text: "Sem Tipo de Formulário",
            visible: true,
          };
          this.loading = false
        }
    },
    salvarFormulario(){
        var _campos = this.converterCamposEspeciais(this.campos, this.tipoFormulario);
        
        let formData = new FormData();
        var form = {};
        /* variavel apenas para novo bem digitalizado */
        var filesOrganizeNovoBem = [];
        /* variavel imagens legendadas */
        var imagens_legendadas = [];
        var imagens_temps = [];

        _campos.map((campo) => {
          if(campo.show){
            /* para documentos novoBem digitalizado. Tendo a haver com a organização de imagens */
            if (campo.name == "filesOrganize") {
              filesOrganizeNovoBem = campo.value;
            }
            if (campo.type == "file") {
              if (!_.isEmpty(campo.value)) {
                imagens_temps = [];
                Array.from(campo.value).map((file) => {
                  formData.append(campo.name + "[]", file);

                  file.legenda = file.legenda ? file.legenda : "";
                  file.data = file.data ? file.data : "";
                  file.autoria = file.autoria ? file.autoria : "";
                  if (file.legenda || file.autoria || file.data) {
                    imagens_temps.push({
                      nome: file.name,
                      legenda: file.legenda,
                      data: file.data,
                      autoria: file.autoria,
                    });
                  }
                });
                imagens_legendadas.push({
                  nome: campo.name,
                  dados: imagens_temps,
                });
              }
            } else if (campo.type != "title" && campo.type != "multipleData") {
              if (campo.intoFormDate == true) {
                formData.append(campo.name, _.escape(campo.value));
              }

              if(!_.isEmpty(campo.value) || campo.intoFormDate == true || campo.required == true) {
                form[campo.name] = _.isString(campo.value) ? _.escape(campo.value) : campo.value;
              }
            } else if (campo.type == "multipleData") {
              if (!_.isEmpty(campo.value)) {
                campo.value.map(element => {
                Object.keys(element).map(function(key) {
                  if(_.isString(element[key])){
                    element[key] = _.unescape(element[key])
                    element[key] = _.escape(element[key])
                  }
                })
              })
              form[campo.name] = campo.value;
              }
            }
          }
        });
        
        if(this.tipoFormulario != "NovoBemDigitalizado"){
          /* passagem de titulo para os campos, com o select */
          _campos[0].options.map((tipo) => {
            if (tipo.value == _campos[0].value) {
              form.titulo = tipo.text;
            }
          });
        }

        if (this.isNew) {
          formData.append("quadro", this.tipoQuadro ? this.tipoQuadro : "");
        }

        formData.append("responsavel", this.$store.state.User.cpf);
        /* formulários novo bem digitalizado não contem campos, apenas imagens */
        if (this.tipoFormulario != "NovoBemDigitalizado") {
          formData.append("campos", JSON.stringify(form));
        }
        if (imagens_legendadas.length > 0) {
          formData.append(
            "imagens_legendadas",
            JSON.stringify(imagens_legendadas)
          );
        }

        this.dialogAlert[0].dialog = false;

        if (this.isNew) {
          axios.post(`https://gespa.dev.weblevel.pt/api/auth/formulario/${this.tipoFormulario}/add`,
            formData,
            {
              onUploadProgress: (prog) => {
                this.loader.value = parseInt(
                  Math.round((prog.loaded * 100) / prog.total)
                );
                this.loader.dialog = true;
                this.loader.text = "A Finalizar Formulário";

                if (this.loader.value > 0 && this.loader.value < 40) {
                  this.loader.color = "red";
                } else {
                  this.loader.color = this.loader.value < 80 ? "yellow darken-4" : "green";
                }
              },
            }
          ).then(() => {
            this.loader.dialog = false;
            this.$store.dispatch("getNotifications");
            
            if(this.$route.params.num){
              this.$router.push({ path: "/" + this.$route.name + "/" + this.$route.params.num});
            }else{
              this.$router.push({ path: "/" + this.$route.name });
            }
          }).catch((error) => {
                this.loader.dialog = false;
                this.response = {
                type: "danger",
                text: error.response.data.message,
                visible: true,
                };
          }).finally(()=>{
            this.loading = false
          });
        } else {
          formData.append("id", this.editId);
          /* caso seja novo bem digitalizado, passa organização de ficheiros, apenas nomes */
          if (this.tipoFormulario == "NovoBemDigitalizado") {
            formData.append("filesOrganize", JSON.stringify(filesOrganizeNovoBem));
          }
          axios.post(`https://gespa.dev.weblevel.pt/api/auth/formulario/${this.tipoFormulario}/update`, formData, {
            onUploadProgress: (prog) => {
            this.loader.value = parseInt(Math.round((prog.loaded * 100) / prog.total));
            this.loader.dialog = true;
            this.loader.text = "A Finalizar Formulário";

            if (this.loader.value > 0 && this.loader.value < 40) {
                this.loader.color = "red";
            } else {
                this.loader.color = this.loader.value < 80 ? "yellow darken-4" : "green";
            }
            }, headers: {
            'Content-Type': 'multipart/form-data'
            }
        }).then(() => {
            this.loader.dialog = false;
            this.$store.dispatch("getNotifications");
            
            if(this.$route.params.num){
              this.$router.push({ path: "/" + this.$route.name + "/" + this.$route.params.num});
            }else{
              this.$router.push({ path: "/" + this.$route.name });
            }
        }).catch(error => {
            this.loader.dialog = false;
            this.response = {
            type: "danger",
            text: error.response.data.message,
            visible: true,
            };
        }).finally(()=>{
          this.loading = false
        });
      }
    },
    Finalizar() {
      if (this.tipoFormulario) {
        var _campos = this.converterCamposEspeciais(
          this.campos,
          this.tipoFormulario
        );
        var fields = [];
        _campos.map((campo) => {
          // var value = "" + campo.value
          // value
          /* verificar se é necessário fazer contagem de percentagens */
          if (campo.required && campo.percentageCamps && campo.show) {
            var count = 0;
            campo.percentageCamps.map((element) => {
              var percent = Number(
                _campos.filter(function (camp) {
                  return camp.name == element;
                })[0].value
              );
              count += percent;
            });
            if (count != campo.maxPercentage) {
              fields.push(
                campo.label
                  ? `${campo.label} - ${campo.subTitlePercentage} (soma da percentagem tem de ser equivalente a ${campo.maxPercentage}%)`
                  : campo.title + " - " + campo.subTitlePercentage
              );
            }
          } else if (
            (campo.required &&
              _.isEmpty(campo.value) &&
              campo.type != "number") &&
              campo.show ||
            (campo.required &&
              campo.type == "number" &&
              Number(campo.value) <= 0) &&
              campo.show
          ) {
            var title = campo.label ? campo.label : campo.title;
            var newField = `${
              campo.subTitle ? title + " - " + campo.subTitle : title
            }`;
            if (!fields.find((element) => element == newField)) {
              fields.push(newField);
            }
          }
        });
        
        this.dialogAlert = [
          { title: "Tem a certeza que quer finalizar?", dialog: true },
        ];
        if (!_.isEmpty(fields)) {
          this.dialogAlert[0].title = "Preencha todos os campos";
          this.dialogAlert[0].description =
            "Foi detetado os seguintes campos vazios:";
          this.dialogAlert[0].emptyFields = fields;
        }
      } else {
        this.response = {
          type: "danger",
          text: "Sem Tipo de Formulário",
          visible: true,
        };
      }
    },
    DownloadForms() {
      var typeDoc = ""
      
      if(this.tipoDocumento == "docGestaoEtiqueta" || this.tipoDocumento == "docGestaoSumario"){
        typeDoc = this.tipoDocumento
        this.$store.dispatch("multipleDownloadsGestao", { type: typeDoc, dados: this.Selected }).finally(() => {
          this.$emit("selected", []);
        });
      }else{
        this.$store.dispatch("multipleDownloads", { type: typeDoc, dados: this.Selected }).finally(() => {
          this.$emit("selected", []);
        });
      }
    },
    RemoveForms() {
      this.loading = true
      var typeMethod = ""
      var route = ""
      switch (this.tipoDocumento) {
        case "formulario":
          typeMethod = "removeForms"
          break;
        case "docGestaoICMS":
          typeMethod = "removeDocsGestao"
          break;
        case "docGestaoSumario":
          typeMethod = "removeGestao"
          route = "sumarioGestao"
          break;
        case "docGestaoEtiqueta":
          typeMethod = "removeGestao"
          route = "etiquetaGestao"
          break;
      }
      
      if(typeMethod){
        this.Selected.forEach((element, index) => {
          var dados = {
            id: element.id,
            nameRoute: route
          };
          this.$store.dispatch(typeMethod, dados).then((res) => {
            this.$emit("response", res);
            this.RefreshData(0, 10);
          }).finally(()=>{
            if(index == (this.Selected.length-1)){
              this.dialogDelete[0].dialog = false;
              this.dialogAlert[0].dialog = false;
              this.$emit("selected", []); 
              this.loading = false
            }
          });
        });
      }
    },
    ConfirmDelete() {
      this.dialogDelete[0].title =
        "Tem a certeza que pretende remover os documentos selecionados?";
      this.dialogDelete[0].dialog = true;
    },
    Close() {
      this.dialogDelete[0].dialog = false;
      this.dialogAlert[0].dialog = false;
    },
    /* para o caso dos laudos, uma vez que foi retirado os v-model */
    converterCamposEspeciais(campos, tipoFormulario) {
      switch (tipoFormulario) {
        case "LaudoNhCp":
        case "LaudoSitiosNaturais":
        case "LaudoBemImovel":
        case "LaudoBemMovelIntegrado":
        case "LaudoDocumentosArquivos":
        case "EstruturasArquitetonicasUrbanisticas":
        case "ManifestacoesCulturais":
        case "BensMoveisIntegrados":
        case "Acervos":
        case "PatrimonioArqueologico":
        case "SitiosNaturais":
        case "ConjuntosUrbanos":
        case "Celebracao":
        case "FormasExpressao":
        case "Lugares":
        case "Saberes":
        case "Oficios":
        case 'RelatorioSalvaguarda':
        case 'DiagnosticoEducacao':
        case 'Revalidacao':
        case 'NovoBemTombado':
        case 'NovoBemRegistro':
          var _campos = this.campos;
          if (this.campos[this.campos.length - 1].campos) {
            var _camposTemp = [];
            _campos.map((raiz) => {
              raiz.campos.map((element) => {
                _camposTemp.push(element);
              });
            });
            _campos = _camposTemp;
          }
          
          _campos.map((element) => {
            if (element.type == "switch") {
              element.value = element.value ? "" + element.value : "false";
            }
            if (element.type != "title" && element.type != "file" && element.type != "date" && element.type != "switch" && element.type != "multipleData" && element.show) {
              var elementById = document.getElementById(element.name);
              if (elementById) {
                  if(elementById.value){
                    element.value = _.isNull(elementById.value) ? element.value : elementById.value;
                  }else {
                    element.value = ""
                  }
              }
              // element.value = document.getElementById(element.name).value ? document.getElementById(element.name).value : element.value
              if (element.type == "radio" && document.getElementById(element.name + "_especificar")) {
                var campEsecificar = document.getElementById(element.name + "_especificar");
                var especificar = {
                  name: element.name + "_especificar",
                  type: "radio",
                  value: "",
                  show: true
                };
                if (campEsecificar) {
                  especificar.value = campEsecificar.value ? campEsecificar.value : ""
                }
                _campos.push(especificar);
              }

              if (element.type == "radioMultipleInputs") {
                if (!_.isArray(element.subElement) && document.getElementById(element.subElement)) {
                  var campSubElement = document.getElementById(element.subElement);
                  var subElement = {
                    name: element.subElement,
                    type: "number",
                    value: 0,
                    show: true,
                  };
                  if (campSubElement) {
                    subElement.value = campSubElement.value ? campSubElement.value : 0
                  }
                  _campos.push(subElement);
                } else if (_.isArray(element.subElement)) {
                  element.subElement.map((element) => {
                    if (document.getElementById(element)) {
                      var campElement = document.getElementById(element).value;
                      var subElementConclusion = {
                        name: element,
                        value: campElement ? campElement : 0,
                        type: "number",
                        show: true
                      };
                      _campos.push(subElementConclusion);
                    }
                  });
                }
              }
            }
          });
          _campos[0].value = tipoFormulario;
          return _campos;
        default:
          return campos;
      }
    },
  },
};
</script>